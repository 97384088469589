import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
    FormGroupDirective,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

@Component({
    selector: 'app-auto-insurance',
    standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        MatFormFieldModule,
        MatInputModule,
    ],
    templateUrl: './auto-insurance.component.html',
    styleUrls: ['./auto-insurance.component.scss'],
})
export class AutoInsuranceComponent {
    form: UntypedFormGroup;
    constructor(private rootFormGroup: FormGroupDirective) {}

    ngOnInit(): void {
        this.form = this.rootFormGroup.control.get(
            'vehicle'
        ) as UntypedFormGroup;

        for (const key in this.form.controls) {
            this.form.get(key).addValidators(Validators.required);
        }
        this.form.updateValueAndValidity();
    }
    ngOnDestroy(): void {
        this.form.reset();
        for (const key in this.form.controls) {
            this.form.get(key).clearValidators();
            this.form.get(key).updateValueAndValidity();
        }
    }
}
