import {
    Component,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    ViewEncapsulation,
    ViewChild,
    OnDestroy,
    Optional,
    OnInit,
    Inject,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBarModule, MatSnackBar } from '@angular/material/snack-bar';
import { MatDrawer, MatSidenavModule } from '@angular/material/sidenav';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { TranslocoService, TranslocoModule } from '@ngneat/transloco';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { fuseAnimations } from '@fuse/animations';
import {
    MAT_DIALOG_DATA,
    MatDialog,
    MatDialogModule,
    MatDialogRef,
} from '@angular/material/dialog';
import { Subject, takeUntil, tap, Subscription, firstValueFrom } from 'rxjs';
import { Firestore, collection, doc } from '@angular/fire/firestore';
import cloneDeep from 'lodash-es/cloneDeep';

import { InsuranceDetailsComponent } from 'app/modules/admin/apps/ecommerce/inventory/insurance-details/insurance-details.component';
import { DocumentExtractionFormComponent } from 'app/common/components/document-extraction-form/document-extraction-form.component';
import { SelectPeopleObjectsComponent } from 'app/common/components/select-people-objects/select-people-objects.component';
import { ContractDataFormComponent } from 'app/common/components/contract-data-form/contract-data-form.component';
import { LoadingOverlayComponent } from 'app/common/components/loading-overlay/loading-overlay.component';
import { generateEmailNotificationData } from 'app/core/helpers/email-notification-helper';
import { PdfViewComponent } from 'app/common/components/pdf-view/pdf-view.component';
import { SwissLuxonDateAdapter } from 'app/common/services/swiss-luxondateadapter';
import { DocumentUpload, ContractData } from 'app/models/document-uploads.model';
import { AdminUserService } from 'app/common/services/admin-user.service';
import { SwissDateFormatPipe } from 'app/common/pipes/swiss-date-format';
import { Insurer as InsurerCore } from 'app/models/core/insurer.model';
import { EventMessagePipe } from 'app/common/pipes/event-message.pipe';
import { InsuranceService } from '../../insurances/insurance.service';
import { DocumentUploadService } from '../document-upload.service';
import { EventService } from 'app/common/services/event.service';
import { StripHtmlPipe } from 'app/common/pipes/strip-html.pipe';
import { PeopleAndObjects } from 'app/models/people-objects';
import { InsurerEntity } from 'app/models/db/insurer.model';
import { Event, LangCode } from 'app/models/db/event.model';
import { ToDatePipe } from 'app/common/pipes/to-date.pipe';
import { Insurance } from 'app/models/insurance.model';

type ExtendedInsurance = Insurance & { isNewInsurance?: boolean };

@Component({
    selector: 'app-policy-extraction-task',
    templateUrl: './policy-extraction-task.component.html',
    styleUrls: ['./policy-extraction-task.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: fuseAnimations,
    standalone: true,
    imports: [
        CommonModule,
        MatIconModule,
        MatButtonModule,
        TranslocoModule,
        MatDialogModule,
        MatSidenavModule,
        MatTooltipModule,
        MatSnackBarModule,
        MatProgressBarModule,
        // component imports
        DocumentExtractionFormComponent,
        SelectPeopleObjectsComponent,
        ContractDataFormComponent,
        InsuranceDetailsComponent,
        SwissDateFormatPipe,
        EventMessagePipe,
        PdfViewComponent,
        StripHtmlPipe,
        ToDatePipe,
    ],
})
export class PolicyExtractionTaskComponent implements OnInit, OnDestroy {
    @ViewChild('matDrawer', { static: false }) matDrawer: MatDrawer;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    private subscription = new Subscription();
    private languageSubscription: Subscription;
    insurancesGroupedByBeneficiary: {
        [beneficiaryId: string]: {
            insurances: Insurance[];
            insuranceIds: string[];
            pendingCount: number;
        };
    } = {};
    objectInsurance: ExtendedInsurance | null = null;
    method: 'create' | 'duplicate' | 'update' = 'create';
    matDrawerContext: string = '';
    insurances: Insurance[] | any[] = [];
    events: Event[] = [];
    document: DocumentUpload = null;
    canMarkAsComplete: boolean = false;
    eventsLoading: boolean = false;
    insuranceLoading: boolean = false;
    isLoading: boolean = false;
    currentLanguage: LangCode;
    mode: string = null;

    constructor(
        @Optional() private dialogRef: MatDialogRef<PolicyExtractionTaskComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: DocumentUpload,
        private router: Router,
        private matDialog: MatDialog,
        private firestore: Firestore,
        private snackbar: MatSnackBar,
        private eventService: EventService,
        private activatedRoute: ActivatedRoute,
        private adminUserService: AdminUserService,
        private insuranceService: InsuranceService,
        private translocoService: TranslocoService,
        private changeDetectorRef: ChangeDetectorRef,
        private swissLuxonDateAdapter: SwissLuxonDateAdapter,
        private documentUploadService: DocumentUploadService
    ) {
        this.languageSubscription = this.translocoService.langChanges$.subscribe(
            (lang: string) => {
                this.currentLanguage = lang.toUpperCase() as LangCode;
            }
        );
    }

    ngOnInit(): void {
        const id = this.activatedRoute.snapshot.paramMap.get('id');
        if (id) {
            this.fetchDocumentDetails(id);
            this.fetchInsurancesByDocumentId(id);
        }
    }

    private fetchDocumentDetails(id: string): void {
        this.isLoading = true;
        const dataSubscription = this.documentUploadService.getById(id).subscribe({
            next: (data) => {
                this.document = data;
                this.mode = this.document ? 'edit' : 'create';
                this.fetchEvents(data.id, data.user.uid);
                this.isLoading = false;
                this.changeDetectorRef.markForCheck();
            },
            error: () => {
                this.isLoading = false;
            },
        });
        this.subscription.add(dataSubscription);
    }

    private fetchInsurancesByDocumentId(documentId: string): void {
        this.insuranceService
            .getAllInsurancesByDocumentId$(documentId)
            .pipe(
                takeUntil(this._unsubscribeAll),
                tap((insurances) => {
                    this.insurances = insurances;
                    // Check if there are any insurances to enable "Mark as Complete"
                    this.canMarkAsComplete = Boolean(insurances.length);
                    // Group the insurances by beneficiary for further processing/display
                    this.groupInsurancesByBeneficiary(this.insurances);
                    // Trigger change detection to update the view
                    this.changeDetectorRef.markForCheck();
                })
            )
            .subscribe();
    }

    private groupInsurancesByBeneficiary(insurances: Insurance[]): void {
        const grouped = insurances.reduce((accumulator, insurance) => {
            const beneficiaryId = insurance.health.beneficiary.id;
            if (!accumulator[beneficiaryId]) {
                accumulator[beneficiaryId] = {
                    insurances: [],
                    insuranceIds: [],
                    pendingCount: 0,
                };
            }
            accumulator[beneficiaryId].insurances.push(insurance);
            accumulator[beneficiaryId].insuranceIds.push(insurance.id);
            if (insurance.captureStatus === 'PENDING') {
                accumulator[beneficiaryId].pendingCount++;
            }

            return accumulator;
        }, {});

        this.insurancesGroupedByBeneficiary = grouped;
        this.changeDetectorRef.markForCheck();
    }

    private fetchEvents(recordId: string, ownerId: string): void {
        this.eventsLoading = true;
        const eventsSubscription = this.eventService
            .getEventsByRecordIdAndOwnerId(recordId, ownerId)
            .subscribe({
                next: (events) => {
                    this.events = events.map((event) => ({
                        ...event,
                        language: this.currentLanguage,
                    }));
                    this.eventsLoading = false;
                    this.changeDetectorRef.markForCheck();
                },
                error: () => {
                    this.eventsLoading = false;
                    // Handle error
                },
            });
        this.subscription.add(eventsSubscription);
    }

    markAsCompleted() {
        const loadingOverlayData = {
            isLoading: true,
            loadingText: 'Creating Insurances',
            successText: 'Insurances Created',
        };
        const loadingOverlayRef = this.matDialog.open(LoadingOverlayComponent, {
            data: loadingOverlayData,
            disableClose: true,
        });
        this.documentUploadService.markAsComplete(this.document).then(() => {
            loadingOverlayRef.componentInstance.data = {
                ...loadingOverlayData,
                isLoading: false,
            };
            this.navigateToTasks();
        });
    }

    delete(): void {
        if (this.document) {
            this.isLoading = true;
            this.documentUploadService
                .delete(this.document.id)
                .then(() => {
                    this.snackbar.open('Policy Extraction deleted.', 'Close', {
                        duration: 3000,
                    });
                    this.isLoading = false;
                    this.navigateToTasks();
                })
                .catch((e: Error) => {
                    console.log('Error', e.message);
                    this.isLoading = false;
                });
        }
    }

    get unpublishEventIds(): string[] {
        return this.events
            .filter((event: Event) => !event.status || event.status === 'unpublished')
            .map((event: Event) => event.id as string);
    }

    updateSelectedEventsStatus(
        ids: string[],
        newStatus: 'unpublished' | 'published'
    ): void {
        this.eventsLoading = true;
        this.eventService.updateEventStatus(ids, newStatus).subscribe({
            next: () => {
                this.eventsLoading = false;
            },
            error: (error) => {
                // Handle error
                console.error('Failed to update event status', error);
                this.eventsLoading = false;
            },
        });
    }

    publishAllEvent() {
        const unpublishIds: string[] = this.unpublishEventIds;
        this.updateSelectedEventsStatus(unpublishIds, 'published');
    }

    updateEventStatus(event: Event) {
        let newStatus: 'unpublished' | 'published' = event?.status || 'unpublished';
        if (newStatus === 'unpublished') {
            newStatus = 'published';
        } else {
            newStatus = 'unpublished';
        }
        this.updateSelectedEventsStatus([event.id], newStatus);
    }

    get beneficiaryIds(): string[] {
        if (!this.document) {
            return [];
        } else {
            return (
                this.document?.beneficiaries.map((beneficiary) => beneficiary.id) || []
            );
        }
    }

    async updateDocumentBeneficiaries(beneficiaryIds: string[]): Promise<void> {
        try {
            await this.documentUploadService.updateBeneficiaries(
                this.document.id,
                beneficiaryIds
            );
            this.changeDetectorRef.markForCheck();
        } catch (error) {
            console.error('Error updating document beneficiaries:', error);
            this.changeDetectorRef.markForCheck();
        }
    }

    async addBeneficiary(object: PeopleAndObjects): Promise<void> {
        const beneficiaryIds = [...this.beneficiaryIds, object.id];
        this.isLoading = true;
        try {
            await this.updateDocumentBeneficiaries(beneficiaryIds);
            const beneficiaries = cloneDeep([
                ...(this.document?.beneficiaries || []),
                object,
            ]);
            const name = `${object.lastName}, ${object.firstName} ${this.swissLuxonDateAdapter.toSwissFormat(object.birthday)}`;

            await this.eventService.createEvent(
                this.document.id,
                this.document.user.uid,
                'policy-extractions',
                'beneficiary-added',
                name
            );

            // Process sending email notification to user
            const emailNotificationData = generateEmailNotificationData(
                'new-insurance-beneficiary',
                this.document.user.email,
                name,
                this.document.id,
                this.currentLanguage || 'EN'
            );
            if (emailNotificationData) {
                // Send the email notification
                this.adminUserService.sendEmailNotification(emailNotificationData).subscribe({
                    next: (response) => console.log('Email notification sent successfully', response),
                    error: (error) => console.error('Failed to send email notification', error),
                });
            }

            this.document = cloneDeep({
                ...this.document,
                beneficiaries,
            }) as DocumentUpload;
            this.closeDrawer();
            this.isLoading = false;
            this.changeDetectorRef.markForCheck();
        } catch (error) {
            console.error('Error adding beneficiary:', error);
            this.isLoading = false;
            this.changeDetectorRef.markForCheck();
        }
    }

    async deleteBeneficiary(object: PeopleAndObjects) {
        let beneficiaryIds = cloneDeep(this.beneficiaryIds);
        beneficiaryIds = beneficiaryIds.filter((id) => id !== object.id);
        const insuranceIds = this.insurancesGroupedByBeneficiary[object.id]?.insuranceIds || [];
        const insurances = this.insurancesGroupedByBeneficiary[object.id]?.insurances || [];
        this.isLoading = true;

        const updateTasks = [this.updateDocumentBeneficiaries(beneficiaryIds)];

        const name = `${object.lastName}, ${
            object.firstName
        } ${this.swissLuxonDateAdapter.toSwissFormat(object.birthday)}`;
        const eventBeneficiaryDeletePromise: Promise<any> = firstValueFrom(
            this.eventService.createEvent(
                this.document.id,
                this.document.user.uid,
                'policy-extractions',
                'beneficiary-deleted',
                name
            )
        );
        updateTasks.push(eventBeneficiaryDeletePromise);

        if (insuranceIds.length > 0) {
            const deleteMultiplePromise = firstValueFrom(
                this.insuranceService.deleteMultiple(insuranceIds)
            );
            updateTasks.push(deleteMultiplePromise);
            // Create an event for each insurance deletion
            const eventCreationPromises: Promise<any>[] = insurances.map(
                async (insurance) => {
                    const productLang = insurance?.product?.languages[this.currentLanguage];
                    const productName = productLang?.name || insurance?.product?.independentName;
                    return firstValueFrom(
                        this.eventService.createEvent(
                            insurance.id,
                            insurance.userId,
                            'policy-extractions',
                            'insurance-deleted',
                            productName
                        )
                    );
                }
            );
            updateTasks.push(...eventCreationPromises);
        }

        try {
            await Promise.all(updateTasks);
            let beneficiaries = cloneDeep(
                this.document?.beneficiaries || []
            ) as PeopleAndObjects[];
            beneficiaries = beneficiaries.filter(({ id }) => id !== object.id);
            this.document = cloneDeep({ ...this.document, beneficiaries });
            this.closeDrawer();
        } catch (error) {
            console.error('Error deleting beneficiary:', error);
        } finally {
            this.isLoading = false;
            this.changeDetectorRef.markForCheck();
        }
    }

    addBeneficiaryInsurance(beneficiary: any) {
        const name = `${beneficiary.lastName}, ${
            beneficiary.firstName
        } ${this.swissLuxonDateAdapter.toSwissFormat(beneficiary.birthday)}`;
        const emptyInsurance = cloneDeep({
            ...this.generateInsurance(),
        }) as ExtendedInsurance;
        this.objectInsurance = cloneDeep({
            ...emptyInsurance,
            isNewInsurance: true,
            health: { beneficiary: { ...beneficiary, name }, insureeNumber: '' },
        }) as ExtendedInsurance;
        this.method = 'create';
        if (this.matDrawer) {
            this.matDrawerContext = 'add-insurance';
            this.matDrawer.open();
        } else {
            console.warn('Drawer is not available');
        }
    }

    editBeneficiaryInsurance(insurance: ExtendedInsurance) {
        this.objectInsurance = cloneDeep({
            ...insurance,
            isNewInsurance: false,
        }) as ExtendedInsurance;
        this.method = 'update';
        if (this.matDrawer) {
            this.matDrawerContext = 'add-insurance';
            this.matDrawer.open();
        } else {
            console.warn('Drawer is not available');
        }
    }

    duplicateBeneficiaryInsurance(insurance: ExtendedInsurance) {
        const insuranceCopy = cloneDeep({
            ...this.generateInsurance(insurance),
        }) as ExtendedInsurance;
        this.objectInsurance = cloneDeep({
            ...insuranceCopy,
            isNewInsurance: true,
        }) as ExtendedInsurance;
        this.method = 'duplicate';
        if (this.matDrawer) {
            this.matDrawerContext = 'add-insurance';
            this.matDrawer.open();
        } else {
            console.warn('Drawer is not available');
        }
    }

    async createPublishUnpublishedEvents(
        insurances: Insurance[],
        newStatus: string
    ): Promise<void> {
        const eventLogs: Promise<any>[] = [];

        for (const insurance of insurances) {
            const productLang = insurance?.product?.languages[this.currentLanguage];
            const productName = productLang?.name || insurance?.product?.independentName;
            const actionType = newStatus === 'COMPLETED' ? 'insurance-published' : 'insurance-unpublished';

            // Create event for 'insurances'
            eventLogs.push(
                firstValueFrom(
                    this.eventService.createEvent(
                        insurance.id,
                        insurance.userId,
                        'insurances',
                        actionType,
                        productName
                    )
                )
            );

            const documentId = insurance.documentId;
            const userId = insurance.userId;
            // Create event for 'policy-extractions'
            eventLogs.push(
                firstValueFrom(
                    this.eventService.createEvent(
                        documentId,
                        userId,
                        'policy-extractions',
                        actionType,
                        productName,
                        false
                    )
                )
            );

            if (actionType === 'insurance-published') {
                // Process sending email notification to user
                const notificationData = generateEmailNotificationData(
                    'new-insurance',
                    this.document.user.email,
                    productName,
                    insurance.id,
                    this.currentLanguage || 'EN'
                );
                if (notificationData) {
                    // Send the email notification
                    const sendNotificationResult = this.adminUserService.sendEmailNotification(notificationData);
                    eventLogs.push(sendNotificationResult.toPromise());
                }
            }
        }

        await Promise.all(eventLogs);
    }

    async updateSelectedInsurancesStatus(
        ids: string[],
        newStatus: string
    ): Promise<void> {
        this.insuranceLoading = true;
        try {
            await firstValueFrom(
                this.insuranceService.updateCaptureStatus(ids, newStatus)
            );
        } catch (error) {
            console.error('Failed to update insurance status', error);
        } finally {
            this.insuranceLoading = false;
            this.changeDetectorRef.markForCheck();
        }
    }

    async updateInsuranceStatus(insurance: Insurance): Promise<void> {
        let newStatus: string = insurance?.captureStatus || 'PENDING';
        if (newStatus === 'PENDING') {
            newStatus = 'COMPLETED';
        } else {
            newStatus = 'PENDING';
        }
        await this.updateSelectedInsurancesStatus([insurance.id], newStatus);
        await this.createPublishUnpublishedEvents([insurance], newStatus);
    }

    async publishInsurancesByBeneficiary(id: string): Promise<void> {
        const ids = this.insurancesGroupedByBeneficiary[id].insuranceIds;
        const insurances = this.insurancesGroupedByBeneficiary[id].insurances;
        const newStatus = 'COMPLETED';
        await this.updateSelectedInsurancesStatus(ids, newStatus);
        await this.createPublishUnpublishedEvents(insurances, newStatus);
    }

    deleteInsurance(insurance: ExtendedInsurance) {
        const loadingOverlayData = {
            isLoading: true,
            loadingText: `${
                insurance?.isNewInsurance
                    ? this.translocoService.translate('admin.docUploads.deletingDraft')
                    : this.translocoService.translate(
                          'admin.docUploads.deletingInsurance'
                      )
            }`,
            successText: `${
                insurance?.isNewInsurance
                    ? this.translocoService.translate('admin.docUploads.draftDiscarded')
                    : this.translocoService.translate('admin.docUploads.insuranceDeleted')
            }`,
        };
        const loadingOverlayRef = this.matDialog.open(LoadingOverlayComponent, {
            data: loadingOverlayData,
            disableClose: true,
        });

        this.insuranceService.delete(insurance.id).then(() => {
            loadingOverlayRef.componentInstance.data = {
                ...loadingOverlayData,
                isLoading: false,
            };
            this.changeDetectorRef.markForCheck();
        });
    }

    async updateContractData(contractData: ContractData | null): Promise<void> {
        this.isLoading = true;
        try {
            await this.documentUploadService.updateContractData(
                this.document.id,
                contractData
            );
        } catch (error) {
            console.error('Failed to update contract data', error);
        } finally {
            this.document = cloneDeep({ ...this.document, contractData });
            this.isLoading = false;
            this.closeDrawer();
            this.changeDetectorRef.markForCheck();
        }
    }

    handleInsuranceFormClose(): void {
        this.closeDrawer();
        this.fetchDocumentDetails(this.document.id);
        this.changeDetectorRef.markForCheck();
    }

    openDrawer(drawerType: string): void {
        if (this.matDrawer) {
            this.matDrawer.open();
            this.matDrawerContext = drawerType;
        } else {
            console.warn('Drawer is not available');
        }
    }

    closeDrawer(): void {
        if (this.matDrawer) {
            this.matDrawer.close();
            this.matDrawerContext = '';
        } else {
            console.warn('Drawer is not available');
        }
    }

    navigateToTasks(): void {
        this.router.navigate(['admin/tasks']);
    }

    /**
     * Generate a new insurance template
     * @returns
     */
    generateInsurance(fromInsurance: ExtendedInsurance = null): ExtendedInsurance {
        /**
         * UID is used as a document ID so we also set it here since it's used
         * for filtering. See ngOnInit
         */
        const insurancesRef = collection(this.firestore, `insurances`);
        const docId = doc(insurancesRef).id;

        let insurance = {
            id: docId,
            insurerId: '',
            productId: '',
            type: '',
            referenceNumber: '',
            holder: {
                name: '',
                birthday: '',
                address: {
                    line1: '',
                    line2: '',
                    postalCode: '',
                    city: '',
                    country: '',
                },
            },
            effectiveDate: '',
            expirationDate: '',
            policyPdf: '',
            captureStatus: 'PENDING',
            insurer: {} as InsurerEntity,
            vehicle: undefined,
            health: undefined,
            healthBasic: undefined,
            details: undefined,
            paymentPeriodicity: undefined,
            premium: {
                periodicityNumMonths: 12,
                grossCents: 0,
                discounts: {
                    associationDiscount: { percentage: 0, amountCents: 0 },
                    familyDiscount: { percentage: 0, amountCents: 0 },
                    loyaltyDiscount: { percentage: 0, amountCents: 0 },
                    environmentalDiscount: { percentage: 0, amountCents: 0 },
                    otherDiscounts: { percentage: 0, amountCents: 0 },
                },
                netCents: 0,
            },
            collectiveContract: '',
            isCaptureStatusPending: true,
            isCaptureStatusProcessing: false,
            isCaptureStatusCompleted: false,
            isNewInsurance: true,
        } as ExtendedInsurance;

        if (fromInsurance) {
            insurance = {
                ...fromInsurance,
                id: docId,
                isNewInsurance: true,
                // Reset or copy specific fields if needed
            } as ExtendedInsurance;
        }

        return insurance;
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
        if (this.languageSubscription) {
            this.languageSubscription.unsubscribe();
        }
    }
}
