<div class="flex w-full relative p-6 flex-col justify-center">
    <div class="w-full mb-6">
        <div class="flex justify-between items-center mb-4">
            <div class="text-lg font-semibold text-gray-700">
                {{'Policy Extraction Details'}}
            </div>
            <button mat-icon-button (click)="onClickClose()">
                <mat-icon>close</mat-icon>
            </button>
        </div>
        <div class="flex justify-start items-end gap-4 mb-3">
            <img *ngIf="docExtraction.user?.photoURL" class="h-12 w-12 rounded" [src]="docExtraction.user?.photoURL"
                [alt]="docExtraction.user.displayName">
            <mat-icon *ngIf="!docExtraction.user?.photoURL" class="icon-size-12"
                svgIcon="heroicons_outline:user-circle"></mat-icon>
            <div class="">
                <p class="text-black">{{docExtraction.user.displayName}}</p>
                <p class="text-black">{{docExtraction.user.email}}</p>
            </div>
        </div>
        <div class="flex justify-between items-center mb-1">
            <span class="text-secondary">{{'Policy Document'}} </span>
            <a [href]="docExtraction.path" target="_blank" class="underline text-primary">{{docExtraction.filename}}</a>
        </div>
        <div class="flex justify-between items-center mb-1">
            <span class="text-secondary"> {{'Status'}} </span>
            <div class="flex justify-between items-center gap-2">
                <ng-container *ngIf="!editStatus; else editStatusTemplate">
                    <span class="text-black">{{ statusMapping[docExtraction.status] }}</span>
                    <mat-icon *ngIf="isAdmin" class="cursor-pointer icon-size-4"
                        svgIcon="heroicons_outline:pencil-square" (click)="startEditStatus()"></mat-icon>
                </ng-container>
                <ng-template #editStatusTemplate>
                    <mat-form-field appearance="fill" class="w-48">
                        <mat-select [(ngModel)]="tempStatus" (click)="$event.stopPropagation()">
                            <mat-option *ngFor="let statusOption of allStatusOptions" [value]="statusOption">
                                {{ statusMapping[statusOption] }}
                            </mat-option>
                        </mat-select>
                        <mat-icon matSuffix class="cursor-pointer icon-size-4" svgIcon="heroicons_outline:check"
                            (click)="updateStatus(); $event.stopPropagation()"></mat-icon>
                        <mat-icon matSuffix class="cursor-pointer icon-size-4" svgIcon="heroicons_outline:x-mark"
                            (click)="cancelEditStatus(); $event.stopPropagation()"></mat-icon>
                    </mat-form-field>

                </ng-template>
            </div>
        </div>
        <div class="flex justify-between items-center mb-1">
            <span class="text-secondary">Urgency</span>
            <div class="flex justify-between items-center gap-2">
                <ng-container *ngIf="!editUrgency; else editUrgencyTemplate">
                    <span class="text-black capitalize">{{ docExtraction?.urgency || 'Low' }}</span>
                    <mat-icon *ngIf="isAdmin" class="cursor-pointer icon-size-4"
                        svgIcon="heroicons_outline:pencil-square" (click)="toggleEditUrgency()"></mat-icon>
                </ng-container>
                <ng-template #editUrgencyTemplate>
                    <mat-form-field appearance="fill" class="w-48">
                        <mat-select [(ngModel)]="tempUrgency" (click)="$event.stopPropagation()">
                            <mat-option value="low">{{'Low'}}</mat-option>
                            <mat-option value="medium">{{'Medium'}}</mat-option>
                            <mat-option value="high">{{'High'}}</mat-option>
                        </mat-select>
                        <mat-icon matSuffix class="cursor-pointer icon-size-4" svgIcon="heroicons_outline:check"
                            (click)="updateUrgency(); $event.stopPropagation()"></mat-icon>
                        <mat-icon matSuffix class="cursor-pointer icon-size-4" svgIcon="heroicons_outline:x-mark"
                            (click)="toggleEditUrgency(); $event.stopPropagation()"></mat-icon>
                    </mat-form-field>

                </ng-template>
            </div>
        </div>
        <div class="flex justify-between items-center mb-1">
            <span class="text-secondary">Assignee</span>
            <div class="flex justify-between items-center gap-2">
                <ng-container *ngIf="!editAssignee; else editAssigneeTemplate">
                    <span class="text-black">{{docExtraction?.assignee?.displayName || 'Unassigned'}}</span>
                    <mat-icon *ngIf="isAdmin" class="cursor-pointer icon-size-4"
                        svgIcon="heroicons_outline:pencil-square" (click)="toggleEditAssignee()">
                    </mat-icon>
                </ng-container>
                <ng-template #editAssigneeTemplate>
                    <select-admin-user class="min-w-48" [selectedAdminUser]="tempAssignee"
                        (onSelectAdminUser)="handleAssigneeChange($event)">
                    </select-admin-user>
                    <div class="flex justify-center items-center gap-1 pb-3">
                        <mat-icon matSuffix class="cursor-pointer icon-size-5" svgIcon="heroicons_outline:check"
                            (click)="updateAssignee(); $event.stopPropagation()"></mat-icon>
                        <mat-icon matSuffix class="cursor-pointer icon-size-5" svgIcon="heroicons_outline:x-mark"
                            (click)="toggleEditAssignee(); $event.stopPropagation()"></mat-icon>
                    </div>
                </ng-template>
            </div>
        </div>
        <div class="flex justify-between items-center">
            <span class="text-secondary">{{'Created At'}} </span>
            <span class="text-black">
                {{ docExtraction.createdAt | toDate | date: 'dd.MM.yyyy HH:mm' }}
            </span>
        </div>
    </div>

    <div class="w-full">
        <mat-tab-group mat-align-tabs="start" class="mb-4">
            <mat-tab label="Comments">
                <div>
                    <mat-form-field *ngIf="isAdmin" appearance="fill" class="w-48">
                        <mat-label class="text-secondary">{{'Status'}} </mat-label>
                        <mat-select [(ngModel)]="activityType">
                            <mat-option *ngFor="let activityOption of activityOptions" [value]="activityOption">
                                {{ 'policyRequest.' + activityTypeMapping[activityOption] | transloco }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="w-full">
                        <textarea matInput [(ngModel)]="newComment.description" rows="4"
                            [disabled]="isLoading"></textarea>
                    </mat-form-field>
                    <div class="flex justify-end items-center">
                        <button mat-raised-button color="primary" (click)="addComment()" [disabled]="isLoading">
                            {{'Comment'}}
                        </button>
                    </div>
                </div>
                <div class="mt-4 overflow-auto"
                    [ngClass]="!isMobile ? 'max-h-[calc(100vh-780px)]' : 'max-h-[calc(100vh-700px)]'">
                    <ul>
                        <li *ngFor="let activity of docExtraction.comments" class="mb-2">
                            <p class="text-sm text-secondary">{{ activity.createdAt | toDate | date: 'dd.MM.yyyy HH:mm' }}</p>
                            <p class="text-black">{{ activity.description }}</p>
                        </li>
                    </ul>
                </div>
            </mat-tab>
            <mat-tab label="Activities">
                <div class="mt-4 overflow-auto"
                    [ngClass]="!isMobile ? 'max-h-[calc(100vh-600px)]' : 'max-h-[calc(100vh-520px)]'">
                    <ul>
                        <li *ngFor="let event of events" class="mb-2">
                            <p class="text-sm text-secondary">{{ event.createdBy.displayName }}</p>
                            <p class="text-sm text-secondary">{{ event.createdAt | toDate | date: 'dd.MM.yyyy HH:mm' }}</p>
                            <p class="text-black" [innerHTML]="event | eventMessage"></p>
                        </li>
                    </ul>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>
<ng-container *ngIf="isLoading">
    <div class="absolute inset-0 bg-gray-500 bg-opacity-50 flex justify-center items-center rounded">
        <mat-progress-spinner [diameter]="40" mode="indeterminate"></mat-progress-spinner>
    </div>
</ng-container>