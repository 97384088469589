import { TextFieldModule } from '@angular/cdk/text-field';
import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Inject,
    Input,
    OnInit,
    Optional,
    Output,
    ViewEncapsulation,
} from '@angular/core';
import { doc, setDoc } from '@angular/fire/firestore';
import {
    FormsModule,
    ReactiveFormsModule,
    FormGroup,
    FormControl,
    FormArray,
    Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import {
    MAT_DIALOG_DATA,
    MatDialogModule,
    MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { TranslocoService, TranslocoModule } from '@ngneat/transloco';
import { InsurersService } from '../insurers.service';
import { InsurerLanguageForm } from 'app/models/insurer.model';
import { LanguageCodes } from 'app/models/common.model';
import { Insurer as InsurerCore } from 'app/models/core/insurer.model';

@Component({
    selector: 'insurer-details',
    templateUrl: './insurer-details.component.html',
    styleUrls: ['./insurer-details.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        MatButtonModule,
        MatIconModule,
        FormsModule,
        TextFieldModule,
        NgFor,
        NgClass,
        MatRippleModule,
        MatDialogModule,
        AsyncPipe,
        MatInputModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        MatSelectModule,
        MatSnackBarModule,
        TranslocoModule
    ],
})
export class InsurerDetailsComponent implements OnInit {
    @Input() insurer: InsurerCore | null = null;
    @Output('closeDetails') closeDetails: EventEmitter<any> = new EventEmitter();

    mode: string = null;
    languageCodes = Object.values(LanguageCodes);
    form: FormGroup | undefined;
    processing: boolean = false;

    /**
     * Constructor
     */
    constructor(
        @Optional() private dialogRef: MatDialogRef<InsurerDetailsComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: InsurerCore,
        private _insurerService: InsurersService,
        private _snackbar: MatSnackBar,
        public translocoService: TranslocoService
    ) {}

    /**
     * OnInit
     */
    ngOnInit(): void {
        this.mode = this.insurer ? 'edit' : 'create';
        this.insurer = this.data || this.insurer;
        this.composeForm(this.insurer);
    }

    /**
     * Compose the insurer deatils form
     * @param data  InsurerType | undefined  Insurer data
     * @returns
     */
    composeForm(data: InsurerCore | undefined): void {
        this.form = new FormGroup({
            id: new FormControl(data?.id || ''),
            languages: new FormArray([]),
        });

        this.languageCodes.forEach((lang) => {
            const currentLang = this.insurer?.languages?.[lang];
            this.addLanguage({
                language: lang,
                name: currentLang?.name || '',
                logo: currentLang?.logo || '',
                preview: currentLang?.logo || '',
                file: '',
            } as InsurerLanguageForm);
        });
    }

    /**
     * Form laguage field getter
     */
    get languages() {
        return this.form.controls['languages'] as FormArray;
    }

    /**
     * Append language group to form
     * @param data
     */
    addLanguage(data: InsurerLanguageForm) {
        this.languages.push(
            new FormGroup({
                language: new FormControl(
                    data?.language || '',
                    Validators.required
                ),
                name: new FormControl(data?.name || '', Validators.required),
                logo: new FormControl(data?.logo),
                preview: new FormControl(data?.logo || ''),
                file: new FormControl(''),
            })
        );
    }

    patchLogo(el: FormGroup, event: any) {
        const file = (event.target as HTMLInputElement)?.files?.[0];
        const src = URL.createObjectURL(file);

        if (file) {
            el.controls.preview.patchValue(src);
            el.controls.file.patchValue(file);
        }
    }

    /**
     * Create or update an insurer
     * @param event
     */
    async save(event: Event): Promise<void> {
        if (!this.form.valid) return;

        this.processing = true;

        try {
            const data = await this._insurerService.save(this.form.value);
            const savedMessage = this.translocoService.translate('insurers.insurerSaved');
            this._snackbar.open(savedMessage, 'Success', {
                duration: 3000,
            });
            if (this.dialogRef) {
                this.dialogRef.close(data);
            }
            this.closeDetails.emit(data);
        } catch (error) {
            console.error('Failed to save insurer:', error);
            this._snackbar.open('Failed to save insurer', 'Error', {
                duration: 3000,
            });
        } finally {
            this.processing = false;
        }
    }

    cancel(): void {
        // If the dialog reference is available, close the modal
        if (this.dialogRef) {
            this.dialogRef.close();
        }
        this.closeDetails.emit();
    }
}
