import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Navigation } from 'app/core/navigation/navigation.types';
import { Observable, ReplaySubject, tap } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { FuseNavigationItem } from '../../../@fuse/components/navigation/navigation.types';
import { ProfileService } from 'app/common/services/profile.service';


@Injectable({ providedIn: 'root' })
export class NavigationService {
    private _navigation: ReplaySubject<Navigation> = new ReplaySubject<Navigation>(1);
    private originalNavigation: Navigation;

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient, private _authService: AuthService, private _profileService: ProfileService) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for navigation
     */
    get navigation$(): Observable<Navigation> {
        return this._navigation.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get all navigation data
     */
    get(): Observable<Navigation> {
        return this._httpClient.get<Navigation>('api/common/navigation').pipe(
            tap((navigation) => {
                this.originalNavigation = { ...navigation }; // Store the original navigation
                navigation = this.setAdminMenu(navigation);
                this._navigation.next(navigation);
            }),
        );
    }


    setAdminMenu(navigation: Navigation) {
        const horizontal = navigation['horizontal'];
        const index = horizontal.findIndex(item => item.id === 'admin');
        const policiesIdx = horizontal.findIndex(item => item.id === 'policies');
        const systemIdx = horizontal.findIndex(item => item.id === 'system');
        const peopleIdx = horizontal.findIndex(item => item.id === 'people-objects');
        const userDataIdx = horizontal.findIndex(item => item.id === 'userData');
        const helpIdx = horizontal.findIndex(item => item.id === 'apps.help-center');
        // const insightsIdx = horizontal.findIndex(item => item.id === 'insights');
        // const infoIdx = horizontal.findIndex(item => item.id === 'info');
        navigation.horizontal[index].hidden = (item: FuseNavigationItem) => true; //admin (old)

        navigation.horizontal[policiesIdx].hidden =(item: FuseNavigationItem) => (this._authService.isAdmin() || this._authService.isSuperUser());
        navigation.horizontal[peopleIdx].hidden = (item: FuseNavigationItem) => (this._authService.isAdmin() || this._authService.isSuperUser());
        navigation.horizontal[helpIdx].hidden =(item: FuseNavigationItem) => (this._authService.isAdmin() || this._authService.isSuperUser());
        
        navigation.horizontal[systemIdx].hidden = (item: FuseNavigationItem) => (!this._authService.isAdmin() && !this._authService.isSuperUser());
        navigation.horizontal[userDataIdx].hidden = (item: FuseNavigationItem) => (!this._authService.isAdmin() && !this._authService.isSuperUser());
        // navigation.horizontal[insightsIdx].hidden = (item: FuseNavigationItem) => (!this._authService.isAdmin() && !this._authService.isSuperUser());
        // navigation.horizontal[infoIdx].hidden = (item: FuseNavigationItem) => (!this._authService.isAdmin() && !this._authService.isSuperUser());
        

        return navigation;
    }

    
}
