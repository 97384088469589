import { Routes } from '@angular/router';

import { PolicyExtractionTaskComponent } from './policy-extraction-task/policy-extraction-task.component';
import { PolicyRequestTaskComponent } from './policy-request-task/policy-request-task.component';
import { DocumentUploadsComponent } from './document-uploads.component';
import { TasksComponent } from './tasks/tasks.component';

export const documentUploadsRoutes: Routes = [
    {
        path: '',
        component: DocumentUploadsComponent,
        children: [
            {
                path: '',
                component: TasksComponent,
            },
            {
                path: 'extraction/:id',
                component: PolicyExtractionTaskComponent,
            },
            {
                path: 'request/:id',
                component: PolicyRequestTaskComponent,
            },
        ],
    },
];
