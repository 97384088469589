<div class="flex flex-col w-full h-full max-h-[calc(100vh-160px)] relative">
    <!-- Loading indicator -->
    <div *ngIf="isLoading" class="absolute inset-x-0 top-0">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>

    <!-- Task Header -->
    <div class="flex items-center justify-between p-4 bg-white shadow border-b">
        <div class="flex justify-start items-center gap-3">
            <button class="flex justify-center items-center" matTooltip="Return to task list"
                (click)="navigateToTasks()">
                <mat-icon svgIcon="heroicons_outline:arrow-small-left"></mat-icon>
            </button>
            <h1 *ngIf="insuranceRetrieval" class="text-lg font-bold">
                Task {{insuranceRetrieval?.taskId || insuranceRetrieval?.id}}: Policy Request - {{insuranceRetrieval?.user?.displayName}}
            </h1>
        </div>
        <div *ngIf="insuranceRetrieval" class="flex justify-center items-center gap-3">
            <button [matTooltip]="!isRequestClosed ? 'Close Policy Request' : null" [disabled]="isRequestClosed"
                (click)="closeRequest()">
                <mat-icon class="icon-size-5" svgIcon="heroicons_outline:x-mark"></mat-icon>
            </button>
            <button matTooltip="Delete Policy Request" (click)="deletePolicy()">
                <mat-icon class="icon-size-5" svgIcon="heroicons_outline:trash"></mat-icon>
            </button>
            <button [matTooltip]="!isRequestCloseOrCompleted ? 'Convert to Extraction' : null"
                [disabled]="isRequestCloseOrCompleted" (click)="convertRequestToExtraction()">
                <mat-icon class="icon-size-5" svgIcon="mat_outline:transform"></mat-icon>
            </button>
        </div>
    </div>

    <mat-drawer-container class="custom-drawer-container flex h-full max-h-[calc(100vh-220px)] overflow-auto" (backdropClick)="closeDrawer()">
        <mat-drawer class="w-full sm:w-100 dark:bg-gray-900" mode="over" [opened]="false" position="end"
            [disableClose]="true" #matDrawer>
            <ng-container *ngIf="insuranceRetrieval">
                <ng-container *ngIf="matDrawerContext === 'request'">
                    <app-policy-request-form [policyRequest]="insuranceRetrieval" [isAdmin]="true"
                        (closeDetails)="closeDrawer()">
                    </app-policy-request-form>
                </ng-container>
                <ng-container *ngIf="matDrawerContext === 'upload'">
                    <app-upload [multiple]="true" [headerTitle]="'Add Policy File'" [basePath]="'tasks/policy'"
                        (fileUploaded)="updatePolicyRequestDocument($event)" (onClose)="closeDrawer()">
                    </app-upload>
                </ng-container>
            </ng-container>

        </mat-drawer>
        <mat-drawer-content class="flex flex-auto overflow-auto bg-default grow p-8">
            <div class="flex flex-auto w-full max-w-sm sm:max-w-5xl lg:max-w-full">
                <!-- Policy File Section -->
                <div class="w-[30%] flex flex-col justify-center items-center p-4 bg-white">
                    <ng-container *ngIf="insuranceRetrieval?.insurer?.languages[currentLanguage] as insurerLang">
                        <img [alt]="insurerLang.name" [src]="insurerLang.logo" class="w-50 mx-auto" />
                    </ng-container>
                    <button
                        class="mt-4 flex items-center justify-center w-full py-2 px-4 border border-transparent text-sm font-medium rounded-md text-secondary hover:text-black"
                        (click)="openDrawer('upload')">
                        + Add or replace policy file
                    </button>
                    <ng-container *ngIf="insuranceRetrieval?.documents && insuranceRetrieval.documents.length > 0">
                        <div class="grid gap-4 mt-4 max-h-96 overflow-auto"
                            [class]="insuranceRetrieval.documents.length === 1 ? 'grid-cols-1' : 'grid-cols-2'">
                            <ng-container *ngFor="let document of insuranceRetrieval.documents; let i = index">
                                <a [href]="document.path" target="_blank"
                                    class="flex flex-col items-center justify-center mb-2 last:mb-0">
                                    <mat-icon class="icon-size-10" svgIcon="mat_solid:file_present"></mat-icon>
                                    <div class="py-2 px-4 border border-transparent text-sm font-medium rounded-md text-secondary hover:text-black truncate"
                                        [matTooltip]="document.filename">
                                        {{ document.filename }}
                                    </div>
                                </a>
                            </ng-container>
                        </div>
                    </ng-container>
                </div>

                <!-- Policy Request Activities -->
                <div class="w-[70%] p-4 bg-white ml-4 shadow relative overflow-auto">
                    <div class="flex justify-between items-center border p-4 bg-gray-50">
                        <h2 class="text-lg font-bold">
                            Policy request: Activities
                        </h2>
                        <div class="flex justify-center items-center gap-3">
                            <button [matTooltip]="!unpublishEventIds.length ? null : 'Publish all activities'"
                                [disabled]="!unpublishEventIds.length" (click)="publishAllEvent()">
                                <mat-icon class="icon-size-6" svgIcon="mat_outline:published_with_changes"></mat-icon>
                            </button>
                        </div>
                    </div>
                    <div class="overflow-x-auto relative">
                        <table mat-table class="min-w-full divide-y divide-gray-200 relative">
                            <!-- Loading indicator -->
                            <div *ngIf="eventsLoading" class="absolute inset-x-0 top-0">
                                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                            </div>
                            <thead>
                                <tr>
                                    <th scope="col"
                                        class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Date
                                    </th>
                                    <th scope="col"
                                        class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Time
                                    </th>
                                    <th scope="col"
                                        class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Who
                                    </th>
                                    <th scope="col"
                                        class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        What
                                    </th>
                                    <th scope="col"
                                        class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Status
                                    </th>
                                    <th scope="col"
                                        class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Actions
                                    </th>
                                </tr>
                            </thead>
                            <tbody class="bg-white divide-y divide-gray-200">
                                <ng-container *ngFor="let event of events">
                                    <tr>
                                        <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                            {{ event.createdAt | toDate | date: 'dd.MM.yyyy' }}
                                        </td>
                                        <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                            {{ event.createdAt | toDate | date: 'HH:mm' }}
                                        </td>
                                        <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                            {{ event.createdBy.displayName }}
                                        </td>
                                        <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 truncate max-w-80"
                                            [innerHTML]="event | eventMessage" [matTooltip]="event | eventMessage | stripHtml">
                                        </td>
                                        <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 capitalize">
                                            {{ event?.status || 'unpublished' }}
                                        </td>
                                        <td class="flex justify-center items-center gap-2 px-6 py-4">
                                            <button
                                                [matTooltip]="!event?.status || event?.status === 'unpublished' ? 'Publish' : 'Unpublish'"
                                                (click)="updateEventStatus(event)">
                                                <mat-icon *ngIf="!event?.status || event?.status === 'unpublished'"
                                                    class="icon-size-5" svgIcon="mat_outline:publish">
                                                </mat-icon>
                                                <mat-icon *ngIf="event?.status && event?.status === 'published'"
                                                    class="icon-size-5" svgIcon="mat_outline:unpublished">
                                                </mat-icon>
                                            </button>
                                        </td>
                                    </tr>
                                </ng-container>
                            </tbody>
                        </table>
                    </div>
                    <div class="flex justify-center items-center border mt-4 p-2 rounded-sm">
                        <button 
                            class="flex items-center px-2 py-1 text-sm text-secondary hover:text-black"
                            [disabled]="!insuranceRetrieval"
                            (click)="openDrawer('request')"
                        >
                            + Add Activity or comment
                        </button>
                    </div>
                </div>
            </div>
        </mat-drawer-content>
    </mat-drawer-container>
</div>