export const LanguageCode = {
    English: 'EN',
    German: 'DE',
    Italian: 'IT',
} as const;

export type LanguageCode = (typeof LanguageCode)[keyof typeof LanguageCode];

export const LanguageCodes = {
    English: 'EN',
    German: 'DE',
    Italian: 'IT',
    French: 'FR',
} as const;

export type LanguageCodes = (typeof LanguageCodes)[keyof typeof LanguageCodes];

export interface GenericPagination {
    length?: number;
    size?: number;
    page?: number;
    sort?: string;
    order?: string;
    search?: string;
    lastPage?: number;
    startIndex?: number;
    endIndex?: number;
}