import { EventEmitter, Injectable, Output, inject } from '@angular/core';
import {
    DocumentData,
    Firestore,
    addDoc,
    collection,
    collectionData,
    deleteDoc,
    doc,
    getDoc,
    getDocs,
    orderBy,
    query,
    setDoc,
    updateDoc,
    where,
} from '@angular/fire/firestore';
import { PeopleAndObjects } from 'app/models/people-objects';
import { Observable, Subject, from, map, takeUntil } from 'rxjs';
import { SwissLuxonDateAdapter } from 'app/common/services/swiss-luxondateadapter';
import { PeopleAndObjectsEntity } from 'app/models/db/peoples-and-objects.model';

@Injectable({
    providedIn: 'root',
})
export class PeopleObjectsService {
    pipe() {
        throw new Error('Method not implemented.');
    }
    private _firestore = inject(Firestore);
    private _collection = 'people-and-objects';
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    private swissLuxonDateAdapter = inject(SwissLuxonDateAdapter);

    peopleOAndObjectsRef = collection(this._firestore, this._collection);

    /**
     * @deprecated
     */
    insurances$ = collectionData(
        query(this.peopleOAndObjectsRef, orderBy('firstName'), orderBy('lastName')),
        { idField: 'uid' }
    ).pipe(
        takeUntil(this._unsubscribeAll),
        map((snapshot) => snapshot.map((doc: DocumentData) => doc as PeopleAndObjects))
    );

    constructor() {}

    /**
     * Get people and objects by user
     * @param id: string
     * @returns
     */

    //TODO: Update getById and update function to use new entity type
    getByid(id: string): Promise<PeopleAndObjects> {
        return getDoc(doc(this._firestore, this._collection, id)).then((doc) => {
            return {
                ...doc.data(),
                id: doc.id,
            } as PeopleAndObjects;
        });
    }

    getAll$(): Observable<PeopleAndObjects[]> {
        return collectionData(this.peopleOAndObjectsRef, { idField: 'id' }).pipe(
            map((snapshot) =>
                snapshot.map((doc) => {
                    return {
                        ...doc,
                        id: doc.id,
                    } as PeopleAndObjects;
                })
            )
        );
    }

    update(beneficiary: PeopleAndObjects): Promise<void> {
        beneficiary['birthday'] = this.swissLuxonDateAdapter.toIsoDateFormat(
            beneficiary['birthday']
        );
        if (beneficiary.ownerId === null) {
            beneficiary['relationship'] = 'Me';
            beneficiary.ownerId = beneficiary.id;
        }
        const { id, ...beneficiaryForUpdate } = beneficiary;

        const beneficiaryRef = doc(this._firestore, this._collection, id);
        // Improvement. Provide type.
        return setDoc(beneficiaryRef, beneficiaryForUpdate as any);
    }

    save(data: PeopleAndObjects) {
        console.log('data to save >>', data);
        const id = data?.id;
        data['birthday'] = this.swissLuxonDateAdapter.toIsoDateFormat(data['birthday']);
        delete data['id'];

        if (id) {
            const ref = doc(this._firestore, this._collection, data.id);
            return updateDoc(ref, data);
        } else {
            return addDoc(this.peopleOAndObjectsRef, data);
        }
    }

    delete(peopleAndObjectsId: string): Observable<void> {
        const beneficiaryRef = doc(this._firestore, this._collection, peopleAndObjectsId);
        return from(deleteDoc(beneficiaryRef));
    }

    getByIds(ids: string[]): Promise<PeopleAndObjects[]> {
        const productsQuery = query(
            this.peopleOAndObjectsRef,
            where('__name__', 'in', ids)
        );

        return getDocs(productsQuery).then((querySnapshot) => {
            const products: PeopleAndObjects[] = querySnapshot.docs.map((doc) => {
                const docId = doc.id;
                const product = { id: docId, ...doc.data() } as PeopleAndObjects;
                return product;
            });
            return products;
        });
    }

    //added temporary function to get people and objects as an observable instead of using PeopleAndObjectsEntity[]
    getByIds$(ids: string[]): Observable<PeopleAndObjects[]> {
        const peopleAndObjectsQuery = query(
            this.peopleOAndObjectsRef,
            where('__name__', 'in', ids)
        );

        return collectionData(peopleAndObjectsQuery, { idField: 'id' }).pipe(
            map((snapshot) => snapshot.map((doc) => doc as PeopleAndObjects))
        );
    }

    getAllByIds$(ids: string[]): Observable<PeopleAndObjectsEntity[]> {
        const peopleAndObjectsQuery = query(
            this.peopleOAndObjectsRef,
            where('__name__', 'in', ids)
        );

        return collectionData(peopleAndObjectsQuery, { idField: 'id' }).pipe(
            map((snapshot) => snapshot.map((doc) => doc as PeopleAndObjectsEntity))
        );
    }

    // get data based on owner id

    getAllByOwnerId$(userId: string): Observable<PeopleAndObjects[]> {
        const peopleAndObjectsQuery = query(
            this.peopleOAndObjectsRef,
            where('ownerId', '==', userId)
        );

        return collectionData(peopleAndObjectsQuery, { idField: 'id' }).pipe(
            map((snapshot) => snapshot.map((doc) => doc as PeopleAndObjects))
        );
    }
}
