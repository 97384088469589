import { DocumentReference, Timestamp } from '@angular/fire/firestore';
import { Insurer as InsurerCore } from './core/insurer.model';
import { PeopleAndObjects } from './people-objects';
import { PaymentPeriodicity } from './insurance.model';

export const CommentTypeMapping = {
    general: 'general',
    'user-message': 'userMessage',
    'insurer-contact': 'insurerContact',
    'admin-update': 'adminUpdate',
} as const;

export const StatusMapping = {
    pending: 'Pending',
    'in-progress': 'In Progress',
    completed: 'Completed',
    closed: 'Closed',
} as const;

export type Status = 'pending' | 'in-progress' | 'completed' | 'closed';
export type CommentType = 'general' | 'user-message' | 'insurer-contact' | 'admin-update';
export type Urgency = 'low' | 'medium' | 'high';

export type ContractData = {
    insurer: DocumentReference | InsurerCore | string;
    holder: DocumentReference | PeopleAndObjects | string;
    contractDate: string;
    effectiveDate: string;
    expirationDate: string;
    paymentPeriodicity: PaymentPeriodicity;
};

export type User = {
    uid: string;
    displayName: string;
    email: string;
    photoURL: string;
};

export type Comment = {
    id: string;
    user: User | null;
    type: CommentType;
    description: string;
    createdAt: Timestamp | Date;
    updatedAt: Timestamp | Date;
};

export type DocumentUpload = {
    id?: string;
    taskId: string;
    path: string;
    filename?: string;
    status: Status;
    user: User;
    comments: Comment[];
    urgency: Urgency;
    assignee: User | null;
    beneficiaries?: DocumentReference[] | string[] | PeopleAndObjects[];
    contractData?: ContractData | null;
    createdAt: Timestamp | Date;
    updatedAt: Timestamp | Date;
};
