import { Component, EventEmitter, Input, Output, LOCALE_ID, Inject } from '@angular/core';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { TranslocoService, TranslocoModule } from '@ngneat/transloco';
import { CommonModule } from '@angular/common';
import { ProductService } from '../../services/product.service';
import { InsuranceTypeCode } from 'app/models/insurance.model';
import { NgSelectModule } from '@ng-select/ng-select';
import { Insurer as InsurerCore } from 'app/models/core/insurer.model';
import { InsurersService } from 'app/modules/admin/apps/insurers/insurers.service';
import { Observable } from 'rxjs';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';

@Component({
    selector: 'app-add-product-form',
    templateUrl: './add-product-form.component.html',
    styleUrls: ['./add-product-form.component.scss'],
    standalone: true,
    imports: [MatFormFieldModule, MatInputModule, MatButtonModule, FormsModule, TranslocoModule, CommonModule, NgSelectModule, MatSnackBarModule],
})
export class AddProductFormComponent {
    @Input() isAddProduct: boolean = true;
    @Input() insuranceType: string = '';
    @Input() insurerId: string = '';
    @Input() insurerName: string = '';
    @Output() closeForm = new EventEmitter<any>();

    productName: string = '';
    processing: boolean = false;

    independentName: string = '';
    enName: string = '';
    deName: string = '';
    frName: string = '';
    itName: string = '';
    description: string = '';
    link: string = '';


    insuranceTypeCodes: InsuranceTypeCode[] = [
        'Auto',
        'Health - Basic',
        'Health - Additional',
        'Home',
        'Life',
        'Travel',
    ];
    insurerItems$: Observable<InsurerCore[]>;
    selectedType: any;
    selectedInsurer: any;


    constructor(private productService: ProductService, private translocoService: TranslocoService,
        private _insurersService: InsurersService, private _snackbar: MatSnackBar,
        @Inject(LOCALE_ID) private locale: string) { }

    ngOnInit() {
        this.insurerItems$ = this._insurersService.getAllLocalized$();
        if (this.isAddProduct) {
            // Initialize selectedType and selectedInsurer only if isAddProduct is true
            this.selectedType = null;
            this.selectedInsurer = null;
        }
    }

    async onSave(): Promise<void> {
        if (this.independentName) {
            console.log('Current Locale:', this.locale);
            try {
                this.processing = true;
                const newProduct = await this.productService.createProduct({
                    insuranceType: this.insuranceType,
                    insurerId: this.insurerId,
                    independentName: this.independentName,
                    description: this.description,
                    link: this.link,
                    languages: {
                        EN: { name: this.enName },
                        DE: { name: this.deName },
                        FR: { name: this.frName },
                        IT: { name: this.itName },
                        // Add similar entries for other languages
                    },
                });
                const savedMessage = 'Product saved.'//this.translocoService.translate('insurers.insurerSaved');
                this._snackbar.open(savedMessage, 'Success', {
                    duration: 3000,
                });
                this.closeForm.emit(newProduct);
                this.processing = false;
                this.onCancel(); // Ensure that no details form is open
            } catch (error) {
                console.error('Error creating product:', error);
            }
        }
    }

    onNgSelectInsuranceChange(event: any): void {
        console.log('event>>', event);
        this.insurerId = event.id;

    }

    onNgSelectTypeChange(event: any): void {
        console.log('type>>', event);
        this.insuranceType = event;
    }

    onCancel(): void {
        // If the dialog reference is available, close the modal
        this.closeForm.emit();
    }


    translateButton(): string {
        const key = this.processing === true ? 'product.saving' : 'product.save';
        return this.translocoService.translate(key);
    }


}
