import { Injectable } from "@angular/core";
import { LuxonDateAdapter } from "@angular/material-luxon-adapter";
import { assertNever } from "app/core/helpers/helper";
import { DateTime } from 'luxon';

@Injectable({ providedIn: 'root' })
export class SwissLuxonDateAdapter extends LuxonDateAdapter {

    private supportedFormats = [
        'dd.MM.yyyy', // Padded day, padded month
        'd.MM.yyyy',  // Non-padded day, padded month
        'dd.M.yyyy',  // Padded day, non-padded month
        'd.M.yyyy'    // Non-padded day, non-padded month
    ];

    private swissDateDisplayFormat = "dd.MM.yyyy";

    parse(value: any): DateTime | null {

        // Guard: Check if value is already a valid DateTime instance
        if (value instanceof DateTime && value.isValid) {
            return value;
        }

        // Guard: Check if value is a valid JS Date instance
        if (value instanceof Date) {
            return DateTime.fromJSDate(value);
        }

        // Guard: Check if value is a string and non-empty
        if (typeof value === 'string' && value.trim().length > 0) {
            for (const format of this.supportedFormats) {
                const dt = DateTime.fromFormat(value, format, { locale: this.locale });
                if (dt.isValid) {
                    return dt;
                }
            }
        }

        // Default: Return null if none of the above conditions are meta
        return null;
    }

    format(date: DateTime, displayFormat: Object): string {
        return date.toFormat(this.swissDateDisplayFormat);
    }

    toSwissFormat(dateString: string): string {
        return DateTime.fromISO(dateString)
                        .toFormat(this.swissDateDisplayFormat);
    }

    toIsoDateFormat(dateInput: string | DateTime): string {
        const dateIsoFormat = "yyyy-MM-dd";
        if (dateInput instanceof DateTime) {
            return dateInput.toFormat(dateIsoFormat);
        }
        if (typeof dateInput === "string") {
            return DateTime.fromISO(dateInput)
                        .toFormat(dateIsoFormat);
        }
        return assertNever(dateInput);
    }
}
