import { TextFieldModule } from '@angular/cdk/text-field';
import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    inject,
    Input,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewEncapsulation,
    ViewChild,
} from '@angular/core';
import {
    FormsModule,
    ReactiveFormsModule,
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators,
    AbstractControl,
    ValidatorFn,
    ValidationErrors
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DateAdapter, MatRippleModule } from '@angular/material/core';
import {
    MatDialog,
    MatDialogModule,
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import {
    tap,
    map,
    filter,
    Subject,
    connect,
    takeUntil,
    Observable,
    debounceTime,
    Subscription,
    combineLatest,
    distinctUntilChanged,
} from 'rxjs';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {
    DocumentData,
    collectionData,
    query,
    where,
} from '@angular/fire/firestore';
import { User } from '@angular/fire/auth';

import { NgSelectConfig, NgSelectModule, NgSelectComponent } from '@ng-select/ng-select';
import { TranslocoService, TranslocoModule } from '@ngneat/transloco';
import { Decimal } from 'decimal.js';
import { isEqual, cloneDeep } from 'lodash-es';


import {
    CaptureStatusCode,
    IInsurance,
    Insurance,
    InsuranceTypeCode,
    PremiumDiscounts
} from 'app/models/insurance.model';

import { AuthService } from '../../../../../../core/auth/auth.service';
import { InventoryService } from '../../../../../../modules/admin/apps/ecommerce/inventory/inventory.service';

import { DocumentUpload, ContractData } from '../../../../../../models/document-uploads.model';
import { Insurer as InsurerCore } from '../../../../../../models/core/insurer.model';
import { PeopleAndObjects } from '../../../../../../models/people-objects';
import { Product } from '../../../../../../models/product.model';

import { LoadingOverlayComponent } from '../../../../../../common/components/loading-overlay/loading-overlay.component';
import { AddProductFormComponent } from '../../../../../../common/components/add-product-form/add-product-form.component';
import { SelectProductComponent } from '../../../../../../common/components/select-product/select-product.component';
import { PersonFormatterService } from '../../../../../../common/services/person.formatter.service';
import { SwissLuxonDateAdapter } from '../../../../../../common/services/swiss-luxondateadapter';
import { PdfViewService } from '../../../../../../common/components/pdf-view/pdf-view.service';
import { getFormControlNames, tryParseOrDefault } from '../../../../../../common/helpers';
import { EventService } from '../../../../../../common/services/event.service';

import { HealthInsuranceComponent } from './insurance-type-forms/health-insurance/health-insurance.component';
import { AutoInsuranceComponent } from './insurance-type-forms/auto-insurance/auto-insurance.component';
import { InsurerDetailsComponent } from '../../../insurers/insurer-details/insurer-details.component';
import { ObjectFormComponent } from '../../../people-objects/object-form/object-form.component';
import { DocumentUploadService } from '../../../document-uploads/document-upload.service';
import { PeopleObjectsService } from '../../../people-objects/people-objects.service';
import { InsuranceService } from '../../../insurances/insurance.service';
import { InsurersService } from '../../../insurers/insurers.service';


type ExtendedInsurance = Insurance & { isNewInsurance?: boolean };

type Data = {
    loggedInUser: User,
    people: PeopleAndObjects[]
    insurers: InsurerCore[]
};

@Component({
    selector: 'insurance-details',
    templateUrl: './insurance-details.component.html',
    styleUrls: ['./insurance-details.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        MatButtonModule,
        MatIconModule,
        FormsModule,
        TextFieldModule,
        NgFor,
        MatCheckboxModule,
        NgClass,
        MatRippleModule,
        MatMenuModule,
        MatDialogModule,
        AsyncPipe,
        MatRadioModule,
        MatStepperModule,
        MatInputModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        MatSelectModule,
        MatProgressSpinnerModule,
        MatDatepickerModule,
        MatNativeDateModule,
        AutoInsuranceComponent,
        HealthInsuranceComponent,
        LoadingOverlayComponent,
        SelectProductComponent,
        NgSelectModule,
        InsurerDetailsComponent,
        AddProductFormComponent,
        ObjectFormComponent,
        TranslocoModule
    ],
    providers: [
        // Material Date Adapter
        {
            provide: DateAdapter,
            useClass: SwissLuxonDateAdapter,
        },
    ]
})
export class InsuranceDetailsComponent implements OnInit, OnDestroy {

    @Input({ required: true }) insurance: ExtendedInsurance = null;
    @Output() insuranceChange: EventEmitter<ExtendedInsurance> = new EventEmitter();

    @Input() method: 'create' | 'duplicate' | 'update' = 'create';
    @Input() mode: 'dialog' | 'inline' = 'dialog';
    /**
     * @deprecated use the uploaded document instead [InsuranceDetailsComponent.document].
     */
    @Input() documentId: string;
    @Input({ required: true }) document: DocumentUpload;
    @Input() isInAdmin: boolean = false;
    @Input() isInAdminTask: boolean = false;

    @Output() deleteInsurance: EventEmitter<ExtendedInsurance> = new EventEmitter();
    @Output() closeDetails: EventEmitter<boolean> = new EventEmitter();
    @Output() detailsIsLoaded: EventEmitter<void> = new EventEmitter();

    @ViewChild('ngSelectInsurance') ngSelectInsurance: NgSelectComponent;
    @ViewChild('ngSelectInsuranceHolder') ngSelectInsuranceHolder: NgSelectComponent;
    @ViewChild(HealthInsuranceComponent) healthInsuranceComponent: HealthInsuranceComponent;


    horizontalStepperForm: UntypedFormGroup;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    private auth: AuthService = inject(AuthService);
    private _peopleAndObjectService = inject(PeopleObjectsService);
    
    // Define maximum deductible amount
    maxDeductible: number = 0;
    isLoading: boolean = false;
    /**
     * Needs Assessment. Assess if still needed. Defaulted to true in admin view.
     */
    isAdmin: boolean = this._authService.isAdmin();
    language: string = 'de-CH'

    insuranceForm: UntypedFormGroup;
    insuranceTypeCodes: InsuranceTypeCode[] = [
        'Auto',
        'Health - Basic',
        'Health - Additional',
        'Home',
        'Life',
        'Travel',
    ];

    peopleOAndObjectsRef = this._peopleAndObjectService.peopleOAndObjectsRef;
    peopleAndObjectsDialogRef = undefined;

    flashMessage: 'success' | 'error' | null = null;

    public insuranceFormFields = [];

    get isMethodCreate() {
        return ['create', 'duplicate'].includes(this.method);
    }

    get isMethodUpdate() {
        return this.method === 'update';
    }

    get isModeDialog() {
        return this.mode === 'dialog';
    }

    get submitAction() {
        return this.insurance.isNewInsurance
            ? this.createInsurance()
            : this.updateSelectedInsurance();
    }

    get deleteText() {
        return 'Delete';
    }

    get submitText() {
        // return this.isMethodCreate ? 'Create' : 'Save';
        return this.insurance.isNewInsurance
            ? this.translocoService.translate('admin.docUploads.create')
            : this.translocoService.translate('admin.docUploads.save');
    }

    get successText() {
        return this.isMethodCreate ? 'Insurance created' : 'Insurance updated';
    }

    get selectedInsurerName() {
        const insurerId = this.insuranceForm.value.insurerId;
        return this.insurers.find(insurer => insurer.id === insurerId)?.displayName;
    }

    // Improvement. Convert to Map (lookup) and move to pipe.
    selectedInsurerLogo(insurers: InsurerCore[]) {
        const insurerId = this.insuranceForm.value.insurerId;
        return insurers.find((insurer) => insurer.id === insurerId)
            ?.displayLogo;
    }


    private readonly defaultItemAddOptionId = "0";

    private swissLuxonDateAdapter = inject(SwissLuxonDateAdapter);
    private personFormatterService = inject(PersonFormatterService);

    data$: Subject<Data> = new Subject();
    insurers: InsurerCore[] = [];

    // toggle add new forms variables
    public toggleInsurerForm: boolean = false;
    public toggleProductForm: boolean = false;
    public togglePeopleAndObjectForm: boolean = false;
    private healthFormGroupName: string = 'beneficiary'; // type:'beneficiary' | 'holder'
    defaultPersonEntity: PeopleAndObjects;
    langChangeSubscription: Subscription;
    addItem: string = '';
    private languageSubscription: Subscription;
    selectedProduct: Product | null = null;
    currentLanguage: string;

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _formBuilder: UntypedFormBuilder,
        private _matDialog: MatDialog,
        private _inventoryService: InventoryService,
        private _translocoService: TranslocoService,
        private _insurersService: InsurersService,
        private _authService: AuthService,
        private _pdfViewService: PdfViewService,
        private ngSelectConfig: NgSelectConfig,
        private _insuranceService: InsuranceService,
        private translocoService: TranslocoService,
        private documentUploadService: DocumentUploadService,
        private eventService: EventService,
    ) {
        function twoDigitDecimalValidator(): ValidatorFn {
            return (control: AbstractControl): ValidationErrors | null => {
                if (control.value === null || control.value === '') {
                    return null; // Don't validate empty value
                }
                const valid = /^\d+(\.\d{0,2})?$/.test(control.value);
                return valid ? null : { 'invalidDecimal': { value: control.value } };
            };

        }

        // Create the selected product form
        this.insuranceForm = this._formBuilder.group({
            id: [''],
            insurerId: [null, [Validators.required]],
            productId: [null, [Validators.required]],
            type: ['', [Validators.required]],
            holder: this._formBuilder.group({
                id: [null, [Validators.required]],
                name: [null, [Validators.required]],
            }),
            policyDate: ['', [Validators.required]],
            effectiveDate: ['', [Validators.required]],
            expirationDate: ['', [Validators.required]],
            referenceNumber: [''],
            captureStatus: [''],
            policyPdf: [''],
            vehicle: this._formBuilder.group({
                make: [''], //These have required validators in their input as adding the validator here would require these fields to be filled up even if type !== "Auto"
                model: [''],
                year: [''],
                VIM: [''],
            }),
            details: this._formBuilder.group({
                information: [''],
            }),
            health: this._formBuilder.group({
                beneficiary: this._formBuilder.group({
                    id: [null],
                    name: [null],
                }),
                insureeNumber: [''],
            }),
            paymentPeriodicity: [null, [Validators.required]],
            premium: this._formBuilder.group({
                periodicityNumMonths: [null, [Validators.required]],
                grossCents: [null, [Validators.required, twoDigitDecimalValidator()]],
                netCents: [null, [Validators.required, twoDigitDecimalValidator()]],
                discounts: this._formBuilder.group({
                    associationDiscount: this._formBuilder.group({
                        percentage: [null],
                        amountCents: [null, [twoDigitDecimalValidator()]]
                    }),
                    familyDiscount: this._formBuilder.group({
                        percentage: [null],
                        amountCents: [null, [twoDigitDecimalValidator()]]
                    }),
                    loyaltyDiscount: this._formBuilder.group({
                        percentage: [null],
                        amountCents: [null, [twoDigitDecimalValidator()]]
                    }),
                    environmentalDiscount: this._formBuilder.group({
                        percentage: [null],
                        amountCents: [null, [twoDigitDecimalValidator()]]
                    }),
                    otherDiscounts: this._formBuilder.group({
                        percentage: [null],
                        amountCents: [null, [twoDigitDecimalValidator()]]
                    }),
                }),
            }),
            healthBasic: this._formBuilder.group({
                coverage: this._formBuilder.group({
                    deductiblePercentage: [null],
                    deductibleAbsoluteCents: [null, [twoDigitDecimalValidator()]],
                    deductibleMaximalCents: [null, [twoDigitDecimalValidator()]],
                    franchiseCents: [null, [Validators.required, twoDigitDecimalValidator()]]
                })
            }),
            collectiveContract: [''],
        });

        this._inventoryService.insuranceFormFields = getFormControlNames(
            this.insuranceForm
        );

        this.ngSelectConfig.notFoundText = 'Person not found';
        // this.ngSelectConfig.appendTo = 'body';
        this.ngSelectConfig.bindValue = 'value';

        this.languageSubscription = this.translocoService.langChanges$.subscribe(
            (lang: string) => {
                this.currentLanguage = lang.toUpperCase();
            }
        );
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    ngOnChanges(changes: SimpleChanges): void {
        if (changes['insurance']) {
            if (!isEqual(changes['insurance'].previousValue, changes['insurance'].currentValue)) {
                const data = changes['insurance'].currentValue;
                this.insuranceForm.patchValue({
                    ...data,
                    id: data?.id,
                    insurerId: data?.insurerId || null,
                    productId: data?.productId || null,
                    premium: {
                        ...data.premium,
                        grossCents: this.convertCentsToDecimal(data.premium?.grossCents),
                        netCents: this.convertCentsToDecimal(data.premium?.netCents),
                        discounts: {
                            associationDiscount: {
                                ...data.premium?.discounts?.associationDiscount,
                                amountCents: this.convertCentsToDecimal(data.premium?.discounts?.associationDiscount?.amountCents),
                            },
                            familyDiscount: {
                                ...data.premium?.discounts?.familyDiscount,
                                amountCents: this.convertCentsToDecimal(data.premium?.discounts?.familyDiscount?.amountCents),
                            },
                            loyaltyDiscount: {
                                ...data.premium?.discounts?.loyaltyDiscount,
                                amountCents: this.convertCentsToDecimal(data.premium?.discounts?.loyaltyDiscount?.amountCents),
                            },
                            environmentalDiscount: {
                                ...data.premium?.discounts?.environmentalDiscount,
                                amountCents: this.convertCentsToDecimal(data.premium?.discounts?.environmentalDiscount?.amountCents),
                            },
                            otherDiscounts: {
                                ...data.premium?.discounts?.otherDiscounts,
                                amountCents: this.convertCentsToDecimal(data.premium?.discounts?.otherDiscounts?.amountCents),
                            },
                        },
                    },
                    healthBasic: {
                        ...data.healthBasic,
                        coverage: {
                            ...data.healthBasic?.coverage,
                            deductibleAbsoluteCents: this.convertCentsToDecimal(data.healthBasic?.coverage?.deductibleAbsoluteCents),
                            deductibleMaximalCents: this.convertCentsToDecimal(data.healthBasic?.coverage?.deductibleMaximalCents),
                            franchiseCents: this.convertCentsToDecimal(data.healthBasic?.coverage?.franchiseCents),
                        }
                    },
                });
            }
        }

        if (changes['method']) {
            this.method = changes['method'].currentValue;
        }

        if (changes['documentId']) {
            this.documentId = changes['documentId'].currentValue;
        }

        if (changes['document']) {
            this.document = changes['document'].currentValue;
            const contractData = (this.document?.contractData || null) as ContractData;
            if (contractData && this.method === 'create') {
                const contractInsurer = (contractData?.insurer || {}) as InsurerCore;
                const contractHolder = (contractData?.holder || {}) as PeopleAndObjects;
                const holderName = contractData
                    ? `${contractHolder?.lastName}, ${
                        contractHolder?.firstName
                    } ${this.swissLuxonDateAdapter.toSwissFormat(contractHolder?.birthday)}`
                    : null;
                this.insuranceForm.patchValue({
                    insurerId: contractInsurer.id,
                    holder: {
                        id: contractHolder.id,
                        name: holderName,
                    },
                    policyDate: contractData.contractDate,
                    effectiveDate: contractData.effectiveDate,
                    expirationDate: contractData.expirationDate,
                    paymentPeriodicity: contractData.paymentPeriodicity,
                })
            }
        }
    }


    private getPeople$(ownerId: string): Observable<PeopleAndObjects[]> {

        const getPeopleAndObjects$ = (ownerId: string): Observable<PeopleAndObjects[]> => {

            // Improvement. move to service.
            return collectionData(
                query(this.peopleOAndObjectsRef, where('ownerId', '==', ownerId)),
                { idField: 'id' }
            )
                .pipe(
                    map((snapshot) => snapshot.map((doc: DocumentData) => doc as PeopleAndObjects))
                );
        };

        const derivePersonRenderOption = (peopleAndObjects: PeopleAndObjects[]): PeopleAndObjects[] => {

            // Used in view, widget selection.
            const defaultPersonEntity: PeopleAndObjects = {
                id: this.defaultItemAddOptionId,
                ownerId: "",
                firstName: '',
                lastName: '',
                name: this.addItem,
                birthday: "",
                type: 'PERSON',
                relationship: 'Others',
            };

            // Get only the people entities.
            const peopleAndDefaultPersonEntity: PeopleAndObjects[] = peopleAndObjects
                // Improvement. Prefer to filter in database.
                .filter(peopleAndObject => peopleAndObject.type === "PERSON")
                .concat([defaultPersonEntity])
                .map(person => ({
                    ...person,
                    name: person.id === this.defaultItemAddOptionId ?
                        person.name : this.personFormatterService.nameWithBirthdate(person)
                }));

            return peopleAndDefaultPersonEntity;
        };

        return getPeopleAndObjects$(ownerId)
            .pipe(map(derivePersonRenderOption));
    }

    private getInsurers$(): Observable<InsurerCore[]> {
        const addNewItem: InsurerCore = {
            id: 'add_new',
            displayName: '+ Add item',
            displayLogo: '',
            languages: {
                DE: { name: '', logo: '' },
                EN: { name: '', logo: '' },
                IT: { name: '', logo: '' },
                FR: { name: '', logo: '' },
            },
        };

        return combineLatest([
            this._insurersService.getAllLocalized$(),
            this._translocoService.langChanges$,
        ]).pipe(
            map(([insurers, lang]) => {
                this.language = lang;
                // Prepending the "Add +" option
                const insurersWithAddNew = [addNewItem, ...insurers];
                return insurersWithAddNew
                    .sort((a, b) => a.displayName.localeCompare(b.displayName));
            }),
        );
    }

    private getData$(uploadedDocumentUserId: string): Observable<Data> {

        const user$: Observable<User> = this.auth.user;

        return user$.pipe(
            filter(user => !(user === null || user === undefined)),
            connect(sharedLoggedInUser$ =>
                combineLatest({
                    loggedInUser: sharedLoggedInUser$,
                    people: this.getPeople$(uploadedDocumentUserId),
                    insurers: this.getInsurers$(),
                })
            )
        );
    }

    private calculateDeductibleAbsolute(
        deductiblePercentage: number,
        netPremium: number
    ): number {
        const deductibleAbsolute = (netPremium * deductiblePercentage) / 100;
        return !Boolean(this.maxDeductible) ? deductibleAbsolute : Math.min(deductibleAbsolute, this.maxDeductible);
    }

    private calculateDeductiblePercentage(
        deductibleAbsolute: number,
        netPremium: number
    ): number {
        const deductiblePercentage = (deductibleAbsolute / netPremium) * 100;
        return deductiblePercentage;
    }

    private setupDeductibleAbsoluteListener(): void {
        this.insuranceForm
            .get('healthBasic.coverage.deductibleAbsoluteCents')
            .valueChanges.pipe(
                takeUntil(this._unsubscribeAll),
                debounceTime(500),
                distinctUntilChanged(),
            )
            .subscribe((value) => {
                const deductibleAbsolute = !Boolean(this.maxDeductible) ? value : Math.min(value, this.maxDeductible)
                const netPremium = this.insuranceForm.get('premium.netCents').value || 0;
                if (netPremium > 0) {
                    const deductiblePercentage = this.calculateDeductiblePercentage(
                        deductibleAbsolute,
                        netPremium
                    );

                    this.insuranceForm
                        .get('healthBasic.coverage.deductiblePercentage')
                        .setValue(deductiblePercentage, { emitEvent: false });
                    if (Boolean(this.maxDeductible) && value > this.maxDeductible) {
                        this.insuranceForm
                            .get('healthBasic.coverage.deductibleAbsoluteCents')
                            .setValue(deductibleAbsolute, { emitEvent: false });
                    }
                }
            });
    }

    private setupDeductiblePercentageListener(): void {
        this.insuranceForm
            .get('healthBasic.coverage.deductiblePercentage')
            .valueChanges.pipe(
                takeUntil(this._unsubscribeAll),
                debounceTime(500),
                distinctUntilChanged()
            )
            .subscribe((percentage) => {
                const netPremium = this.insuranceForm.get('premium.netCents').value;
                if (netPremium > 0) {
                    const deductibleAbsolute = this.calculateDeductibleAbsolute(
                        percentage,
                        netPremium
                    );
                    const deductiblePercentage = this.calculateDeductiblePercentage(
                        deductibleAbsolute,
                        netPremium
                    );

                    this.insuranceForm
                        .get('healthBasic.coverage.deductibleAbsoluteCents')
                        .setValue(deductibleAbsolute, { emitEvent: false });
                    if (percentage !== deductiblePercentage) {
                        this.insuranceForm
                            .get('healthBasic.coverage.deductiblePercentage')
                            .setValue(deductiblePercentage, { emitEvent: false });
                    }
                }
            });
    }

    private setupNetPremiumListener(): void {
        this.insuranceForm
            .get('premium.netCents')
            .valueChanges.pipe(
                takeUntil(this._unsubscribeAll),
                debounceTime(500),
                distinctUntilChanged()
            )
            .subscribe((netPremium) => {
                const deductiblePercentageControl = this.insuranceForm.get(
                    'healthBasic.coverage.deductiblePercentage'
                );
                const deductibleAbsoluteControl = this.insuranceForm.get(
                    'healthBasic.coverage.deductibleAbsoluteCents'
                );
                const deductiblePercentage = deductiblePercentageControl.value;
                const deductibleAbsolute = deductibleAbsoluteControl.value;
                // Check if either deductiblePercentage or deductibleAbsolute has value
                if (deductiblePercentage !== null && deductiblePercentage !== '') {
                    const calculatedDeductibleAbsolute = this.calculateDeductibleAbsolute(
                        deductiblePercentage,
                        netPremium
                    );
                    
                    deductibleAbsoluteControl.setValue(calculatedDeductibleAbsolute, {
                        emitEvent: false,
                    });
                } else if (deductibleAbsolute !== null && deductibleAbsolute !== '') {
                    const calculatedDeductiblePercentage =
                        this.calculateDeductiblePercentage(
                            deductibleAbsolute,
                            netPremium
                        );
                    deductiblePercentageControl.setValue(calculatedDeductiblePercentage, {
                        emitEvent: false,
                    });
                }
            });
    }

    private setupMaximalDeductibleListener(): void {
        this.insuranceForm
            .get('healthBasic.coverage.deductibleMaximalCents')
            .valueChanges.pipe(
                takeUntil(this._unsubscribeAll),
                debounceTime(500),
                distinctUntilChanged()
            )
            .subscribe((maxDeductible) => {
                this.maxDeductible = maxDeductible;
                const deductibleAbsoluteControl = this.insuranceForm.get('healthBasic.coverage.deductibleAbsoluteCents');
                const deductiblePercentageControl = this.insuranceForm.get('healthBasic.coverage.deductiblePercentage');
                const netPremium = this.insuranceForm.get('premium.netCents').value;
    
                if (deductibleAbsoluteControl.value > maxDeductible) {
                    const adjustedDeductibleAbsolute = maxDeductible;
                    deductibleAbsoluteControl.setValue(adjustedDeductibleAbsolute, { emitEvent: false });
    
                    if (netPremium > 0) {
                        const adjustedDeductiblePercentage = this.calculateDeductiblePercentage(
                            adjustedDeductibleAbsolute,
                            netPremium
                        );
                        deductiblePercentageControl.setValue(adjustedDeductiblePercentage, { emitEvent: false });
                    }
                }
            });
    }

    private initializeListeners(): void {
        this.setupDeductibleAbsoluteListener();
        this.setupDeductiblePercentageListener();
        this.setupNetPremiumListener();
        this.setupMaximalDeductibleListener();
    }

    /**
     * On init
     */
    ngOnInit(): void {
        this.getData$(this.document.user.uid)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(data => {

                // Needs Assessment. Assess if still needed.
                //  Defaulted to true in admin view.
                this.isAdmin = this._authService.isAdmin();

                // Pass to a single observable.
                this.data$.next(data);
            });


        // Listen to PDFViewer
        this._pdfViewService.contextMenu
            .pipe(
                tap(x => this.patchFieldWithSelection(x)),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe();

        this.data$.subscribe(data => {
            this.insurers = data.insurers;
            // Sort the data.people array alphabetically by name
            data.people.sort((a, b) => a.name.localeCompare(b.name));
            // Trigger change detection
            this._changeDetectorRef.markForCheck();
            this.detailsIsLoaded.emit();
        });

        // Subscribe to language changes
        this.langChangeSubscription = this.translocoService.langChanges$.subscribe(() => {
            this.addItem = this.translocoService.translate('product.addItem');
            this._changeDetectorRef.markForCheck();
        });

        this.initializeListeners();
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Propagate the latest form value on close.
        this.insuranceChange.emit(
            this.mapFormValueToExtendedInsurance(
                this.insuranceForm.getRawValue(),
                this.document.user.uid
            )
        );

        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();

        if (this.languageSubscription) {
            this.languageSubscription.unsubscribe();
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    async ensureBeneficiaryInDocument(insuranceBeneficiaryId: string): Promise<void> {
        // Check if the beneficiary is already listed in the document beneficiaries
        const beneficiaryExists = this.document.beneficiaries.some(
            (beneficiary) => beneficiary.id === insuranceBeneficiaryId
        );
    
        if (!beneficiaryExists) {
            // If not, add the beneficiary ID to the document beneficiaries and update
            const updatedBeneficiaryIds = [
                ...this.document.beneficiaries.map((beneficiary) => beneficiary.id),
                insuranceBeneficiaryId,
            ];
    
            try {
                await this.documentUploadService.updateBeneficiaries(
                    this.document.id,
                    updatedBeneficiaryIds
                );
            } catch (error) {
                console.error('Error updating document beneficiaries:', error);
            }
        }
    }

    async createInsurance(): Promise<void> {
        this.isLoading = true;
        const loadingOverlayData = {
            isLoading: true,
            loadingText: this.translocoService.translate('admin.docUploads.updatingInsurance'),
            successText: this.translocoService.translate('admin.docUploads.insuranceUpdated'),
        };
        const loadingOverlayRef = this._matDialog.open(
            LoadingOverlayComponent,
            {
                data: loadingOverlayData,
                disableClose: true,
            }
        );

        // Get the insurance object
        const insuranceForm = this.insuranceForm.getRawValue() as Insurance;

        // Convert all monetary values from dollars to cents
        const convertToCents = (value) => new Decimal(value || 0).times(100).toFixed(0);

        // Convert the premium values to cents
        if (insuranceForm.premium) {
            insuranceForm.premium.grossCents = parseFloat(convertToCents(insuranceForm.premium.grossCents));
            insuranceForm.premium.netCents = parseFloat(convertToCents(insuranceForm.premium.netCents));
            // Convert discount amounts to cents
            Object.values(insuranceForm.premium.discounts).forEach(discount => {
                if (discount) {
                    discount.amountCents = parseFloat(convertToCents(discount.amountCents));
                }
            });
        }

        // Convert the healthBasic coverage values to cents
        if (insuranceForm.healthBasic && insuranceForm.healthBasic.coverage) {
            const coverage = insuranceForm.healthBasic.coverage;
            coverage.deductibleAbsoluteCents = parseFloat(convertToCents(coverage.deductibleAbsoluteCents));
            coverage.deductibleMaximalCents = parseFloat(convertToCents(coverage.deductibleMaximalCents));
            coverage.franchiseCents = parseFloat(convertToCents(coverage.franchiseCents));
        }

        const insurance: IInsurance = {
            ...insuranceForm,
            policyDate: this.swissLuxonDateAdapter.toIsoDateFormat(insuranceForm.policyDate),
            effectiveDate: this.swissLuxonDateAdapter.toIsoDateFormat(insuranceForm.effectiveDate),
            expirationDate: this.swissLuxonDateAdapter.toIsoDateFormat(insuranceForm.expirationDate),
            userId: this.document.user.uid,
            captureStatus: CaptureStatusCode.Pending,
            documentId: this.documentId || '',
        };

        try {
            await this.ensureBeneficiaryInDocument(insurance.health.beneficiary.id);
            await this._insuranceService.create(insurance);
            this.showFlashMessage('success');
        } catch (error) {
            console.error('Error creating insurance:', error);
        } finally {
            this.isLoading = false;
            loadingOverlayRef.componentInstance.data = {
                ...loadingOverlayData,
                isLoading: false,
            };
            this.closeDetails.emit(true);
        }
    }

    /**
     * Show flash message 
     */
    showFlashMessage(type: 'success' | 'error'): void {
        // Show the message
        this.flashMessage = type;

        // Mark for check
        this._changeDetectorRef.markForCheck();

        // Hide it after 3 seconds
        setTimeout(() => {
            this.flashMessage = null;

            // Mark for check
            this._changeDetectorRef.markForCheck();
        }, 3000);
    }

    /**
     * Update the selected insurance using the form data
     */
    async updateSelectedInsurance(): Promise<void> {
        this.isLoading = true;
        const loadingOverlayData = {
            isLoading: true,
            loadingText: this.translocoService.translate('admin.docUploads.updatingInsurance'),
            successText: this.translocoService.translate('admin.docUploads.insuranceUpdated'),
        };
        const loadingOverlayRef = this._matDialog.open(
            LoadingOverlayComponent,
            {
                data: loadingOverlayData,
                disableClose: true,
            }
        );
        const insuranceForUpdate = this.mapFormValueToExtendedInsurance(
            this.insuranceForm.getRawValue(),
            this.document.user.uid
        );

        try {
            await this.ensureBeneficiaryInDocument(insuranceForUpdate.health.beneficiary.id);
            await this._insuranceService.update(insuranceForUpdate);

            if (this.method === 'update') {
                const product = this.selectedProduct || this.insurance.product;
                const productLang = product.languages[this.currentLanguage];
                const productName = productLang?.name || product.independentName;
                
                this.eventService.createEvent(
                    insuranceForUpdate.id,
                    insuranceForUpdate.userId,
                    'insurances',
                    'insurance-updated',
                    productName,
                );
            }

            this.showFlashMessage('success');
        } catch (error) {
            console.error('Error creating insurance:', error);
        } finally {
            this.isLoading = false;
            loadingOverlayRef.componentInstance.data = {
                ...loadingOverlayData,
                isLoading: false,
            };
            this.closeDetails.emit(true);
        }
    }

    /**
     *
     * @param Call parent to delete an Insurance
     */
    delete() {
        this.deleteInsurance.emit(this.insurance);
    }

    /**
     * Fill field with PDFViewer context menu selection
     * @param control
     */
    patchFieldWithSelection(selection) {
        if (selection !== null)
            selection.menu.control.patchValue(selection.selection);
    }

    onBeneficiaryChange(e) {
        this.onNgSelectChange(e.group, e.e);
    }

    onNgSelectChange(group: string, e: any) {
        if (!e) return;

        let form: UntypedFormGroup | AbstractControl = this.insuranceForm;
        this.healthFormGroupName = group;

        if (group === 'beneficiary') {
            form = form.get('health');
        }

        // If the option selected is adding.
        if (e.id === this.defaultItemAddOptionId) {
            form.patchValue({ [group]: { id: null, name: null } });
            this.togglePeopleAndObjectForm = true;
        } else {
            form.patchValue({
                [group]: { id: e.id, name: e.name },
            });
        }
        this._changeDetectorRef.markForCheck();
    }

    onNgSelectInsuranceChange(e: any) {
        if (!e) return;

        let form: UntypedFormGroup | AbstractControl = this.insuranceForm;

        // If the option selected is adding.
        if (e.id === 'add_new') {
            form.patchValue({ insurerId: null });
            this.toggleInsurerForm = true;
        } else {
            form.patchValue({ insurerId: e.id });
        }
        this._changeDetectorRef.markForCheck();
    }

    selectAll(event: any): void {
        event.target.select();
    }

    formatCentsValue(formControlName: string, value: number): void {
        // Convert the number to a string, if it's not already.
        let stringValue = value.toString();

        // Parse the string to a float to ensure it's a valid number.
        let numericValue = parseFloat(stringValue);

        // If the value is not a valid number, set to '0.00'.
        // Also, replace commas which might be added by previous formatting.
        const formattedValue = isNaN(numericValue) ? '0.00' : numericValue.toFixed(2);

        // Update the form control value with the formatted value without emitting the event.
        this.insuranceForm.get(formControlName).setValue(formattedValue, { emitEvent: false });
    }

    onProductSelect(product: Product | null): void {
        let form: UntypedFormGroup | AbstractControl = this.insuranceForm;
        this.selectedProduct = product;
        if (product.id === 'add-new') {
            form.patchValue({ productId: null });
            this.toggleProductForm = true;
        } else {
            form.patchValue({ productId: product.id });
        }
        this._changeDetectorRef.markForCheck();
    }

    getClass() {
        if (this.isModeDialog) {
            return 'p-4 pb-0 overflow-scroll max-h-192';
        } else {
            const typeValue = this.insuranceForm.get('type').value;
            const overflow = typeValue ? 'overflow-hidden' : 'overflow-visible';
            return !this.isInAdminTask ? `shadow-lg ${overflow}` : overflow;
        }
    }

    private convertCentsToDecimal(value: string | number): string {
        const cents = new Decimal(value || 0);
        return cents.dividedBy(100).toFixed(2);
    }

    closeInsurerForm(data: any): void {
        let form: UntypedFormGroup | AbstractControl = this.insuranceForm;
        if (data) {
            form.patchValue({ insurerId: data?.id });
        }
        this.toggleInsurerForm = false;
        this._changeDetectorRef.markForCheck();
    }

    closeProductForm(data: Product): void {
        let form: UntypedFormGroup | AbstractControl = this.insuranceForm;
        if (data) {
            this.selectedProduct = data;
            form.patchValue({ productId: data?.id });
        }
        this.toggleProductForm = false;
        this._changeDetectorRef.markForCheck();
    }

    closePeopleAndObject(data: any): void {
        let form: UntypedFormGroup | AbstractControl = this.insuranceForm;
        if (this.healthFormGroupName === 'beneficiary') {
            form = form.get('health');
        }

        if (data) {
            const formattedData = {
                id: data.id,
                name: `${data.lastName}, ${data.firstName} ${this.swissLuxonDateAdapter.toSwissFormat(data.birthday)}`,
            };
            form.patchValue({ [this.healthFormGroupName]: formattedData });
            if (this.healthInsuranceComponent) {
                this.healthInsuranceComponent?.unfocusBeneficiaryField();
            }
        }
        this.togglePeopleAndObjectForm = false;
        this._changeDetectorRef.markForCheck();
    }

    /**
     * Converts insurance form raw value to ExtendedInsurance.
     * Improvement. Typed form.
     * @param insuranceFormRawcValue 
     * @param documentUserId 
     * @returns A new insurance instance.
     */
    private mapFormValueToExtendedInsurance(insuranceFormRawcValue: any, documentUserId: string): ExtendedInsurance {

        // Get the insurance object
        const insurance = insuranceFormRawcValue;

        // Convert all monetary values from dollars to cents
        const convertToCents = (value) =>
            new Decimal(value || 0).times(100).toFixed(0);

        // Convert the premium values to cents
        if (insurance.premium) {
            insurance.premium.grossCents = convertToCents(
                insurance.premium.grossCents
            );
            insurance.premium.netCents = convertToCents(
                insurance.premium.netCents
            );
            // Convert discount amounts to cents
            Object.values(insurance.premium.discounts as PremiumDiscounts).forEach((discount) => {
                if (discount) {
                    discount.amountCents = parseFloat(convertToCents(discount.amountCents));
                }
            });
        }

        // Convert the healthBasic coverage values to cents
        if (insurance.healthBasic && insurance.healthBasic.coverage) {
            const coverage = insurance.healthBasic.coverage;
            coverage.deductibleAbsoluteCents = convertToCents(
                coverage.deductibleAbsoluteCents
            );
            coverage.deductibleMaximalCents = convertToCents(
                coverage.deductibleMaximalCents
            );
            coverage.franchiseCents = convertToCents(coverage.franchiseCents);
        }

        // Prepare the object to update, without the nested premium structure
        const extendedInsurance: ExtendedInsurance = {
            ...insurance,
            product: this.insurance.product,
            // Assuming the date adapter converts to a date string that the backend expects
            policyDate: this.swissLuxonDateAdapter.toIsoDateFormat(
                insurance.policyDate
            ),
            effectiveDate: this.swissLuxonDateAdapter.toIsoDateFormat(
                insurance.effectiveDate
            ),
            expirationDate: this.swissLuxonDateAdapter.toIsoDateFormat(
                insurance.expirationDate
            ),
            userId: documentUserId,
            isNewInsurance: this.insurance.isNewInsurance,
            createdAt: this.insurance.createdAt,
        };

        return cloneDeep(extendedInsurance);
    }
}
