export const environment = {
    name: 'development',
    production: false,
    firebase: {
        apiKey: 'AIzaSyCGM3G3Y-89mlxtho97KbEpV9OMiCEa_U0',
        authDomain: 'sicooro-dev-01.firebaseapp.com',
        projectId: 'sicooro-dev-01',
        storageBucket: 'sicooro-dev-01.appspot.com',
        messagingSenderId: '160206034593',
        appId: '1:160206034593:web:f523676e28518d20e6c2e0',
        measurementId: 'G-LKS8PHYMZ9',
    },
    baseUrl: 'https://dev01-app.covo.me',
};
