/* eslint-disable */
import { FuseNavigationItem } from '@fuse/components/navigation';
import { AuthService } from '../../../core/auth/auth.service';
import { inject } from '@angular/core';

export const adminNavigation: FuseNavigationItem[] = [
    {
        id   : 'admin.insurances',
        title: 'insurances',
        type : 'basic',
        icon : 'heroicons_outline:pencil-square',
        link : '/admin/insurances',
    },
    {
        id   : 'admin.insurers',
        title: 'insurers',
        type : 'basic',
        icon : 'heroicons_outline:building-office',
        link : '/admin/insurers',
    },
    {
        id   : 'admin.tasks',
        title: 'tasks',
        type : 'basic',
        icon : 'heroicons_outline:clipboard-document-check',
        link : '/admin/tasks',
    },
];

export const systemNavigation: FuseNavigationItem[] = [
    
    {
        id   : 'admin.insurers',
        title: 'insurers',
        type : 'basic',
        icon : 'heroicons_outline:building-office',
        link : '/admin/insurers',
    },
    {
        id   : 'admin.products',
        title: 'products',
        type : 'basic',
        icon : 'heroicons_outline:tag',
        link : '/admin/products', // component to be added
    },
    {
        id   : 'admin.claim',
        title: 'claims',
        type : 'basic',
        icon : 'heroicons_outline:clipboard-document-check',
        link : '', //claims component to be added

    },
];

export const userDataNavigation: FuseNavigationItem[] = [
    
    {
        id   : 'accounts',
        title: 'accounts',
        type : 'basic',
        icon : 'heroicons_outline:briefcase',
        link : '', //accounts component to be added
    },
    {
        id   : 'admin.insurances',
        title: 'insurances',
        type : 'basic',
        icon : 'heroicons_outline:pencil-square',
        link : '/admin/insurances'
    },
    {
        id   : 'admin.objects',
        title: 'objects',
        type : 'basic',
        icon : 'heroicons_outline:users',
        link : '/admin/people-objects'
    },
];



export const defaultNavigation: FuseNavigationItem[] = [
    {
        id   : 'dashboards.finance',
        title: 'Dashboard',
        type : 'basic',
        icon : 'heroicons_outline:banknotes',
        link : '/dashboard',
    },
    {
        id   : 'policies',
        title: 'Insurances',
        type : 'basic',
        icon : 'heroicons_outline:shopping-cart',
        link : '/policies',
    },
    {
        id   : 'people-objects',
        title: 'People and Objects',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/people-objects'
    },
    {
        id      : 'apps.help-center',
        title   : 'Help ',
        type    : 'basic',
        icon    : 'heroicons_outline:information-circle',
        link    : '/help',
    }
    
];
export const compactNavigation: FuseNavigationItem[] = [
    {
        id   : 'dashboards.finance',
        title: 'Dashboard',
        type : 'basic',
        icon : 'heroicons_outline:banknotes',
        link : '/dashboard',
    },
    {
        id   : 'policies',
        title: 'Insurances',
        type : 'basic',
        icon : 'heroicons_outline:shield-check',
        link : '/policies',
    },
    {
        id   : 'people-objects',
        title: 'People and Objects',
        type : 'basic',
        icon : 'heroicons_outline:users',
        link : '/people-objects'
    },
    {
        id      : 'apps.help-center',
        title   : 'Help ',
        type    : 'basic',
        icon    : 'heroicons_outline:information-circle',
        link    : '/help',
    }
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id   : 'dashboards.finance',
        title: 'Dashboard',
        type : 'basic',
        icon : 'heroicons_outline:banknotes',
        link : '/dashboard',
    },
    {
        id   : 'policies',
        title: 'Insurances',
        type : 'basic',
        icon : 'heroicons_outline:shopping-cart',
        link : '/policies',
    },
    {
        id   : 'people-objects',
        title: 'People and Objects',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/people-objects'
    },
    {
        id      : 'apps.help-center',
        title   : 'Help ',
        type    : 'basic',
        icon    : 'heroicons_outline:information-circle',
        link    : '/help',
    }
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id   : 'dashboards.finance',
        title: 'dashboard',
        type : 'basic',
        icon : 'heroicons_outline:banknotes',
        link : '/dashboard',
    },
    {
        id   : 'admin.tasks',
        title: 'tasks',
        type : 'basic',
        icon : 'heroicons_outline:clipboard-document-check',
        link : '/admin/tasks'
    },
    {
        id   : 'system',
        title: 'systemData',
        type : 'collapsable',
        icon : 'heroicons_outline:rectangle-group',
        children: systemNavigation,
        hidden: (item: FuseNavigationItem) => false
    },
    {
        id   : 'userData',
        title: 'userData',
        type : 'collapsable',
        icon : 'heroicons_outline:user-group',
        children: userDataNavigation,
        hidden: (item: FuseNavigationItem) => false
    },
    {
        id   : 'policies',
        title: 'insurances',
        type : 'basic',
        icon : 'heroicons_outline:shopping-cart',
        link : '/policies',
    },
    {
        id   : 'people-objects',
        title: 'peopleAndObjects',
        type : 'basic',
        icon : 'heroicons_outline:users',
        link : '/people-objects'
    },
    {
        id   : 'admin',
        title: 'admin',
        type : 'collapsable',
        icon : 'heroicons_outline:adjustments-horizontal',
        children: adminNavigation,
        hidden: (item: FuseNavigationItem) => false
    },

    {
        id      : 'apps.help-center',
        title   : 'help',
        type    : 'basic',
        icon    : 'heroicons_outline:information-circle',
        link    : '/help',
    },
    // {
    //     id      : 'insights',
    //     title   : 'insights',
    //     type    : 'basic',
    //     icon    : 'heroicons_outline:presentation-chart-line',
    //     link    : '/dashboard',
    // },
    // {
    //     id   : 'info',
    //     title: 'infoCenter',
    //     type : 'basic',
    //     icon : 'heroicons_outline:information-circle',
    //     link : '/help',
    // }
    // {
    //     id   : 'pages.settings',
    //     title: 'settings',
    //     type : 'basic',
    //     icon : 'heroicons_outline:cog-8-tooth',
    //     link : '/settings',
    // },
];