import { FormGroup } from '@angular/forms';
import { startCase } from 'lodash';
import { Decimal } from 'decimal.js';


/**
 * Recurssively get control names of a FormGroup
 * @param form FormGroup
 * @returns array
 */
export const getFormControlNames = (form: FormGroup, prefix: string = null) => {
    let controls = {};
    Object.keys(form.controls).forEach((field) => {
        const control = form.get(field);
        if (control instanceof FormGroup) {
            controls = {...controls, ...getFormControlNames(control, field)}
        } else {
            const key = (prefix ? prefix + '.' : '') + field;
            controls = {
                ...controls,
                [key]: {
                    key,
                    name: startCase(key.replace('.', ' ')),
                    control: control,
                },
            };
        }
    });
    return controls;
};

/**
 * Track by function for ngFor loops
 *
 * @param index
 * @param item
 */
export function trackByFn(index: number, item: any): any {
    return item.id ?? item.uid ?? index;
}

/**
 * Parses a value, otherwise returns the default value if parsing failed.
 * @param value 
 * @param defaultValue 
 * @returns 
 */
export function tryParseOrDefault(value: string, defaultValue: number = 0): Decimal {

    if (value === null || value === undefined || value === "") {
        return new Decimal(defaultValue);
    };

    const parsed = new Decimal(value);
    return parsed.isNaN() ? new Decimal(defaultValue) : parsed;
}