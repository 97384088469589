import { Injectable } from '@angular/core';
import { LanguageCode } from '../../../../models/common.model';
import {
    DocumentData,
    Firestore,
    addDoc,
    collection,
    collectionData,
    deleteDoc,
    doc,
    getDoc,
    query,
    setDoc,
} from '@angular/fire/firestore';
import {
    Observable,
    firstValueFrom,
    map,
    switchMap,
} from 'rxjs';
import { getStorage, ref } from 'firebase/storage';
import {
    StorageReference,
    getDownloadURL,
    uploadBytes,
} from '@angular/fire/storage';
import { InsurerEntity } from 'app/models/db/insurer.model';
import { TranslocoService } from '@ngneat/transloco';
import { Insurer as InsurerCore, fromInsurerEntity } from 'app/models/core/insurer.model';

@Injectable({
    providedIn: 'root',
})
export class InsurersService {
    private storage = getStorage();

    private insurersCollectionRef = collection(this._firestore, 'insurers');

    private readonly _collection = 'insurers';

    constructor(
        private _firestore: Firestore,
        private _translocoService: TranslocoService,
    ) {}


    /**
     * Create or update an insurer
     * IMPROVEMENT: Provide type.
     *
     * @param insurer
     * @returns
     */
    async save(insurer: any): Promise<any> {
        let data = { languages: {} };
    
        // Populate the 'data' object with languages data
        await new Promise(async (resolve) => {
            for (const lang of insurer.languages) {
                data.languages[lang.language] = {
                    logo: lang.logo,
                    name: lang.name,
                };
    
                const file = lang.file;
    
                if (file) {
                    const url = await this.uploadLogo(file);
                    data.languages[lang.language].logo = url;
                }
            }
            resolve(data);
        });
    
        // Check if it's an existing insurer or a new one
        if (insurer?.id != null && insurer.id.trim() !== '') {
            // Update an existing document
            const ref = doc(this._firestore, 'insurers', insurer.id);
            await setDoc(ref, data, { merge: true });
            return { id: insurer.id, ...data };
        } else {
            // Add a new document
            const docRef = await addDoc(collection(this._firestore, 'insurers'), data);
            return { id: docRef.id, ...data };
        }
    }

    /**
     * Upload insurer logo to storage
     *
     * @param file
     * @returns
     */
    async uploadLogo(file: File) {
        const target: string = '/insurers/' + file.name;
        const storageRef: StorageReference = ref(this.storage, target);
        let url: string;

        return new Promise(async (resolve) => {
            await uploadBytes(storageRef, file).then(async (snapshot) => {
                url = await getDownloadURL(snapshot.ref);
            });
            resolve(url);
        });
    }

    /**
     * Delete insurer by id
     *
     * @param id
     * @returns
     */
    async delete(id: string) {
        return await deleteDoc(doc(this._firestore, 'insurers', id));
    }

    getAll$(): Observable<InsurerEntity[]> {

        return collectionData(
            query(this.insurersCollectionRef),
            { idField: 'id' }
        ).pipe(
            map((snapshot) => snapshot.map((doc: DocumentData) => doc as InsurerEntity))
        );
    }

    getById(id: string): Promise<InsurerEntity> {
        return getDoc(
            doc(this._firestore, this._collection, id))
            .then((doc) => {
                return {
                    ...doc.data(),
                    id: doc.id,
                } as InsurerEntity;
            });
    }

    /**
     * Retrieves a localized insurer by its ID.
     * IMPROVEMENT: Error handling.
     * @param id - The ID of the insurer.
     * @returns A promise that resolves to the localized insurer.
     */
    async getLocalizedById(id: string): Promise<InsurerCore> {

        try {
            const selectedLanguage = await firstValueFrom(this._translocoService.langChanges$);
            const insurer = await this.getById(id);

            return fromInsurerEntity(insurer, (selectedLanguage.toLocaleUpperCase()) as LanguageCode);
        } catch (error) {
            // Handle the error here
            console.error(error);
            throw error;
        }
    }

    /**
     * Retrieves all localized insurers.
     * It uses the currently selected language from the TranslocoService.
     * @returns An Observable that emits an array of InsurerCore objects.
     */
    getAllLocalized$(): Observable<InsurerCore[]> {

        const getAndMapInsurerByLanguage = (language: LanguageCode): Observable<InsurerCore[]> => {
            return this.getAll$()
                .pipe(
                    map(entities => 
                        entities.map(entity =>
                                fromInsurerEntity(entity, language)
                            )
                    )
                );
        };

        return this._translocoService.langChanges$
            .pipe(
                switchMap(selectedLanguage =>
                    getAndMapInsurerByLanguage(
                        selectedLanguage.toLocaleUpperCase() as LanguageCode)
                )
            );
    }
}
