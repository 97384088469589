import Decimal from 'decimal.js';
import { v4 as uuidv4 } from 'uuid';

export function assertNever<T>(value: never): T {
    throw new Error(`Unexpected value: ${JSON.stringify(value)}`);
}

export function calculateMonthlyPremium(
    netCentsValue: number,
    periodicityMonthsValue: number,
    expirationDate: string
): number {
    const currentDate = new Date();
    const expirationDt = new Date(expirationDate);
    if (netCentsValue && periodicityMonthsValue && expirationDt >= currentDate) {
        const netCentsDecimal = new Decimal(netCentsValue);
        const calculatedMonthlyPremium = netCentsDecimal.dividedBy(100).dividedBy(periodicityMonthsValue);
        // Ensure two decimal places and check for finiteness
        return (calculatedMonthlyPremium.isFinite() ? calculatedMonthlyPremium.toDecimalPlaces(2) : new Decimal(0))
            .toDecimalPlaces(2)
            .toNumber();
    }
    return new Decimal(0).toDecimalPlaces(2).toNumber();
}

export function parseName(nameWithDate: string): string {
    return nameWithDate.replace(/\d{2}.\d{2}.\d{4}$/, '').trim();
}

export function generateUUID(): string {
    return uuidv4();
}

export function monthlyPremiumInCard(
    netCentsValue: number,
    periodicityMonthsValue: number
): number {

    const netCentsDecimal = new Decimal(netCentsValue);
    const calculatedMonthlyPremium = netCentsDecimal.dividedBy(100).dividedBy(periodicityMonthsValue);

    // Ensure two decimal places and check for finiteness
    return (calculatedMonthlyPremium.isFinite() ? calculatedMonthlyPremium.toDecimalPlaces(2) : new Decimal(0))
        .toDecimalPlaces(2)
        .toNumber();
}

export function generateTaskID() {
    // Create a 4-letter prefix
    const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let prefix = '';
    for (let i = 0; i < 4; i++) {
        prefix += letters.charAt(Math.floor(Math.random() * letters.length));
    }
    
    // Create a 4-number suffix
    const suffix = Math.floor(1000 + Math.random() * 9000); // This ensures a 4-digit number

    return `${prefix}-${suffix}`;
}
