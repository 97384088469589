import { LanguageCodes } from "../common.model";
import { InsurerLanguageEntity, InsurerEntity } from "../db/insurer.model";

// Alias, same structure.
export type InsurerLanguage = InsurerLanguageEntity;

export type Insurer = {
    id: string;
    languages: {
        [key in LanguageCodes]: InsurerLanguage;
    }
    displayName: string;
    displayLogo: string;
}


// Model transformers

export function fromInsurerEntity (insurerEntity: InsurerEntity,
                                languageCode: LanguageCodes): Insurer {
    return {
        id: insurerEntity.id,
        languages: insurerEntity.languages,
        displayName: insurerEntity.languages[languageCode].name,
        displayLogo: insurerEntity.languages[languageCode].logo
    }
}

export function toInsurerEntity (insurer: Insurer): InsurerEntity {
    return {
        id: insurer.id,
        languages: insurer.languages,
    }
};
