import { Injectable, OnDestroy } from '@angular/core';
import {
    Firestore,
    collectionData,
    collection,
    doc,
    query,
    where,
    addDoc,
    orderBy,
    updateDoc,
    deleteDoc,
} from '@angular/fire/firestore';
import { User } from '@angular/fire/auth';
import { Subject, Observable, from, forkJoin } from 'rxjs';
import { isEqual, cloneDeep } from 'lodash-es';
import { takeUntil } from 'rxjs/operators';

import {
    User as CurrentUser,
    CollectionType,
    CommentLog,
    ActionType,
    Status,
    Event,
    Log,
} from '../../models/db/event.model';
import { AuthService } from '../../core/auth/auth.service';

@Injectable({
    providedIn: 'root',
})
export class EventService implements OnDestroy {
    private collectionRef = collection(this.firestore, 'event-logs');
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    private authUser: User;

    constructor(private firestore: Firestore, private _authService: AuthService) {
        this._authService.user.pipe(takeUntil(this._unsubscribeAll)).subscribe((user) => {
            this.authUser = user;
        });
    }

    listenForEventNotifications(uid: string): Observable<Event[]> {
        const unreadEventsQuery = query(
            this.collectionRef,
            where('status', '==', 'published'),
            where('showOnNotification', '==', true),
            where('recordOwnerId', '==', uid),
            orderBy('createdAt', 'desc')
        );

        // Using takeUntil to ensure we can unsubscribe effectively to prevent memory leaks.
        return collectionData(unreadEventsQuery, { idField: 'id' }).pipe(
            takeUntil(this._unsubscribeAll)
        ) as Observable<Event[]>;
    }

    get user(): CurrentUser {
        return {
            uid: this.authUser.uid,
            displayName: this.authUser.displayName,
            email: this.authUser.email,
            photoURL: this.authUser?.photoURL || '',
        };
    }

    getEventsByRecordIdAndOwnerId(
        recordId: string,
        ownerId: string
    ): Observable<Event[]> {
        const eventsRef = collection(this.firestore, 'event-logs');
        const eventsQuery = query(
            eventsRef,
            where('recordId', '==', recordId),
            where('recordOwnerId', '==', ownerId),
            orderBy('createdAt', 'desc')
        );
        return collectionData(eventsQuery, { idField: 'id' }) as Observable<Event[]>;
    }

    getEventsByRecordIdOwnerIdAndStatus(
        recordId: string,
        ownerId: string,
        status: Status
    ): Observable<Event[]> {
        const eventsRef = collection(this.firestore, 'event-logs');
        const eventsQuery = query(
            eventsRef,
            where('recordId', '==', recordId),
            where('recordOwnerId', '==', ownerId),
            where('status', '==', status),
            orderBy('createdAt', 'desc')
        );
        return collectionData(eventsQuery, { idField: 'id' }) as Observable<Event[]>;
    }

    createEvent(
        recordId: string,
        recordOwnerId: string,
        collectionType: CollectionType,
        actionType: ActionType,
        name: string = '',
        showOnNotification: boolean = true
    ) {
        const event: Event = {
            name,
            recordId,
            actionType,
            collectionType,
            recordOwnerId,
            showOnNotification,
            status: 'published',
            read: false,
            logs: [],
            createdBy: this.user,
            createdAt: new Date(),
        };

        return from(addDoc(this.collectionRef, event));
    }

    // Function to create an event log for creation
    createCreateEvent(
        recordId: string,
        recordOwnerId: string,
        collectionType: CollectionType,
        name = ''
    ) {
        const event: Event = {
            name,
            recordId: recordId,
            status: 'published',
            recordOwnerId: recordOwnerId,
            collectionType,
            actionType: 'created',
            logs: [], // No logs needed for creation
            createdBy: this.user,
            createdAt: new Date(),
        };

        return from(addDoc(this.collectionRef, event));
    }

    // Function to create multiple event logs
    createMultipleEventLogs(events: Event[]): Observable<void[]> {
        const addPromises = events.map((event) => {
            return addDoc(this.collectionRef, event).then(() => {});
        });

        // Using from() to convert each Promise<void> to Observable<void>, and then using forkJoin to wait for all of them
        return forkJoin(addPromises.map((promise) => from(promise)));
    }

    // Function to create an event log for deletion
    createDeleteEvent(
        recordId: string,
        recordOwnerId: string,
        collectionType: CollectionType
    ) {
        const event: Event = {
            recordId: recordId,
            status: 'published',
            recordOwnerId: recordOwnerId,
            collectionType,
            actionType: 'deleted',
            logs: [], // No logs needed for deletion
            createdBy: this.user,
            createdAt: new Date(),
        };

        return from(addDoc(this.collectionRef, event));
    }

    // Function to create an event log for updates
    createUpdateEvent(
        previousObj: object,
        newObj: object,
        recordId: string,
        recordOwnerId: string,
        collectionType: CollectionType
    ) {
        const logs: Log[] = this.compareObjects(previousObj, newObj);
        if (logs.length > 0) {
            const event: Event = {
                recordId: recordId,
                status: 'published',
                recordOwnerId: recordOwnerId,
                collectionType,
                actionType: 'updated',
                logs: logs,
                createdBy: this.user,
                createdAt: new Date(),
            };

            return from(addDoc(this.collectionRef, event));
        } else {
            return from(Promise.resolve(null));
        }
    }

    private stringifyValue(value: any): string {
        // Avoids converting undefined to string directly
        if (typeof value === 'undefined' || value === null) {
            return '';
        }

        // If it's an object (and not null), stringify, but exclude 'path' during the process
        if (typeof value === 'object' && value !== null) {
            const replacer = (key, val) => (key === 'path' ? undefined : val);
            try {
                return JSON.stringify(value, replacer, 2);
            } catch (error) {
                console.error('Error stringifying value:', error);
                return '';
            }
        }

        // For non-object types, convert to string directly
        return value.toString();
    }

    private compareObjects(previousObj: object, newObj: object): Log[] {
        const logs: Log[] = [];
        const allKeys = new Set([
            ...Object.keys(previousObj || {}),
            ...Object.keys(newObj || {}),
        ]);

        allKeys.forEach((key) => {
            if (key === 'updatedAt') return;
            let prevValue = previousObj ? previousObj[key] : undefined;
            let newValue = newObj ? newObj[key] : undefined;

            // Special handling for 'documents' key
            if (key === 'documents') {
                // If newValue is an array (regardless of prevValue)
                if (Array.isArray(newValue)) {
                    // If prevValue is not an array, treat it as an empty array
                    const effectivePrevValue = Array.isArray(prevValue) ? prevValue : [];

                    // Compare each document in the new array against the previous array
                    newValue.forEach((newDoc, index) => {
                        const prevDoc = effectivePrevValue[index] || {};
                        const docFilename = newDoc.filename || 'New document';
                        // If prevDoc.filename is undefined or different from newDoc.filename, create a log
                        if (
                            !prevDoc.filename ||
                            !isEqual(prevDoc.filename, docFilename)
                        ) {
                            logs.push({
                                propertyName: `documents, ${index}`,
                                previousValue: prevDoc.filename || 'None',
                                newValue: docFilename,
                            });
                        }
                    });
                }
                // No need to compare if newValue is not an array
                return;
            }

            // Special handling for 'assignee' key
            if (
                key === 'assignee' &&
                typeof newValue === 'object' &&
                newValue !== null &&
                prevValue?.uid !== newValue?.uid
            ) {
                const prevDisplayName =
                    prevValue && prevValue?.displayName ? prevValue.displayName : 'N/A';
                const newDisplayName = newValue.displayName
                    ? newValue.displayName
                    : 'N/A';
                logs.push({
                    propertyName: 'assignee',
                    previousValue: prevDisplayName,
                    newValue: newDisplayName,
                });
                return;
            }

            if (
                typeof prevValue === 'object' &&
                typeof newValue === 'object' &&
                prevValue !== null &&
                newValue !== null
            ) {
                const nestedLogs = this.compareObjects(prevValue, newValue);
                if (nestedLogs.length > 0) {
                    logs.push(
                        ...nestedLogs.map((log) => ({
                            propertyName: `${key}, ${log.propertyName}`,
                            previousValue: log.previousValue,
                            newValue: log.newValue,
                        }))
                    );
                }
            } else if (!isEqual(prevValue, newValue)) {
                logs.push({
                    propertyName: key,
                    previousValue: this.stringifyValue(prevValue),
                    newValue: this.stringifyValue(newValue),
                });
            }
        });

        return logs;
    }

    // Function to create an event log specifically for comment actions
    createCommentEvent(
        recordId: string,
        recordOwnerId: string,
        collectionType: CollectionType,
        commentLog: CommentLog
    ) {
        const event: Event = {
            recordId,
            status: 'published',
            recordOwnerId,
            collectionType,
            actionType: 'updated',
            logs: [],
            commentLog,
            createdBy: this.user,
            createdAt: new Date(),
        };

        return from(addDoc(this.collectionRef, event));
    }

    updateEventStatus(eventIds: string[], newStatus: string): Observable<void[]> {
        const updatePromises = eventIds.map((eventId) => {
            const eventDocRef = doc(this.firestore, 'event-logs', eventId);
            return updateDoc(eventDocRef, { status: newStatus });
        });

        return forkJoin(updatePromises.map((promise) => from(promise)));
    }

    markEventsReadOrUnread(eventIds: string[], read: boolean): Observable<void[]> {
        const updatePromises = eventIds.map((eventId) => {
            const eventDocRef = doc(this.firestore, 'event-logs', eventId);
            return updateDoc(eventDocRef, { read });
        });

        return forkJoin(updatePromises.map((promise) => from(promise)));
    }

    removeEventsFromNotifications(eventIds: string[]): Observable<void[]> {
        const updatePromises = eventIds.map((eventId) => {
            const eventDocRef = doc(this.firestore, 'event-logs', eventId);
            return updateDoc(eventDocRef, { showOnNotification: false });
        });

        return forkJoin(updatePromises.map((promise) => from(promise)));
    }

    deleteEvent(eventId: string): Observable<void> {
        const eventDocRef = doc(this.firestore, 'event-logs', eventId);
        return from(deleteDoc(eventDocRef));
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }
}
