import {
    Component,
    Input,
    OnInit,
    Output,
    OnChanges,
    OnDestroy,
    EventEmitter,
    SimpleChanges,
    ChangeDetectorRef,
    ChangeDetectionStrategy,
} from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslocoService, TranslocoModule } from '@ngneat/transloco';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Subscription, Subject } from 'rxjs';
import { User } from '@angular/fire/auth';
import { takeUntil } from 'rxjs/operators';

import { DocumentUploadService } from '../../../modules/admin/apps/document-uploads/document-upload.service';
import { SelectAdminUserComponent } from '../select-admin-user/select-admin-user.component';
import { EventMessagePipe } from '../../pipes/event-message.pipe';
import { Event, LangCode } from '../../../models/db/event.model';
import { DeviceService } from '../../services/device.service';
import { AuthService } from '../../../core/auth/auth.service';
import { EventService } from '../../services/event.service';
import { generateUUID } from '../../../core/helpers/helper';
import { ToDatePipe } from '../../pipes/to-date.pipe';
import {
    User as ExtractionUser,
    CommentTypeMapping,
    DocumentUpload,
    StatusMapping,
    CommentType,
    Comment,
    Status,
    Urgency,
} from '../../../models/document-uploads.model';

@Component({
    selector: 'app-document-extraction-form',
    templateUrl: './document-extraction-form.component.html',
    styleUrls: ['./document-extraction-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        FormsModule,
        CommonModule,
        MatIconModule,
        MatTabsModule,
        MatInputModule,
        TranslocoModule,
        MatButtonModule,
        MatSelectModule,
        MatTooltipModule,
        MatFormFieldModule,
        MatProgressSpinnerModule,
        SelectAdminUserComponent,
        EventMessagePipe,
        ToDatePipe,
    ],
})
export class DocumentExtractionFormComponent implements OnInit, OnChanges, OnDestroy {
    @Input() docExtraction: DocumentUpload;
    @Input() isAdmin: boolean = true;
    @Output() closeDetails: EventEmitter<void> = new EventEmitter<void>();
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    private languageSubscription: Subscription;
    private user: User;
    events: Event[];
    tempStatus: Status;
    tempUrgency: Urgency;
    tempAssignee: ExtractionUser;
    newComment: Comment = {
        id: '',
        user: null,
        type: 'general',
        description: '',
        createdAt: new Date(),
        updatedAt: new Date(),
    };
    editStatus: boolean = false;
    editUrgency: boolean = false;
    editAssignee: boolean = false;
    allStatusOptions: Status[] = ['pending', 'in-progress', 'completed'];
    activityOptions: CommentType[] = ['general', 'insurer-contact', 'admin-update'];
    activityTypeMapping = CommentTypeMapping;
    statusMapping = StatusMapping;
    isLoading: boolean = false;
    isMobile: boolean = false;
    activityType: CommentType = 'general';
    currentLanguage: LangCode;

    constructor(
        private authService: AuthService,
        private eventService: EventService,
        private deviceService: DeviceService,
        private translocoService: TranslocoService,
        private changeDetectorRef: ChangeDetectorRef,
        private documentUploadService: DocumentUploadService
    ) {
        this.authService.user.pipe(takeUntil(this._unsubscribeAll)).subscribe((user) => {
            this.user = user;
        });

        this.languageSubscription = this.translocoService.langChanges$.subscribe(
            (lang: string) => {
                this.currentLanguage = lang.toUpperCase() as LangCode;
            }
        );
    }

    private fetchEvents(recordId: string, ownerId: string): void {
        this.isLoading = true;

        if (!this.isAdmin) {
            this.eventService
                .getEventsByRecordIdOwnerIdAndStatus(recordId, ownerId, 'published')
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe((events) => {
                    this.events = events.map((event) => ({
                        ...event,
                        language: this.currentLanguage,
                    }));
                    this.isLoading = false;
                    this.changeDetectorRef.detectChanges();
                });
        } else {
            this.eventService
                .getEventsByRecordIdAndOwnerId(recordId, ownerId)
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe((events) => {
                    this.events = events.map((event) => ({
                        ...event,
                        language: this.currentLanguage,
                    }));
                    this.isLoading = false;
                    this.changeDetectorRef.detectChanges();
                });
        }
    }

    ngOnInit() {
        this.isMobile = this.deviceService.isMobile();
        this.sortCommentsByLatest();
        if (this.docExtraction) {
            const recordId = this.docExtraction.id;
            const ownerId = this.docExtraction.user.uid;
            this.fetchEvents(recordId, ownerId);
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.docExtraction && changes.docExtraction.currentValue) {
            // If docExtraction has changed, fetch the events
            const recordId = this.docExtraction.id;
            const ownerId = this.docExtraction.user.uid;
            this.fetchEvents(recordId, ownerId);
        }
    }

    // Method to start editing the policy extraction's status
    startEditStatus() {
        this.tempStatus = this.docExtraction.status;
        this.editStatus = true;
    }

    // Method to cancel editing the policy extraction's status
    cancelEditStatus() {
        this.editStatus = false;
    }

    toggleEditUrgency() {
        this.editUrgency = !this.editUrgency;
        if (this.editUrgency) {
            this.tempUrgency = this.docExtraction?.urgency || 'low';
        }
    }

    toggleEditAssignee() {
        this.editAssignee = !this.editAssignee;
        if (this.editAssignee) {
            this.tempAssignee = this.docExtraction?.assignee;
        }
    }

    handleAssigneeChange(assignee: ExtractionUser) {
        this.tempAssignee = assignee;
    }

    sortCommentsByLatest(): void {
        if (this.docExtraction && this.docExtraction.comments) {
            this.docExtraction.comments.sort((a, b) => {
                // Convert timestamps to dates if they are not already
                const dateA = a.createdAt instanceof Date ? a.createdAt : a.createdAt.toDate();
                const dateB = b.createdAt instanceof Date ? b.createdAt : b.createdAt.toDate();

                return dateB.getTime() - dateA.getTime();
            });
        }
    }

    // Method to save the updated status of the policy extraction
    async updateStatus() {
        if (this.tempStatus !== this.docExtraction.status) {
            this.isLoading = true;
            try {
                await this.documentUploadService.updateDocUploadStatus(
                    this.docExtraction.id,
                    this.tempStatus
                );
                // Update the local state
                this.docExtraction.status = this.tempStatus;
            } catch (error) {
                console.error('Error updating policy extraction status:', error);
            } finally {
                // Reset the editing state and loading indicator
                this.editStatus = false;
                this.isLoading = false;
            }
        } else {
            // No change in status, so just reset the editing state
            this.editStatus = false;
        }
    }

    async updateUrgency() {
        if (this.tempUrgency !== this.docExtraction?.urgency) {
            this.isLoading = true;
            try {
                await this.documentUploadService.updateExtraction(this.docExtraction.id, {
                    urgency: this.tempUrgency,
                });
                // Update the local state
                this.docExtraction.urgency = this.tempUrgency;
            } catch (error) {
                console.error('Error updating extraction urgency:', error);
            } finally {
                // Reset the editing state and loading indicator
                this.editUrgency = false;
                this.isLoading = false;
            }
        } else {
            // No change in urgency, so just reset the editing state
            this.editUrgency = false;
        }
    }

    async updateAssignee() {
        if (this.tempAssignee?.uid !== this.docExtraction?.assignee?.uid) {
            this.isLoading = true;
            try {
                await this.documentUploadService.updateExtraction(this.docExtraction.id, {
                    assignee: this.tempAssignee,
                });
                // Update the local state
                this.docExtraction.assignee = this.tempAssignee;
            } catch (error) {
                console.error('Error updating extraction assignee:', error);
            } finally {
                // Reset the editing state and loading indicator
                this.editAssignee = false;
                this.isLoading = false;
            }
        } else {
            // No change in assignee, so just reset the editing state
            this.editAssignee = false;
        }
    }

    addComment() {
        if (this.newComment.description.trim()) {
            this.isLoading = true;
            this.newComment.id = generateUUID();
            this.newComment.type = !this.isAdmin ? 'user-message' : this.activityType;
            if (!this.isAdmin) {
                this.newComment.type = 'user-message';
            }
            this.newComment.user = {
                uid: this.user.uid,
                displayName: this.user.displayName,
                email: this.user.email,
                photoURL: this.user?.photoURL || '',
            };
            this.newComment.createdAt = new Date();
            this.newComment.updatedAt = new Date();
            this.documentUploadService
                .addComment(this.docExtraction.id, this.newComment)
                .then(() => {
                    this.docExtraction.comments.push(this.newComment);
                    this.isLoading = false;
                    this.resetNewActivityForm();
                    this.sortCommentsByLatest();
                })
                .catch((error) => {
                    console.error('Error adding activity:', error);
                    this.isLoading = false;
                });
        }
    }

    resetNewActivityForm() {
        this.newComment = {
            id: '',
            user: null,
            type: 'general',
            description: '',
            createdAt: new Date(),
            updatedAt: new Date(),
        };
    }

    onClickClose(): void {
        this.closeDetails.emit();
    }

    ngOnDestroy() {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
        this._unsubscribeAll = new Subject<void>();
        if (this.languageSubscription) {
            this.languageSubscription.unsubscribe();
        }
    }
}
