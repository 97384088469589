<div class="flex flex-col flex-auto w-full px-4">
  <div class="text-lg font-semibold" *ngIf="!isAddProduct">
    {{ 'product.newProductFor' | transloco }}
    <span class="text-gray-600">"{{ insuranceType }}"</span> >
    <span class="text-gray-600">"{{ insurerName }}"</span>
  </div>
  <div class="text-lg font-semibold" *ngIf="isAddProduct">
    {{ 'product.newProduct' | transloco }}
  </div>

  <div class="flex flex-auto flex-wrap pt-8" *ngIf="isAddProduct">
    <div *ngIf="insurerItems$ | async as insurerItems" class="w-full">
      <div class="flex flex-wrap">
        <div class="w-full sm:w-1/2 pr-2">
          <mat-label>{{ 'admin.docUploads.type' | transloco }}*</mat-label>
          <ng-select
              matInput
              appearance="outline"
              [items]="insuranceTypeCodes"
              [clearable]="false"
              [searchable]="false"
              [(ngModel)]="selectedType"
              (change)="onNgSelectTypeChange($event)"
          ></ng-select>
        </div>
        <div class="w-full sm:w-1/2 pl-2" *ngIf="selectedType && insurerItems.length > 0">
          <mat-label>{{ 'admin.docUploads.insurance' | transloco }}*</mat-label>
          <ng-select 
            #ngSelectInsurance
            matInput
            appearance="outline"
            [items]="insurerItems"
            bindValue="id"
            bindLabel="displayName"
            [markFirst]="false"
            [editableSearchTerm]="true"
            [(ngModel)]="selectedInsurer"
            (change)="onNgSelectInsuranceChange($event)"
        ></ng-select>
        </div>
      </div>
    </div>
  </div>

  
  <!-- Include input fields for language-independent name and language-specific names -->
  <div class="flex flex-col gap-4 pt-8 w-full">

    <div class="flex items-center mb-1">
      <div class="font-semibold w-16">LIN</div>
      <mat-form-field class="flex-grow no-hint">
        <input matInput [(ngModel)]="independentName" placeholder="Product name (Language Independent)" required
          class="w-full" />
      </mat-form-field>
    </div>

    <div class="flex items-center mb-1">
      <div class="font-semibold w-16">EN</div>
      <mat-form-field class="flex-grow no-hint">
        <input matInput [(ngModel)]="enName" placeholder="Product name in English" class="w-full" />
      </mat-form-field>
    </div>

    <div class="flex items-center mb-1">
      <div class="font-semibold w-16">DE</div>
      <mat-form-field class="flex-grow no-hint">
        <input matInput [(ngModel)]="deName" placeholder="Product name in German" class="w-full" />
      </mat-form-field>
    </div>

    <div class="flex items-center mb-1">
      <div class="font-semibold w-16">FR</div>
      <mat-form-field class="flex-grow no-hint">
        <input matInput [(ngModel)]="frName" placeholder="Product name in French" class="w-full" />
      </mat-form-field>
    </div>

    <div class="flex items-center mb-1">
      <div class="font-semibold w-16">IT</div>
      <mat-form-field class="flex-grow no-hint">
        <input matInput [(ngModel)]="itName" placeholder="Product name in Italian" class="w-full" />
      </mat-form-field>
    </div>

  </div>

  <mat-form-field appearance="outline" class="w-full pt-8">
    <mat-label>{{ 'product.prodPageLink' | transloco }}</mat-label>
    <input matInput [(ngModel)]="link" [placeholder]="'product.linkToSite' | transloco">
  </mat-form-field>


  <mat-form-field appearance="outline">
    <textarea matInput [(ngModel)]="description" minlength="0" maxlength="650" [placeholder]="'product.addPlaceholder' | transloco"></textarea>
  </mat-form-field>

  


  <div class="flex justify-end items-center gap-2 pt-4">
    <!-- <button type="button" class="px-6" mat-stroked-button [disabled]="processing" (click)="onCancel()">{{'insurers.cancel' | transloco}}</button> -->
    <button type="submit" class="px-8" mat-flat-button color="primary" [disabled]="(!independentName) || processing" (click)="onSave()">
      {{ !processing ? 'Save' : 'Saving...' }}
    </button>
  </div>
  
</div>