import { Component, EventEmitter, Input, Output, ChangeDetectorRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
    FormsModule,
    ReactiveFormsModule,
    FormGroup,
    FormBuilder,
    Validators,
} from '@angular/forms';
import {
    DateAdapter,
    MatRippleModule,
    MatNativeDateModule,
} from '@angular/material/core';
import { TranslocoService, TranslocoModule } from '@ngneat/transloco';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { Observable } from 'rxjs';

import { SelectPeopleObjectsComponent } from 'app/common/components/select-people-objects/select-people-objects.component';
import { SelectInsurerComponent } from 'app/common/components/select-insurer/select-insurer.component';
import { SwissLuxonDateAdapter } from 'app/common/services/swiss-luxondateadapter';
import { PeopleAndObjectsEntity } from 'app/models/db/peoples-and-objects.model';
import { ContractData } from 'app/models/document-uploads.model';
import { Insurer } from 'app/models/core/insurer.model';

@Component({
    selector: 'app-contract-data-form',
    templateUrl: './contract-data-form.component.html',
    styleUrls: ['./contract-data-form.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatNativeDateModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatRippleModule,
        MatSelectModule,
        TranslocoModule,
        MatButtonModule,
        MatInputModule,
        SelectPeopleObjectsComponent,
        SelectInsurerComponent,
    ],
    providers: [
        {
            provide: DateAdapter,
            useClass: SwissLuxonDateAdapter,
        },
    ],
})
export class ContractDataFormComponent {
    @Input() ownerId: string;
    @Input() contractData: ContractData;
    @Output() formSubmit: EventEmitter<ContractData> = new EventEmitter();
    selectedInsurer: Insurer;
    selectedObject: PeopleAndObjectsEntity;
    contractDataForm: FormGroup;
    activeAdhoc: string = '';

    constructor(
        private fb: FormBuilder,
        private swissLuxonDateAdapter: SwissLuxonDateAdapter,
        private changeDetectorRef: ChangeDetectorRef
    ) {
        this.createContractDataForm();
    }

    ngOnChanges(changes): void {
        if (changes.contractData && this.contractData) {
            this.selectedInsurer = this.contractData.insurer as Insurer;
            this.selectedObject = this.contractData.holder as PeopleAndObjectsEntity;
            this.contractDataForm.patchValue(this.contractData);
        }
    }

    setInsurerAdhoc(active: boolean) {
        this.activeAdhoc = !active ? '' : 'insurer';
        this.changeDetectorRef.markForCheck();
    }

    handleInsurerChange(insurer: Insurer) {
        this.selectedInsurer = insurer;
    }

    setObjectAdhoc(active: boolean) {
        this.activeAdhoc = !active ? '' : 'object';
        this.changeDetectorRef.markForCheck();
    }

    handleObjectChange(object: PeopleAndObjectsEntity) {
        this.selectedObject = object;
    }

    private createContractDataForm(): void {
        this.contractDataForm = this.fb.group({
            contractDate: ['', Validators.required],
            effectiveDate: ['', Validators.required],
            expirationDate: ['', Validators.required],
            paymentPeriodicity: ['', Validators.required],
        });
    }

    onSubmit(): void {
        if (this.contractDataForm.valid) {
            const { 
                contractDate, 
                effectiveDate, 
                expirationDate,
                paymentPeriodicity
            } = this.contractDataForm.value || {};
            const contractData = {
                paymentPeriodicity,
                holder: this.selectedObject,
                insurer: this.selectedInsurer,
                contractDate: this.swissLuxonDateAdapter.toIsoDateFormat(contractDate),
                effectiveDate: this.swissLuxonDateAdapter.toIsoDateFormat(effectiveDate),
                expirationDate: this.swissLuxonDateAdapter.toIsoDateFormat(expirationDate),
            } as ContractData;
            this.formSubmit.emit(contractData);
        }
    }
}
