import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    ViewEncapsulation,
    Component,
    ViewChild,
    OnDestroy,
    AfterViewInit,
    OnInit,
} from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { MatSelectModule, MatSelectChange } from '@angular/material/select';
import { MatPaginatorModule, MatPaginator } from '@angular/material/paginator';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { MatSortModule, Sort, MatSort } from '@angular/material/sort';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { Timestamp } from '@angular/fire/firestore';
import { fuseAnimations } from '@fuse/animations';
import { FormsModule } from '@angular/forms';
import cloneDeep from 'lodash-es/cloneDeep';
import { Router } from '@angular/router';
import {
    BehaviorSubject,
    combineLatest,
    Subscription,
    switchMap,
    takeUntil,
    Subject,
    map,
    of,
    Observable,
} from 'rxjs';

import { DocumentUploadService } from 'app/modules/admin/apps/document-uploads/document-upload.service';
import { InsuranceRetrievalService } from 'app/common/services/insurance-retrieval.service';
import {
    InsuranceRetrieval,
    StatusMapping,
} from 'app/models/db/insurance-retrieval.model';
import { DocumentUpload, User } from 'app/models/document-uploads.model';
import { ToDatePipe } from 'app/common/pipes/to-date.pipe';

type CombinedItem =
    | (DocumentUpload & { type: 'policy-extraction' })
    | (InsuranceRetrieval & { type: 'policy-request' });

type Filters = {
    urgency: string;
    user: string;
    type: string;
    status: string[];
    assignee: string;
};

type AdvancedFilters = {
    query: string;
    filters: Filters;
};

@Component({
    selector: 'app-tasks',
    templateUrl: './tasks.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: fuseAnimations,
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        MatSortModule,
        MatIconModule,
        MatTableModule,
        MatInputModule,
        MatSelectModule,
        TranslocoModule,
        MatButtonModule,
        MatTooltipModule,
        MatPaginatorModule,
        MatProgressBarModule,
        ToDatePipe,
    ],
    providers: [DatePipe],
})
export class TasksComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    advancedFilters: AdvancedFilters = {
        query: '',
        filters: {
            urgency: '',
            user: '',
            type: '',
            status: [],
            assignee: '',
        },
    };
    filters: {
        query$: BehaviorSubject<string>;
        sort$: BehaviorSubject<Sort>;
        filters$: BehaviorSubject<Filters>;
    } = {
        query$: new BehaviorSubject(''),
        sort$: new BehaviorSubject({ active: '', direction: '' }),
        filters$: new BehaviorSubject({
            urgency: '',
            user: '',
            type: '',
            status: [],
            assignee: '',
        }),
    };
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    private languageSubscription: Subscription;
    currentLanguage: string;
    isLoading: boolean = false;
    displayedColumns: string[] = [
        'urgency',
        'taskID',
        'dateTime',
        'user',
        'type',
        'detail',
        'attachment',
        'status',
        'assignedTo',
        'actions',
    ];
    fullDataSource: CombinedItem[] = [];
    dataSource: CombinedItem[] = [];
    // Data mapping
    docTypeMapping = {
        'policy-extraction': 'Extraction',
        'policy-request': 'Policy Request',
    };
    statusMapping = StatusMapping;
    urgencyColorMapping = {
        low: 'bg-green-400',
        medium: 'bg-orange-400',
        high: 'bg-red-400',
    };
    users: User[] = [];
    assignees: User[] = [];

    constructor(
        private router: Router,
        private datePipe: DatePipe,
        private translocoService: TranslocoService,
        private changeDetectorRef: ChangeDetectorRef,
        private documentUploadService: DocumentUploadService,
        private insuranceRetrievalService: InsuranceRetrievalService
    ) {
        this.languageSubscription = this.translocoService.langChanges$.subscribe(
            (lang: string) => {
                this.currentLanguage = lang.toUpperCase();
            }
        );
    }

    ngOnInit(): void {
        this.loadFiltersFromLocalStorage();
        combineLatest([this.filters.query$, this.filters.sort$, this.filters.filters$])
            .pipe(
                switchMap(([query, sort, filters]) => {
                    this.isLoading = true;
                    return combineLatest({
                        documents: this.documentUploadService.documents$,
                        policyRequests:
                            this.insuranceRetrievalService.insuranceRetrievals$,
                    }).pipe(
                        map(({ documents, policyRequests }) => {
                            let combinedList = this.combineAndTagDocuments(
                                documents,
                                policyRequests
                            );
                            this.extractUniqueUsersAndAssignees(combinedList);
                            combinedList = this.filterData(combinedList, filters, query);
                            combinedList = this.sortData(combinedList, sort);
                            return combinedList;
                        })
                    );
                }),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe(
                (data) => {
                    this.fullDataSource = data;
                    this.paginator.length = this.fullDataSource.length;
                    this.applyPagination();
                    this.isLoading = false;
                    this.changeDetectorRef.markForCheck();
                },
                (error) => {
                    console.error('[APP ERROR] Error: ', error);
                    this.isLoading = false;
                    this.changeDetectorRef.markForCheck();
                }
            );
    }

    ngAfterViewInit(): void {
        this.sort.sortChange.subscribe((sort) => {
            this.filters.sort$.next(sort);
        });

        this.paginator.page.subscribe(() => {
            this.updateDataSourceBasedOnPagination();
        });
    }

    private applyPagination(): void {
        this.paginator.pageIndex = 0;
        this.updateDataSourceBasedOnPagination();
    }

    private updateDataSourceBasedOnPagination(): void {
        const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
        const paginatedData = this.fullDataSource.slice(
            startIndex,
            startIndex + this.paginator.pageSize
        );

        this.dataSource = paginatedData;
        this.changeDetectorRef.markForCheck();
    }

    private combineAndTagDocuments(documents, policyRequests): CombinedItem[] {
        const taggedDocuments = documents.map((doc) => ({
            ...doc,
            type: 'policy-extraction',
        }));
        const taggedPolicyRequests = policyRequests.map((request) => ({
            ...request,
            type: 'policy-request',
        }));
        return [...taggedDocuments, ...taggedPolicyRequests];
    }

    private filterData(
        data: CombinedItem[],
        filters: Filters,
        query: string
    ): CombinedItem[] {
        let filteredData = data;

        if (filters.urgency) {
            filteredData = filteredData.filter(
                (item) => item?.urgency || 'low' === filters.urgency
            );
        }

        if (filters.user) {
            filteredData = filteredData.filter((item) => item.user.uid === filters.user);
        }

        if (filters.type) {
            filteredData = filteredData.filter((item) => item.type === filters.type);
        }

        if (filters.status.length) {
            filteredData = filteredData.filter((item) => filters.status.includes(item.status));
        }

        if (filters.assignee) {
            filteredData = filteredData.filter(
                (item) => item?.assignee?.uid === filters.assignee
            );
        }

        if (query) {
            const lowerCaseQuery = query.toLowerCase();
            filteredData = filteredData.filter((item) => {
                let formattedCreatedAt = '';
                if (item.createdAt instanceof Timestamp) {
                    formattedCreatedAt = this.datePipe.transform(
                        item.createdAt.toDate(),
                        'short'
                    );
                } else if (item.createdAt instanceof Date) {
                    formattedCreatedAt = this.datePipe.transform(item.createdAt, 'short');
                }
                formattedCreatedAt = formattedCreatedAt
                    ? formattedCreatedAt.toLowerCase()
                    : '';

                let insurerName = '';
                let filename = '';
                if (
                    item.type === 'policy-request' &&
                    item.insurer?.languages[this.currentLanguage]
                ) {
                    insurerName = item.insurer.languages[this.currentLanguage].name;
                } else if (item.type === 'policy-extraction') {
                    filename = item.filename;
                }

                const searchableStr = [
                    item.id,
                    formattedCreatedAt,
                    item.user.displayName,
                    item.user.email,
                    this.docTypeMapping[item.type].toLowerCase(),
                    insurerName,
                    filename,
                    this.statusMapping[item.status].toLowerCase(),
                    (
                        item.assignee?.displayName ||
                        item.assignee?.email ||
                        'unassigned'
                    ).toLowerCase(),
                ]
                    .filter(Boolean)
                    .join(' ')
                    .toLowerCase();

                return searchableStr.includes(lowerCaseQuery);
            });
        }

        return filteredData;
    }

    private sortData(data: CombinedItem[], sort: Sort): CombinedItem[] {
        if (!sort.active || sort.direction === '') {
            // If no explicit sorting is set, default to sorting by updatedAt in descending order
            sort = { active: 'updatedAt', direction: 'desc' }; // Set default sort if not specified
        }

        return data.sort((a, b) => {
            let valueA: any;
            let valueB: any;

            if (sort.active === 'updatedAt') {
                valueA = a.updatedAt instanceof Timestamp ? a.updatedAt.toDate() : a.updatedAt;
                valueB = b.updatedAt instanceof Timestamp ? b.updatedAt.toDate() : b.updatedAt;
            } else {
                valueA = this.getValue(a, sort.active);
                valueB = this.getValue(b, sort.active);
            }

            // Sort in ascending or descending order based on direction
            return (valueA < valueB ? -1 : 1) * (sort.direction === 'asc' ? 1 : -1);
        });
    }

    private extractUniqueUsersAndAssignees(dataSource: CombinedItem[]): void {
        const userSet = new Set<string>();
        const assigneeSet = new Set<string>();

        const users = [];
        const assignees = [];

        dataSource.forEach((item) => {
            if (item.user && !userSet.has(item.user.uid)) {
                users.push(item.user);
                userSet.add(item.user.uid);
            }

            // Some items might not have an assignee
            if (item.assignee && !assigneeSet.has(item.assignee.uid)) {
                assignees.push(item.assignee);
                assigneeSet.add(item.assignee.uid);
            }
        });

        this.users = users;
        this.assignees = assignees;
    }

    private getValue(item: CombinedItem, property: string): any {
        switch (property) {
            case 'urgency':
                return item?.urgency || 'low';
            case 'createdAt ':
                const createdAt = item.createdAt as Timestamp;
                return createdAt.toDate();
            case 'user':
                return item.user.displayName;
            case 'type':
                return this.docTypeMapping[item.type].toLowerCase();
            case 'detail':
                if (item.type === 'policy-request') {
                    const insurer = item.insurer.languages[this.currentLanguage];
                    return insurer.displayName;
                }
                return '';
            case 'attachment':
                if (item.type === 'policy-extraction') {
                    return item.filename;
                }
                return '';
            case 'assignedTo':
                return item?.assignee?.displayName || 'Unassigned';
            default:
                return item[property];
        }
    }

    onSearch(): void {
        this.filters.query$.next(this.advancedFilters.query);
    }

    updateFilter(): void {
        this.filters.filters$.next(this.advancedFilters.filters);
        this.saveFiltersToLocalStorage();
        this.changeDetectorRef.detectChanges();
    }

    clearFilters(): void {
        this.advancedFilters = {
            query: '',
            filters: {
                urgency: '',
                user: '',
                type: '',
                status: [],
                assignee: '',
            },
        };
        this.filters.filters$.next({ ...this.advancedFilters.filters });
        this.filters.query$.next('');
        this.filters.sort$.next({ active: '', direction: '' });
        this.saveFiltersToLocalStorage();
        this.changeDetectorRef.detectChanges();
    }

    private saveFiltersToLocalStorage(): void {
        let tasks: any = localStorage.getItem('tasks');
        tasks = cloneDeep({
            ...JSON.parse(tasks),
            filters: { ...this.advancedFilters.filters },
        });
        localStorage.setItem('tasks', JSON.stringify(tasks));
    }

    private loadFiltersFromLocalStorage(): void {
        let tasks: any = localStorage.getItem('tasks');
        if (tasks) {
            tasks = JSON.parse(tasks);
            if (tasks.filters) {
                this.advancedFilters.filters = cloneDeep(tasks.filters);
            }
        } else {
            this.advancedFilters.filters = cloneDeep({
                urgency: '',
                user: '',
                type: '',
                status: [],
                assignee: '',
            });
        }
        this.filters.filters$.next({ ...this.advancedFilters.filters });
    }

    navigateToItem(item: CombinedItem): void {
        const typeMap = {
            'policy-extraction': 'extraction',
            'policy-request': 'request',
        };
        const basePath = 'admin/tasks';
        const path = `${basePath}/${typeMap[item.type]}/${item.id}`;
        this.router.navigate([path]);
    }

    delete(doc: CombinedItem): void {
        if (doc.type === 'policy-extraction') {
            this.isLoading = true;
            this.documentUploadService
                .delete(doc.id)
                .then(() => {
                    this.isLoading = false;
                    this.changeDetectorRef.markForCheck();
                })
                .catch((e: Error) => {
                    console.log('Error', e.message);
                    this.isLoading = false;
                });
        } else {
            this.isLoading = true;
            this.insuranceRetrievalService.delete(doc.id, doc.user.uid).subscribe({
                next: () => {
                    this.isLoading = false;
                    this.changeDetectorRef.markForCheck();
                },
                error: (e: Error) => {
                    console.log('Error', e.message);
                    this.isLoading = false;
                },
            });
        }
    }

    translateStatus(status: string): Observable<string> {
        const sanitizedStatus = status.replace(/-/g, '');
        return this.translocoService.selectTranslate('tasks.statuses.' + sanitizedStatus.toLowerCase().replace(/\s+/g, ''));
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
        if (this.languageSubscription) {
            this.languageSubscription.unsubscribe();
        }
    }
}
