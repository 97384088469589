<ng-select 
    #ngSelectAdminUser
    class="w-full"
    matInput
    appearance="outline"
    [items]="adminUsers" 
    bindLabel="displayName" 
    bindValue="uid" 
    [markFirst]="false"
    [editableSearchTerm]="true"
    [formControl]="adminUserControl"
    [loading]="isLoading"
    [compareWith]="compareFn"
    (change)="onSelected($event)"
>
</ng-select>
