<div class="flex items-center mx-2 lg:mr-8">
    <div class="hidden lg:flex">
        <!-- Light version -->
        <div class="flex gap-1 dark:hidden items-center">
            <img
                class="w-18"
                src="assets/images/logo/Covo_Logo_Rot.png"
                alt="Logo image"
            />
        </div>
        <!--Dark version -->
        <img
            class="hidden dark:flex w-24"
            src="assets/images/logo/Covo_Logo_Rot.png"
            alt="Logo image">
    </div>
    <!-- Small version -->
    <img
        class="flex lg:hidden min-w-8 max-w-8"
        src="assets/images/logo/Covo_Icon_Rot.png"
        alt="Logo image"
    />
</div>
