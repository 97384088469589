import { Injectable, inject } from '@angular/core';
import {
    DocumentData,
    Firestore,
    collection,
    collectionData,
    deleteDoc,
    doc,
    getDoc,
    orderBy,
    query,
    serverTimestamp,
    setDoc,
    updateDoc,
    where,
    writeBatch,
} from '@angular/fire/firestore';
import { Observable, map, forkJoin, from, switchMap, of } from 'rxjs';
import { cloneDeep } from 'lodash';

import { Insurance as InsuranceCore } from 'app/models/core/insurance.model';
import { IInsurance, Insurance } from 'app/models/insurance.model';
import { EventService } from 'app/common/services/event.service';
import { InsuranceEntity } from 'app/models/db/insurance.model';
import { Product } from 'app/models/product.model';

@Injectable({
    providedIn: 'root',
})
export class InsuranceService {
    private _firestore = inject(Firestore);
    private _collection = 'insurances';

    private insurancesRef = collection(this._firestore, this._collection);
    constructor(private eventService: EventService) {}

    /**
     * @deprecated Use entity based.
     * @returns
     */
    getAll$(): Observable<Insurance[]> {
        return collectionData(query(this.insurancesRef, orderBy('createdAt')), {
            idField: 'id',
        }).pipe(map((snapshot) => snapshot.map((doc: DocumentData) => doc as Insurance)));
    }

    /**
     * Queries all the insurances ordered by capture status and creation date.
     * @deprecated Use entity based.
     */
    getAllOrdered$(): Observable<Insurance[]> {
        return collectionData(
            query(this.insurancesRef, orderBy('captureStatus'), orderBy('createdAt')),
            { idField: 'id' }
        ).pipe(map((snapshot) => snapshot.map((doc: DocumentData) => doc as Insurance)));
    }

    /**
     * @deprecated use entity based.
     * @param id
     * @returns
     */
    getById(id: string): Promise<Insurance> {
        return getDoc(doc(this._firestore, this._collection, id)).then((doc) => {
            return {
                ...doc.data(),
                id: doc.id,
            } as Insurance;
        });
    }

    /**
     * @deprecated Use entity based.
     * @param userId
     * @returns
     */
    getAllOfUser$(userId: string): Observable<Insurance[]> {
        return collectionData(
            query(
                this.insurancesRef,
                where('userId', '==', userId),
                orderBy('captureStatus'),
                orderBy('createdAt')
            ),
            { idField: 'id' }
        ).pipe(map((snapshot) => snapshot.map((doc: DocumentData) => doc as Insurance)));
    }

    /**
     * @deprecated Use entity base instead.
     * @param insurance
     */
    async create(insurance: IInsurance & { isNewInsurance?: boolean }): Promise<void> {
        const insurancesRef = collection(this._firestore, `insurances`);
        const { id, isNewInsurance, ...newInsurance } = insurance;
        const docRef = doc(insurancesRef, id);

        await setDoc(docRef, { ...newInsurance, createdAt: serverTimestamp() }).then(
            () => {
                this.eventService.createCreateEvent(
                    id,
                    newInsurance.userId,
                    'insurances'
                );
            }
        );
    }

    /**
     * Update insurance - Firestore
     * @deprecated Use entity base instead.
     *
     * @param docId
     * @param insurance
     */
    update(insurance: Insurance & { isNewInsurance?: boolean }): Promise<void> {
        const { id, isNewInsurance, product, ...forUpdateInsurance } = insurance;

        const insurancesRef = doc(this._firestore, `insurances/${id}`);

        return getDoc(insurancesRef).then((snapshot) => {
            if (!snapshot.exists()) {
                throw new Error('Document does not exist!');
            }

            const previousData = { ...snapshot.data() } as Insurance;
            const newData = {
                ...previousData,
                ...forUpdateInsurance,
                updatedAt: new Date(),
            };

            return updateDoc(insurancesRef, newData).then(() => {
                this.eventService.createUpdateEvent(
                    previousData,
                    newData,
                    id,
                    previousData.userId,
                    'insurances'
                );
            });
        });
    }

    /**
     * Deletes an insurance
     * @param documentId
     *
     * IMPROVEMENT. move to own service.
     */
    async delete(documentId: string): Promise<void> {
        return await deleteDoc(doc(this._firestore, this._collection, documentId));
    }

    getAllEntities$(): Observable<InsuranceEntity[]> {
        return collectionData(query(this.insurancesRef, orderBy('createdAt')), {
            idField: 'id',
        }).pipe(
            map((snapshot) => snapshot.map((doc: DocumentData) => this.adapter(doc)))
        );
    }

    getAllEntitiesOfUser$(userId: string): Observable<InsuranceEntity[]> {
        return collectionData(
            query(
                this.insurancesRef,
                where('userId', '==', userId),
                orderBy('captureStatus'),
                orderBy('createdAt')
            ),
            { idField: 'id' }
        ).pipe(map((snapshot) => snapshot.map((doc) => this.adapter(doc))));
    }

    getAllOrderedEntities$(): Observable<InsuranceEntity[]> {
        return collectionData(
            query(this.insurancesRef, orderBy('captureStatus'), orderBy('createdAt')),
            { idField: 'id' }
        ).pipe(map((snapshot) => snapshot.map((doc: DocumentData) => this.adapter(doc))));
    }

    getEntityById(id: string): Promise<InsuranceEntity> {
        return getDoc(doc(this._firestore, this._collection, id)).then((doc) => {
            return this.adapter({
                ...doc.data(),
                id: doc.id,
            });
        });
    }

    createEntity(
        insurance: InsuranceEntity & { isNewInsurance?: boolean }
    ): Promise<void> {
        const clonedInsurance = cloneDeep(insurance);
        const insurancesRef = collection(this._firestore, `insurances`);
        const { id, isNewInsurance, ...newInsurance } = clonedInsurance;
        const docRef = doc(insurancesRef, id);

        // Stripped down insurance for creation.
        const insuranceForCreation = {
            ...newInsurance,
            createdAt: serverTimestamp(),
            // Last updated is the created at.
            updatedAt: serverTimestamp(),
        };

        return setDoc(docRef, insuranceForCreation);
    }

    updateEntity(insurance: InsuranceCore): Promise<void> {
        const clonedInsurance = cloneDeep(insurance);

        // Strip out the updated at.
        const { id, updatedAt, ...forUpdateInsurance } = clonedInsurance;

        // Stripped down insurance for update.
        const insuranceForUpdate = {
            ...forUpdateInsurance,
            updatedAt: serverTimestamp(),
        };

        const insurancesRef = doc(this._firestore, `insurances/${id}`);

        // Improvement. Provide type.
        return updateDoc(insurancesRef, insuranceForUpdate);
    }

    // Adapt from old entity.
    // REFACTOR. TO be removed after the refactoring and new entity is used.
    private adapter(doc: DocumentData): InsuranceEntity {
        return {
            ...doc,
            holderId: doc?.holderId || doc?.holder.id,
            productId: doc?.productId || doc?.product.id,
            health: {
                ...doc.health,
                beneficiaryId: doc.health.beneficiary.id,
            },
        } as InsuranceEntity;
    }

    updateCaptureStatus(insuranceIds: string[], newStatus: string): Observable<void[]> {
        const updates$ = insuranceIds.map((insuranceId) => {
            const DocRef = doc(this._firestore, 'insurances', insuranceId);
            return from(getDoc(DocRef)).pipe(
                switchMap((docSnapshot) => {
                    if (!docSnapshot.exists()) {
                        throw new Error('Document does not exist!');
                    }

                    const prevData = { ...docSnapshot.data() } as Insurance;
                    const newData = {
                        ...prevData,
                        captureStatus: newStatus,
                        updatedAt: new Date(),
                    };

                    // Update the document with the new status
                    return from(updateDoc(DocRef, newData)).pipe(
                        // Once the document is updated, create the event log
                        switchMap(() => {
                            return this.eventService.createUpdateEvent(
                                prevData,
                                newData,
                                insuranceId,
                                prevData.userId,
                                'insurances'
                            );
                        }),
                        // Map to void to comply with Observable<void[]> return type, ignoring the event creation result
                        map(() => {})
                    );
                })
            );
        });

        // Combine all observables and wait for them to complete
        return forkJoin(updates$);
    }

    // get all insurances by documentId and expand productId
    getAllInsurancesByDocumentId$(documentId: string): Observable<Insurance[]> {
        return collectionData(
            query(
                this.insurancesRef,
                where('documentId', '==', documentId),
                orderBy('createdAt')
            ),
            { idField: 'id' }
        ).pipe(
            switchMap((insurances: Insurance[]) => {
                // Resolve each insurance's productId to a product object
                return forkJoin(
                    insurances.map((insurance) => this.expandProductId(insurance))
                );
            })
        );
    }

    private expandProductId(insurance: Insurance): Observable<Insurance> {
        if (!insurance.productId) {
            return of(insurance);
        }
        const productRef = doc(this._firestore, `products/${insurance.productId}`);
        return from(getDoc(productRef)).pipe(
            map((productSnapshot) => {
                const productData = productSnapshot.data() as Product;
                return { ...insurance, product: productData } as Insurance;
            })
        );
    }

    /**
     * Deletes multiple insurances by their document IDs.
     * @param documentIds Array of document IDs to delete.
     * @returns Observable that completes when the batch delete is successful.
     */
    deleteMultiple(documentIds: string[]) {
        const batch = writeBatch(this._firestore);

        documentIds.forEach((documentId) => {
            const docRef = doc(this._firestore, this._collection, documentId);
            batch.delete(docRef);
        });

        return from(batch.commit());
    }
}
