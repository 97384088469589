<ng-container [formGroup]="form">
    <div formGroupName="beneficiary">
        <div class="w-full">
            <mat-label>{{ 'admin.docUploads.beneficiary' | transloco }}*</mat-label>
            <ng-select
                #ngSelectField
                matInput
                appearance="outline"
                formControlName="id"
                [items]="people"
                bindValue="id"
                bindLabel="name"
                [markFirst]="false"
                [editableSearchTerm]="true"
                (change)="onNgSelectChange('beneficiary', $event)"
            />
        </div>
    </div>
</ng-container>


