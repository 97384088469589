import { Component, ElementRef, ViewChild, Input, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
@Component({
    selector: 'app-usersnap',
    templateUrl: './usersnap.component.html',
    styleUrls: ['./usersnap.component.scss'],
    standalone: true,
})
export class UsersnapComponent {
    // Replace with the global API key from your installation page
    @Input()
    globalApiKey: string = 'c4578bbe-41bf-4b8d-9d52-b91f01cdfb30';

    @ViewChild('script') script: ElementRef;
    window = null;

    private _projectKey = '3c565f3a-b964-4b9e-826b-fda6209b0505';

    constructor(@Inject(DOCUMENT) private document: Document) {
        this.window = this.document.defaultView;
    }

    convertToScript() {
        this.window.onUsersnapCXLoad = function (api) {
            api.init();
            // Replace with the Project API key from your widget configuration page
            api.show(this._projectKey);
        };
        var element = this.script.nativeElement;
        var script = document.createElement('script');
        script.defer = false;
        script.type = 'text/javascript';

        script.src = `https://widget.usersnap.com/global/load/${this.globalApiKey}?onload=onUsersnapCXLoad`;
        var parent = element.parentElement;
        parent.parentElement.replaceChild(script, parent);
    }

    ngAfterViewInit() {
        this.convertToScript();
    }
}
