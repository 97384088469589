import { Timestamp } from '@angular/fire/firestore';
import { PeopleAndObjects } from './people-objects';
import { Product } from './product.model';
import { PeopleAndObjectsEntity } from './db/peoples-and-objects.model';
import { Insurer as InsurerCore } from './core/insurer.model';

export const InsuranceTypeCode = {
    Auto: 'Auto',
    Home: 'Home',
    HealthBasic: 'Health - Basic',
    HealthAdditional: 'Health - Additional',
    Life: 'Life',
    Travel: 'Travel',
} as const;

export const InsuranceTypeCodes = [
    InsuranceTypeCode.Auto,
    InsuranceTypeCode.Home,
    InsuranceTypeCode.HealthBasic,
    InsuranceTypeCode.HealthAdditional,
    InsuranceTypeCode.Life,
    InsuranceTypeCode.Travel,
] as const;

export type InsuranceTypeCode =
    (typeof InsuranceTypeCode)[keyof typeof InsuranceTypeCode];

export const CaptureStatusCode = {
    Pending: 'PENDING',
    Processing: 'PROCESSING',
    Completed: 'COMPLETED',
} as const;

export type CaptureStatusCode =
    (typeof CaptureStatusCode)[keyof typeof CaptureStatusCode];

export const captureStatusCodes = [
    CaptureStatusCode.Pending,
    CaptureStatusCode.Processing,
    CaptureStatusCode.Completed,
] as const;

/**
 * @deprecated Use `PeopleAndObjects` instead.
 */
export type PolicyHolderApiType = {
    name: string;
    birthday: string;
    address: string;
};

export type InsuranceApiType = {
    id: string;
    insurerId: string;
    productId?: string;
    type: InsuranceTypeCode;
    referenceNumber: string;
    policyHolder: PolicyHolderApiType;
    premium: string;
    effectiveDate: string;
    expirationDate: string;
    policyDate?: string;
};

/**
 * @deprecated Use `PeopleAndObjects` instead.
 */
export interface IPolicyHolder {
    id?: string;
    name: string;
    birthday: string;
    contactNumber?: string;
    email?: string;
    relationship?: string;
    address?: {
        line1: string;
        line2: string;
        postalCode: string;
        city: string;
        country: string;
    };
}

/**
 * @deprecated Use `PeopleAndObjects` instead.
 */
export type Vehicle = {
    make: string;
    model: string;
    year: number;
    VIN: string;
};

export type Details = {
    information: string;
    notes?: string;
};

/**
 * @deprecated Use reference to PeopleAndObjects instead.
 */
export type Beneficiary = {
    id?: string;
    name: string;
    birthday: string;
    relationship?: string;
    contactNumber?: string;
    email?: string;
    address?: {
        line1: string;
        line2: string;
        postalCode: string;
        city: string;
        country: string;
    };
};
export const RelationshipTypeCode = {
    Father: 'Father',
    Mother: 'Mother',
    Sibling: 'Sibling',
    Grandparent: 'Grandparent',
    LifePartner: 'LifePartner',
    Child: 'Child',
    Others: "Others",
    Me: 'Me',
    Wife: 'Wife',
    Husband: 'Husband',
} as const;

export type RelationshipTypeCode =
    (typeof RelationshipTypeCode)[keyof typeof RelationshipTypeCode];

export const relationshipTypes = [
    RelationshipTypeCode.Father,
    RelationshipTypeCode.Mother,
    RelationshipTypeCode.Sibling,
    RelationshipTypeCode.Grandparent,
    RelationshipTypeCode.LifePartner,
    RelationshipTypeCode.Child,
    RelationshipTypeCode.Others,
] as const;

/**
 * @deprecated In favor of Entity Model.
 */
export type Health = {
    beneficiary: Beneficiary;
    insureeNumber?: string;
};

export type HealthBasic = {
    coverage: Coverage;
};

export enum PaymentPeriodicity {
    Monthly = 'MONTHLY',
    BiMonthly = 'BI-MONTHLY',
    Quarterly = 'QUARTERLY',
    SemiAnnual = '6 MONTHS',
    Annual = '12 MONTHS',
}

export type PremiumDiscounts = {
    associationDiscount?: { percentage: number; amountCents: number };
    familyDiscount?: { percentage: number; amountCents: number };
    loyaltyDiscount?: { percentage: number; amountCents: number };
    environmentalDiscount?: { percentage: number; amountCents: number };
    otherDiscounts?: { percentage: number; amountCents: number };
};

export type Premium = {
    periodicityNumMonths: number;
    grossCents?: number;
    discounts: PremiumDiscounts;
    netCents: number;
};

export type Coverage = {
    deductiblePercentage?: number;
    deductibleAbsoluteCents?: number;
    deductibleMaximalCents?: number;
    franchiseCents: number;
};

/**
 * @deprecated Use `InsuranceEntity` instead.
 */
export interface IInsurance {
    /**
     * @deprecated Use `id` instead.
     */
    uid?: string;
    id?: string;
    insurerId: string;
    productId?: string;
    userId?: string;
    type: InsuranceTypeCode | '';
    referenceNumber: string;
    holder: IPolicyHolder;
    policyDate: string;
    effectiveDate: string;
    expirationDate: string;
    policyPdf: string;
    captureStatus: CaptureStatusCode;
    vehicle?: Vehicle;
    details?: Details;
    health?: Health;
    healthBasic?: HealthBasic;
    documentId?: string;
    paymentPeriodicity?: PaymentPeriodicity;
    premium?: Premium;
    collectiveContract?: string;
    createdAt: Timestamp;
}

export interface InsurancePagination {
    length: number;
    size: number;
    page: number;
    lastPage: number;
    startIndex: number;
    endIndex: number;
}

/**
 * @deprecated Use `InsuranceCore` model instead.
 */
export class Insurance implements IInsurance {
    /**
     * @deprecated Use `id` instead.
     */
    uid?: string;
    id?: string;
    userId?: string;
    insurerId: string;
    productId?: string;
    documentId?: string;
    type: InsuranceTypeCode | '';
    referenceNumber: string;
    holder: IPolicyHolder;
    policyDate: string;
    effectiveDate: string;
    expirationDate: string;
    policyPdf: string;
    captureStatus: CaptureStatusCode;
    insurer: InsurerCore;
    vehicle?: Vehicle;
    health?: Health;
    healthBasic?: HealthBasic;
    details?: Details;
    paymentPeriodicity?: PaymentPeriodicity;
    premium?: Premium;
    collectiveContract?: string;
    product: Product;
    createdAt: Timestamp;

    get isCaptureStatusPending() {
        return this.captureStatus === CaptureStatusCode.Pending;
    }

    get isCaptureStatusProcessing() {
        return this.captureStatus === CaptureStatusCode.Processing;
    }

    get isCaptureStatusCompleted() {
        return this.captureStatus === CaptureStatusCode.Completed;
    }

    constructor(insurance: IInsurance, insurer: InsurerCore, product: Product) {
        this.id = insurance.id;
        this.insurerId = insurance.insurerId;
        this.productId = insurance.productId;
        this.userId = insurance.userId;
        this.type = insurance.type;
        this.referenceNumber = insurance.referenceNumber;
        this.holder = insurance.holder;
        this.policyDate = insurance?.policyDate;
        this.effectiveDate = insurance.effectiveDate;
        this.expirationDate = insurance.expirationDate;
        this.captureStatus = insurance.captureStatus;
        this.policyPdf = insurance.policyPdf;
        this.insurer = insurer;
        this.vehicle = insurance.vehicle;
        this.details = insurance.details;
        this.healthBasic = insurance.healthBasic;
        this.health = insurance.health;
        this.documentId = insurance.documentId;
        this.paymentPeriodicity = insurance.paymentPeriodicity;
        this.premium = insurance.premium;
        this.collectiveContract = insurance.collectiveContract;
        this.product = product;
        this.createdAt = insurance.createdAt;
    }

    static generatePendingInsurance(
        userId: string,
        pdfUrl: string
    ): IInsurance {
        return {
            id: '',
            insurerId: '',
            productId: '',
            userId: userId,
            type: '',
            referenceNumber: '',
            holder: {
                name: '',
                birthday: '',
                address: {
                    line1: '',
                    line2: '',
                    postalCode: '',
                    city: '',
                    country: '',
                },
            },
            policyDate: '',
            effectiveDate: '',
            expirationDate: '',
            policyPdf: pdfUrl,
            captureStatus: CaptureStatusCode.Pending,
            premium: {
                periodicityNumMonths: 12,
                grossCents: 0,
                discounts: {
                    associationDiscount: { percentage: 0, amountCents: 0 },
                    familyDiscount: { percentage: 0, amountCents: 0 },
                    loyaltyDiscount: { percentage: 0, amountCents: 0 },
                    environmentalDiscount: { percentage: 0, amountCents: 0 },
                    otherDiscounts: { percentage: 0, amountCents: 0 },
                },
                netCents: 0,
            },
            health: undefined,
            vehicle: undefined,
            details: undefined,
            paymentPeriodicity: undefined,
            collectiveContract: '',
            createdAt: undefined,
        };
    }

    static createHealthInsurance(insurance: IInsurance, insurer: InsurerCore,
        product: Product, holder: PeopleAndObjects, beneficiary: PeopleAndObjects,
        context: {
            nameFormatter: (person: PeopleAndObjects) => string,
        }): Insurance {

        // Guard. Only for Health and HealthBasic type.
        if (!(insurance.type === InsuranceTypeCode.HealthBasic || insurance.type === InsuranceTypeCode.HealthAdditional)) {
            throw new Error(`Insurance type ${insurance.type} is not Health or HealthBasic`);
        }

        const bareInsurance = new Insurance(insurance, insurer, product);

        // Modify bare insurance.
        bareInsurance.holder = {
            id: holder?.id,
            name: context.nameFormatter(holder),
            birthday: holder?.birthday,
            email: holder?.email,
            contactNumber: holder?.contactNumber,
            relationship: holder?.relationship,
            address: {
                line1: holder?.address.line1,
                line2: holder?.address.line2,
                postalCode: holder?.address.postalCode,
                city: holder?.address.city,
                country: holder?.address.country,
            },
        };

        bareInsurance.health = {
            beneficiary: {
                id: beneficiary?.id,
                name: context.nameFormatter(beneficiary),
                birthday: beneficiary?.birthday,
                relationship: beneficiary?.relationship,
                contactNumber: beneficiary?.contactNumber,
                address: beneficiary?.address,
                email: beneficiary?.email,
            },
            insureeNumber: bareInsurance.health?.insureeNumber,
        };
        return bareInsurance;
    }

    static createAutoInsurance(insurance: IInsurance, insurer: InsurerCore, product: Product, holder: PeopleAndObjects,
        context: {
            nameFormatter: (person: PeopleAndObjects) => string,
        }): Insurance {
            
        // Guard. Only for Auto type.
        if (!(insurance.type === InsuranceTypeCode.Auto)) {
            throw new Error(`Insurance type ${insurance.type} is not Auto`);
        }

        const bareInsurance = new Insurance(insurance, insurer, product);

        // Modify bare insurance.
        bareInsurance.holder = {
            name: context.nameFormatter(holder),
            birthday: holder?.birthday,
            id: holder?.id,
            address: {
                line1: holder?.address.line1,
                line2: holder?.address.line2,
                postalCode: holder?.address.postalCode,
                city: holder?.address.city,
                country: holder?.address.country,
            },
        };
        return bareInsurance;
    }
}
