import { TextFieldModule } from '@angular/cdk/text-field';
import { AsyncPipe, CommonModule, NgFor, NgIf } from '@angular/common';
import {
    Component,
    OnInit,
    OnChanges,
    OnDestroy,
    Optional,
    Inject,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import {
    FormsModule,
    ReactiveFormsModule,
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { DateAdapter, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
    MAT_DIALOG_DATA,
    MatDialog,
    MatDialogModule,
    MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { PeopleAndObjects } from 'app/models/people-objects';
import { PeopleObjectsService } from '../people-objects.service';
import { SwissLuxonDateAdapter } from 'app/common/services/swiss-luxondateadapter';
import { MatSelectModule } from '@angular/material/select';
import { cloneDeep } from 'lodash-es';
import { LoadingOverlayComponent } from 'app/common/components/loading-overlay/loading-overlay.component';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { RelationshipTypeCode } from 'app/models/insurance.model';
import { NgSelectModule } from '@ng-select/ng-select';

@Component({
    selector: 'app-object-form',
    templateUrl: './object-form.component.html',
    styleUrls: ['./object-form.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        MatButtonModule,
        MatIconModule,
        FormsModule,
        TextFieldModule,
        NgSelectModule,
        NgFor,
        MatDialogModule,
        AsyncPipe,
        MatInputModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatSelectModule,
        TranslocoModule,
    ],
    providers: [
        // Material Date Adapter
        {
            provide: DateAdapter,
            useClass: SwissLuxonDateAdapter,
        },
    ],
})
export class ObjectFormComponent implements OnInit, OnChanges, OnDestroy {
    @Input() method: 'create' | 'update' = 'create';
    @Input() inputData: PeopleAndObjects;
    @Output() closeForm = new EventEmitter<any>();
    form: UntypedFormGroup;
    processing: boolean = false;
    relationshipItems: RelationshipTypeCode[] = [
        'Father',
        'Mother',
        'Sibling',
        'Grandparent',
        'LifePartner',
        'Child',
        'Me',
        'Wife',
        'Husband',
        'Others',
    ];
    translatedRelationshipItems: string[];

    constructor(
        @Optional()
        private dialogRef: MatDialogRef<ObjectFormComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: PeopleAndObjects,
        private _peopleAndObjectsService: PeopleObjectsService,
        private _formBuilder: UntypedFormBuilder,
        private _matDialog: MatDialog,
        private translocoService: TranslocoService
    ) {}

    ngOnInit(): void {
        const data = this.data || this.inputData;
        const { address } = data;

        this.form = this._formBuilder.group({
            ownerId: [data.ownerId, [Validators.required]],
            firstName: [data.firstName, [Validators.required]],
            lastName: [data.lastName, [Validators.required]],
            birthday: [data.birthday, [Validators.required]],
            email: [data.email],
            relationship: [data?.relationship ? data.relationship : 'Others'],
            contactNumber: [data?.contactNumber ?? ''],
            address: this._formBuilder.group({
                line1: [address?.line1 ?? ''],
                line2: [address?.line2 ?? ''],
                postalCode: [address?.postalCode ?? ''],
                city: [address?.city ?? ''],
            }),
            type: [data.type, [Validators.required]],
        });

        // Preprocess relationship items for translation
        this.translatedRelationshipItems = this.relationshipItems.map(
            (item) => 'relationship.' + item.toLowerCase()
        );
    }

    ngOnChanges(): void {}

    ngOnDestroy(): void {
        this.closeForm.emit();
    }

    save() {
        const loadingOverlayData = {
            isLoading: true,
            loadingText: 'Saving Item',
            successText: 'Item Saved',
        };
        const loadingOverlayRef = this._matDialog.open(LoadingOverlayComponent, {
            data: loadingOverlayData,
            disableClose: true,
        });
        const defaultCountry = 'Switzerland';
        const dataToSave: PeopleAndObjects = cloneDeep(this.form.value);
        if (this.method === 'update') {
            dataToSave.id = this.inputData.id;
        }
        const dataToSaveWithUpdatedAddress: PeopleAndObjects = {
            ...dataToSave,
            address: {
                ...dataToSave.address,
                country: defaultCountry,
            },
        };

        if (this.method === 'create') {
            this._peopleAndObjectsService
                .save(dataToSaveWithUpdatedAddress)
                .then((data) => {
                    loadingOverlayRef.componentInstance.data = {
                        ...loadingOverlayData,
                        isLoading: false,
                    };
                    if (this.dialogRef) {
                        this.dialogRef.close({
                            id: data.id,
                            ...dataToSaveWithUpdatedAddress,
                        });
                    }
                    this.closeForm.emit({
                        id: data.id,
                        ...dataToSaveWithUpdatedAddress,
                    });
                });
        } else {
            this._peopleAndObjectsService
                .update(dataToSaveWithUpdatedAddress)
                .then(() => {
                    loadingOverlayRef.componentInstance.data = {
                        ...loadingOverlayData,
                        isLoading: false,
                    };
                    if (this.dialogRef) {
                        this.dialogRef.close();
                    }
                    this.closeForm.emit();
                });
        }
    }

    getMethodTranslation(): string {
        const key = this.method === 'create' ? 'admin.docUploads.new' : '';
        return this.translocoService.translate(key);
    }

    translateButton(): string {
        const key =
            this.method === 'create'
                ? 'admin.docUploads.create'
                : 'admin.docUploads.save';
        return this.translocoService.translate(key);
    }

    cancel(): void {
        this.closeForm.emit();
    }
}
