import { DocumentReference, Timestamp } from '@angular/fire/firestore';
import { InsurerEntity } from './insurer.model';

export const CommentTypeMapping = {
    general: 'general',
    'user-message': 'userMessage',
    'insurer-contact': 'insurerContact',
    'admin-update': 'adminUpdate',
} as const;

export const StatusMapping = {
    pending: 'Pending',
    'in-progress': 'In progress',
    completed: 'Completed',
    closed: 'Closed',
} as const;

export type Status = 'pending' | 'in-progress' | 'completed' | 'closed';
export type CommentType = 'general' | 'user-message' | 'insurer-contact' | 'admin-update';
export type Urgency = 'low' | 'medium' | 'high';

export type User = {
    uid: string;
    displayName: string;
    email: string;
    photoURL: string;
};

export type Document = {
    path: string;
    filename: string;
};

export type Comment = {
    id: string;
    user: User | null;
    type: CommentType;
    description: string;
    createdAt: Timestamp | Date;
    updatedAt: Timestamp | Date;
};

type BaseInsuranceRetrieval = {
    taskId: string;
    user: User;
    status: Status;
    comments: Comment[];
    urgency: Urgency;
    assignee: User | null;
    documents: Document[];
    createdAt: Timestamp | Date;
    updatedAt: Timestamp | Date;
};

export interface InsuranceRetrievalEntity extends BaseInsuranceRetrieval {
    id?: string;
    insurer: DocumentReference;
}

export interface InsuranceRetrieval extends BaseInsuranceRetrieval {
    id: string;
    insurer: InsurerEntity;
}
