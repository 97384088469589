import { Injectable } from '@angular/core';
import {
    Firestore,
    addDoc,
    collection,
    collectionData,
    deleteDoc,
    doc,
    getDocs,
    query,
    serverTimestamp,
    setDoc,
    where,
    DocumentReference,
    FieldValue,
    Timestamp,
    DocumentSnapshot,
    getDoc,
    DocumentData
} from '@angular/fire/firestore';
import { Observable, from, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Product } from '../../models/product.model';
import { chunk } from 'lodash-es';

@Injectable({
    providedIn: 'root',
})
export class ProductService {
    private productsRef = collection(this.firestore, 'products');
    private insurersRef = collection(this.firestore, 'insurers');

    constructor(private firestore: Firestore) {}

    async createProduct(data: Partial<Product>): Promise<Product> {
        const { insurerId, ...otherData } = data;
        const insurerRef = doc(this.firestore, `insurers/${insurerId}`);
        const productData = {
            ...otherData,
            insurer: insurerRef,
            createdAt: serverTimestamp(),
            updatedAt: serverTimestamp(),
        };

        try {
            const docRef = await addDoc(this.productsRef, productData);
            const newProduct = {
                id: docRef.id,
                ...data,
            } as Product;
            return newProduct;
        } catch (error) {
            console.error('Error creating new product: ', error);
            throw new Error('Error creating new product');
        }
    }

    updateProduct(productId: string, data: Partial<Product>): Observable<void> {
        const productSnap = doc(this.productsRef, productId);

        let updateData: { updatedAt: FieldValue; [key: string]: any } = {
            ...data,
            updatedAt: serverTimestamp(),
        };

        if (data.insurerId) {
            const insurerRef = doc(
                this.firestore,
                `insurers/${data.insurerId}`
            );
            updateData = { ...updateData, insurer: insurerRef };
        }

        return from(setDoc(productSnap, updateData as any, { merge: true }));
    }

    getAll(insuranceType?: string, insurerId?: string): Observable<Product[]> {
        let productsQuery = query(this.productsRef);

        if (insuranceType) {
            productsQuery = query(
                productsQuery,
                where('insuranceType', '==', insuranceType)
            );
        }

        if (insurerId) {
            const insurerRef = doc(this.insurersRef, insurerId);
            productsQuery = query(
                productsQuery,
                where('insurer', '==', insurerRef)
            );
        }

        // Assuming that the Product type has an insurerId property
        return collectionData(productsQuery, { idField: 'id' }).pipe(
            map(
                (
                    products: any[] // Cast to any[] if necessary
                ) =>
                    products.map((product) => {
                        const { insurer, ...otherProps } = product;

                        return {
                            ...otherProps,
                            insurerId: insurer.id,
                        } as Product;
                    })
            )
        );
    }

    delete(productId: string): Observable<void> {
        const productRef = doc(this.productsRef, productId);
        return from(deleteDoc(productRef));
    }

    getProductById(productId: string): Observable<string | null> {
        if (!productId) {
            return of(null); // Return null if productId is empty or undefined
        }
    
        const productDocRef = doc(this.productsRef, productId);
    
        return from(getDoc(productDocRef)).pipe(
            switchMap((productDoc: DocumentSnapshot<DocumentData>) => {
                if (productDoc.exists()) {
                    const product = productDoc.data();
                    const { name } = product;
    
                    return of(name || ''); // Return an empty string if name is undefined
                } else {
                    return of(null); // Return null when the product is not found
                }
            }),
            catchError((error) => {
                console.error('Error fetching product:', error);
                return of(null);
            })
        );
        
    }

    /**
     * Retrieves products by their IDs.
     * @param productIds - An array of product IDs.
     * @returns A promise that resolves to an array of products.
     */
    getProductByIds(productIds: string[]): Promise<Product[]> {

        // Firebase limit 10 id's per query.
        const fetchedProductsList = chunk(productIds, 10)
            .map((productIdsChunk) => {


            const productsQuery = query(
                this.productsRef,
                where('__name__', 'in', productIdsChunk)
            );

            return getDocs(productsQuery).then((querySnapshot) => {
                const products: Product[] = querySnapshot.docs.map((doc) => {
                    const docId = doc.id;
                    const product = { id: docId, ...doc.data() } as Product;
                    return product;
                });
                return products;
            })
            .catch<Product[]>(error => {

                console.error('[debug] Error fetching products:', error);
                return [];
            });
        });

        return Promise.all(fetchedProductsList)
            .then((results) => results.flat())
            .catch<Product[]>(error => {

                console.error('[debug] Error fetching products:', error);
                return [];
            });
    }
}
