import { Injectable, inject } from '@angular/core';
import { Firestore, doc, setDoc, deleteDoc, getDoc, collection} from '@angular/fire/firestore';
import { Observable, from, tap } from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class UserPreferencesService {
    private _firestore = inject(Firestore);
    private _collection = 'user-preferences';


    constructor(firestore: Firestore) {
        this._firestore = firestore;
    }

    createUserPreferences(userId: string, props: string, preferences: any): Promise<void> {
        const userDocRef = collection(this._firestore, this._collection);
        const docRef = doc(userDocRef, userId);
        
        const preferencesObject = {
            [props]: preferences
        };
    
        return setDoc(docRef, preferencesObject).then(() => {
            console.log('User preferences created successfully for userId:', userId);
        }).catch(error => {
            console.error('Error creating user preferences:', error);
            throw error;
        });
    }
    
    

    getUserPreferences(userId: string): Observable<any> {
        const docRef = doc(this._firestore, this._collection, userId);
        return from(getDoc(docRef).then((snapshot) => {
            if (snapshot.exists()) {
                console.log('User preferences retrieved successfully for userId:', snapshot.data());
                return snapshot.data();
            } else {
                console.log('No preferences found for userId:', userId);
                return null;
            }
        }).catch(error => {
            console.error('Error retrieving user preferences:', error);
            throw error;
        })).pipe(
            tap(data => console.log('Returned data:', data))
        );
    }
    
    
    
    
    updateUserPreferences(userId: string, props: string, preferences: any): Promise<void> {
        const userDocRef = collection(this._firestore, this._collection);
        const docRef = doc(userDocRef, userId);       
        const preferencesObject = {
            [props]: preferences
        };   
        return setDoc(docRef, preferencesObject).then(() => {
            console.log('User preferences created successfully for userId:', userId);
        }).catch(error => {
            console.error('Error creating user preferences:', error);
            throw error;
        });
    }
    
    
    
    
    deleteUserPreferences(userId: string): Promise<void> {
        const userDocRef = doc(this._firestore,`${this._collection}`, userId);
        return deleteDoc(userDocRef).then(() => {
            console.log('User preferences deleted successfully for userId:', userId);
        }).catch(error => {
            console.error('Error deleting user preferences:', error);
            throw error;
        });
    }
    
    
    
}
