<!-- <div class="flex flex-col flex-auto md:w-160 md:min-w-160 -m-6"> -->
<div
    *ngIf="(data$ | async) as data; else loadingSpinner"
    [class]="getClass()"
>
    <div 
        [ngClass]="{ 'border-b': !isModeDialog && !isInAdminTask }"
        *ngIf="!toggleInsurerForm && !toggleProductForm && !togglePeopleAndObjectForm; else showBlock" 
    >
        <!-- Selected product form -->
        <form
            class="flex flex-col w-full"
            [ngClass]="{'-ml-4': (isInAdmin && insuranceForm.get('type').value)}"
            [formGroup]="insuranceForm"
        >
            <div
                class="flex flex-col sm:flex-row"
                [ngClass]="{ 'p-8': !isModeDialog && !isInAdminTask }"
            >
                <!-- Product images and status -->
                <div
                    class="flex flex-col items-center sm:items-start mb-8 sm:mb-0"
                >
                    <div class="flex flex-col items-center">
                        <div
                            *ngIf="!isInAdmin"
                            class="w-32 h-44 border rounded overflow-hidden p-2 flex items-center"
                        >
                            <ng-container
                                *ngIf="selectedInsurerLogo(data.insurers); else noImage"
                            >
                                <img
                                    class="w-32 object-cover"
                                    [src]="selectedInsurerLogo(data.insurers)"
                                />
                            </ng-container>
                            <ng-template #noImage>
                                <span
                                    class="flex items-center min-h-20 text-lg font-semibold"
                                    >NO IMAGE</span
                                >
                            </ng-template>
                        </div>
                        <div
                            class="flex items-center mt-2 whitespace-nowrap"
                            *ngIf="insuranceForm.get('images')?.value.length"
                        >
                            <button mat-icon-button (click)="(null)">
                                <mat-icon
                                    class="icon-size-5"
                                    [svgIcon]="'heroicons_mini:arrow-long-left'"
                                ></mat-icon>
                            </button>
                            <span class="font-sm mx-2">
                                {{
                                    insuranceForm.get("currentImageIndex")
                                        ?.value + 1
                                }}
                                of
                                {{ insuranceForm.get("images")?.value.length }}
                            </span>
                            <button mat-icon-button (click)="(null)">
                                <mat-icon
                                    class="icon-size-5"
                                    [svgIcon]="
                                        'heroicons_mini:arrow-long-right'
                                    "
                                ></mat-icon>
                            </button>
                        </div>
                    </div>
                </div>

                <div class="flex flex-auto flex-wrap">
                    <div class="flex flex-col w-full sm:pl-8 mb-4">
                        <div class="grid grid-cols-1 sm:grid-cols-2 gap-3 mb-3">

                            <div class="col-span-1">
                                <mat-label>{{ 'admin.docUploads.type' | transloco }}*</mat-label>
                                <ng-select
                                    matInput
                                    appearance="outline"
                                    formControlName="type"
                                    [items]="insuranceTypeCodes"
                                    [clearable]="false"
                                    [searchable]="false"
                                >
                                </ng-select>
                            </div>
                            <div class="col-span-1" *ngIf="insuranceForm.get('type').value">
                                <mat-label>{{ 'admin.docUploads.insurance' | transloco }}*</mat-label>
                                <ng-select
                                    #ngSelectInsurance
                                    matInput
                                    appearance="outline"
                                    formControlName="insurerId"
                                    [items]="data.insurers"
                                    bindValue="id"
                                    bindLabel="displayName"
                                    [markFirst]="false"
                                    [editableSearchTerm]="true"
                                    (change)="onNgSelectInsuranceChange($event)"
                                />
                            </div>

                            <div class="col-span-2" 
                                *ngIf="insuranceForm.get('type').value && insuranceForm.get('insurerId').value"
                            >
                                <mat-label>{{ 'admin.docUploads.product' | transloco }}*</mat-label>
                                <select-product
                                    [insuranceType]="insuranceForm.get('type').value"
                                    [insurerId]="insuranceForm.get('insurerId').value"
                                    [insurerName]="selectedInsurerName"
                                    [productId]="insuranceForm.get('productId').value"
                                    (selectedProduct)="onProductSelect($event)"
                                ></select-product>
                            </div>


                        </div>

                        <div class="grid grid-cols-1 sm:grid-cols-2 gap-3" *ngIf="insuranceForm.get('type').value.length > 0">

                            <div class="col-span-2" formGroupName="holder">
                                <mat-label>{{ 'admin.docUploads.insuranceHolder' | transloco }}*</mat-label>
                                <ng-select
                                    #ngSelectInsuranceHolder
                                    matInput
                                    appearance="outline"
                                    formControlName="id"
                                    [items]="data.people"
                                    bindValue="id"
                                    bindLabel="name"
                                    [markFirst]="false"
                                    [editableSearchTerm]="true"
                                    (change)="onNgSelectChange('holder', $event)"
                                />
                            </div>

                            <mat-form-field class="col-span-1">
                                <mat-label>{{ 'admin.docUploads.policyNumber' | transloco }}</mat-label>
                                <input
                                    matInput
                                    [formControlName]="'referenceNumber'"
                                />
                            </mat-form-field>

                            <mat-form-field class="col-span-1">
                                <mat-label>{{ 'admin.docUploads.payPeriodicity' | transloco }}</mat-label>
                                <mat-select formControlName="paymentPeriodicity">
                                  <mat-option value="MONTHLY">Monthly</mat-option>
                                  <mat-option value="BI-MONTHLY">Bi-Monthly</mat-option>
                                  <mat-option value="QUARTERLY">Quarterly</mat-option>
                                  <mat-option value="6 MONTHS">Semi-Annual</mat-option>
                                  <mat-option value="12 MONTHS">Annual</mat-option>
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field class="col-span-1">
                                <mat-label>{{ 'admin.docUploads.policyDate' | transloco }}</mat-label>
                                <input
                                    matInput
                                    [matDatepicker]="policyDatePicker"
                                    formControlName="policyDate"
                                />
                                <mat-datepicker-toggle
                                    matIconSuffix
                                    [for]="policyDatePicker"
                                ></mat-datepicker-toggle>
                                <mat-datepicker
                                    #policyDatePicker
                                ></mat-datepicker>
                            </mat-form-field>
                              
                            <mat-form-field class="col-span-1">
                                <mat-label>{{ 'admin.docUploads.effectiveDate' | transloco }}</mat-label>
                                <input
                                    matInput
                                    [matDatepicker]="effectiveDatePicker"
                                    formControlName="effectiveDate"
                                />
                                <mat-datepicker-toggle
                                    matIconSuffix
                                    [for]="effectiveDatePicker"
                                ></mat-datepicker-toggle>
                                <mat-datepicker
                                    #effectiveDatePicker
                                ></mat-datepicker>
                            </mat-form-field>

                            <mat-form-field class="col-span-1">
                                <mat-label>{{ 'admin.docUploads.expirationDate' | transloco }}</mat-label>
                                <input
                                    matInput
                                    [matDatepicker]="expirationDatePicker"
                                    formControlName="expirationDate"
                                />
                                <mat-datepicker-toggle
                                    matIconSuffix
                                    [for]="expirationDatePicker"
                                ></mat-datepicker-toggle>
                                <mat-datepicker
                                    #expirationDatePicker
                                ></mat-datepicker>
                            </mat-form-field>

                             <!-- Health details section -->
                            <div 
                                class="col-span-2"
                                formGroupName="health"
                                *ngIf="
                                    insuranceForm.get('type').value.toLowerCase() === 'health - basic' ||
                                    insuranceForm.get('type').value.toLowerCase() === 'health - additional'
                                "
                            >
                                <div class="grid grid-cols-2 gap-3">
                                    <app-health-insurance
                                        class="col-span-2"
                                        [people]="data.people"
                                        [selectDisabled]="(isInAdminTask && method === 'create')"
                                        (onNgSelect)="onBeneficiaryChange($event)"
                                    >
                                    </app-health-insurance>
                                    <mat-form-field class="col-span-2">
                                        <mat-label>{{ 'admin.docUploads.insureeNumber' | transloco }}</mat-label>
                                        <input matInput formControlName="insureeNumber" />
                                    </mat-form-field>
                                </div>
                            </div>

                            <!-- Health Basic Coverage Section -->
                            <div 
                                class="col-span-2"
                                formGroupName="healthBasic"  
                                *ngIf="
                                    insuranceForm.get('type').value.toLowerCase() === 'health - basic' ||
                                    insuranceForm.get('type').value.toLowerCase() === 'health - additional'
                                "
                            >
                                <div class="text-lg font-semibold mb-3">{{ 'admin.docUploads.coverage' | transloco }}</div>
                                <div formGroupName="coverage" class="grid grid-cols-2 gap-3">
                                    <mat-form-field class="col-span-1">
                                        <mat-label>{{ 'admin.docUploads.deductiblePerc' | transloco }}</mat-label>
                                        <input matInput formControlName="deductiblePercentage" type="number" />
                                        <span matSuffix>%</span>
                                    </mat-form-field>
                                    <mat-form-field class="col-span-1">
                                        <mat-label>{{ 'admin.docUploads.deductibleAbs' | transloco }}</mat-label>
                                        <input 
                                            matInput 
                                            formControlName="deductibleAbsoluteCents" 
                                            type="number" 
                                            step="0.01"
                                            (click)="selectAll($event)"
                                            [max]="maxDeductible > 0 ? maxDeductible : ''"
                                            (blur)="formatCentsValue('healthBasic.coverage.deductibleAbsoluteCents', $event.target.value)"
                                        />
                                    </mat-form-field>
                                    <mat-form-field class="col-span-1">
                                        <mat-label>{{ 'admin.docUploads.deductibleMax' | transloco }}</mat-label>
                                        <input 
                                            matInput 
                                            formControlName="deductibleMaximalCents" 
                                            type="number" 
                                            step="0.01"
                                            (click)="selectAll($event)"
                                            (blur)="formatCentsValue('healthBasic.coverage.deductibleMaximalCents', $event.target.value)"
                                        />
                                    </mat-form-field>
                                    <mat-form-field class="col-span-1">
                                        <mat-label>{{ 'admin.docUploads.franchise' | transloco }}</mat-label>
                                        <input 
                                            matInput 
                                            formControlName="franchiseCents" 
                                            type="number"
                                            step="0.01"
                                            (click)="selectAll($event)"
                                            (blur)="formatCentsValue('healthBasic.coverage.franchiseCents', $event.target.value)"
                                        />
                                    </mat-form-field>
                                </div>
                            </div>

                            <!-- Premium details section -->
                            <div class="col-span-2" formGroupName="premium">
                                <div class="text-lg font-semibold mb-3">{{ 'admin.docUploads.premium' | transloco }}</div>
                                <div class="grid grid-cols-3 gap-3">
                                    <mat-form-field class="col-span-1">
                                        <mat-label>{{ 'admin.docUploads.periodicityMos' | transloco }}</mat-label>
                                        <input matInput formControlName="periodicityNumMonths" type="number" />
                                    </mat-form-field>
                                
                                    <mat-form-field class="col-span-1">
                                        <mat-label>{{ 'admin.docUploads.grossAmt' | transloco }}</mat-label>
                                        <input 
                                            matInput 
                                            formControlName="grossCents" 
                                            type="number" 
                                            step="0.01"
                                            (click)="selectAll($event)"
                                            (blur)="formatCentsValue('premium.grossCents', $event.target.value)"
                                        />
                                    </mat-form-field>
                                
                                    <mat-form-field class="col-span-1">
                                        <mat-label>{{ 'admin.docUploads.netAmt' | transloco }}</mat-label>
                                        <input 
                                            matInput 
                                            formControlName="netCents" 
                                            type="number" 
                                            step="0.01"
                                            (click)="selectAll($event)"
                                            (blur)="formatCentsValue('premium.netCents', $event.target.value)"
                                        />
                                    </mat-form-field>
                                </div>
                                
                                <!-- Discounts section -->
                                <div class="mt-4" formGroupName="discounts">
                                    <span class="text-lg font-semibold">{{ 'admin.docUploads.discounts' | transloco }}</span>
                                    <div class="flex flex-col gap-1">
                                        <!-- Association Discount -->
                                        <div class="flex items-center gap-3" formGroupName="associationDiscount">
                                            <mat-label class="w-1/3">{{ 'admin.docUploads.assocDisc' | transloco }}</mat-label>
                                            <mat-form-field class="w-1/3">
                                                <mat-label>{{ 'admin.docUploads.percentage' | transloco }}</mat-label>
                                                <input matInput formControlName="percentage" type="number" />
                                                <span matSuffix>%</span>
                                            </mat-form-field>
                                            <mat-form-field class="w-1/3">
                                                <mat-label>{{ 'admin.docUploads.amount' | transloco }}</mat-label>
                                                <input 
                                                    matInput 
                                                    formControlName="amountCents" 
                                                    type="number" 
                                                    step="0.01"
                                                    (click)="selectAll($event)"
                                                    (blur)="formatCentsValue('premium.discounts.associationDiscount.amountCents', $event.target.value)"
                                                />
                                            </mat-form-field>
                                        </div>
                                        
                                        <!-- Family Discount -->
                                        <div class="flex items-baseline gap-3" formGroupName="familyDiscount">
                                            <mat-label class="w-1/3">{{ 'admin.docUploads.famDisc' | transloco }}</mat-label>
                                            <mat-form-field class="w-1/3">
                                                <input matInput formControlName="percentage" type="number" />
                                                <span matSuffix>%</span>
                                            </mat-form-field>
                                            <mat-form-field class="w-1/3">
                                                <input 
                                                    matInput 
                                                    formControlName="amountCents" 
                                                    type="number" 
                                                    step="0.01"
                                                    (click)="selectAll($event)"
                                                    (blur)="formatCentsValue('premium.discounts.familyDiscount.amountCents', $event.target.value)"
                                                />
                                            </mat-form-field>
                                        </div>
                                        
                                        <!-- Loyalty Discount -->
                                        <div class="flex items-baseline gap-3" formGroupName="loyaltyDiscount">
                                            <mat-label class="w-1/3">{{ 'admin.docUploads.loyaltyDisc' | transloco }}</mat-label>
                                            <mat-form-field class="w-1/3">
                                                <input matInput formControlName="percentage" type="number" />
                                                <span matSuffix>%</span>
                                            </mat-form-field>
                                            <mat-form-field class="w-1/3">
                                                <input 
                                                    matInput 
                                                    formControlName="amountCents" 
                                                    type="number" 
                                                    step="0.01"
                                                    (click)="selectAll($event)"
                                                    (blur)="formatCentsValue('premium.discounts.loyaltyDiscount.amountCents', $event.target.value)"
                                                />
                                            </mat-form-field>
                                        </div>
                                        
                                        <!-- Environmental Discount -->
                                        <div class="flex items-baseline gap-3" formGroupName="environmentalDiscount">
                                            <mat-label class="w-1/3">{{ 'admin.docUploads.envDisc' | transloco }}</mat-label>
                                            <mat-form-field class="w-1/3">
                                                <input matInput formControlName="percentage" type="number" />
                                                <span matSuffix>%</span>
                                            </mat-form-field>
                                            <mat-form-field class="w-1/3">
                                                <input 
                                                    matInput 
                                                    formControlName="amountCents" 
                                                    type="number" 
                                                    step="0.01"
                                                    (click)="selectAll($event)"
                                                    (blur)="formatCentsValue('premium.discounts.environmentalDiscount.amountCents', $event.target.value)"
                                                />
                                            </mat-form-field>
                                        </div>
                                        
                                        <!-- Other Discounts -->
                                        <div class="flex items-baseline gap-3" formGroupName="otherDiscounts">
                                            <mat-label class="w-1/3">{{ 'admin.docUploads.othDisc' | transloco }}</mat-label>
                                            <mat-form-field class="w-1/3">
                                                <input matInput formControlName="percentage" type="number" />
                                                <span matSuffix>%</span>
                                            </mat-form-field>
                                            <mat-form-field class="w-1/3">
                                                <input 
                                                    matInput 
                                                    formControlName="amountCents" 
                                                    type="number" 
                                                    step="0.01"
                                                    (click)="selectAll($event)"
                                                    (blur)="formatCentsValue('premium.discounts.otherDiscounts.amountCents', $event.target.value)"
                                                />
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <app-auto-insurance
                                class="col-span-2"
                                *ngIf="
                                    insuranceForm
                                        .get('type')
                                        .value.toLowerCase() === 'auto'
                                "
                            ></app-auto-insurance>
                            
                            <!-- Collective Contract Field -->
                            <mat-form-field class="col-span-2">
                                <mat-label>{{ 'admin.docUploads.collContract' | transloco }}</mat-label>
                                <input matInput formControlName="collectiveContract" />
                            </mat-form-field>
        
                            <div formGroupName="details" class="col-span-2">
                                <div class="w-full flex justify-between">
                                    <mat-form-field class="w-full">
                                        <mat-label
                                            >{{ 'admin.docUploads.details' | transloco }}</mat-label
                                        >
                                        <textarea
                                            matInput
                                            [formControlName]="'information'"
                                            rows="10"
                                        ></textarea>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div
                class="flex items-center w-full py-4"
                [ngClass]="{
                    'px-8': !isModeDialog,
                    'justify-between': isMethodUpdate,
                    'justify-end': isMethodCreate,
                    'border-t': !isInAdminTask
                }"
            >
                <!-- <ng-container *ngIf="isMethodUpdate">
                    <button
                        class="-ml-4"
                        mat-button
                        [color]="'warn'"
                        (click)="(null)"
                    >
                        Delete
                    </button>
                </ng-container> -->
                <div class="flex items-center w-full">
                    <!-- <div class="flex items-center mr-4" *ngIf="isLoading">
                        <mat-spinner [diameter]="20"></mat-spinner>
                    </div> -->
                    <div class="flex items-center mr-4" *ngIf="flashMessage">
                        <!-- <ng-container *ngIf="flashMessage === 'success'">
                            <mat-icon
                                class="text-green-500"
                                [svgIcon]="'heroicons_outline:check'"
                            ></mat-icon>
                            <span class="ml-2">{{ successText }}</span>
                        </ng-container> -->
                        <ng-container *ngIf="flashMessage === 'error'">
                            <mat-icon
                                class="text-red-500"
                                [svgIcon]="'heroicons_outline:x-mark'"
                            ></mat-icon>
                            <span class="ml-2"
                                >An error occurred, try again!</span
                            >
                        </ng-container>
                    </div>

                    <div class="grid grid-cols-2 w-full">
                        <div>
                            <button
                                *ngIf="!isInAdminTask"
                                mat-flat-button
                                class="mr-2"
                                (click)="delete()"
                            >
                                <span class="text-red-500">
                                    {{ !insurance?.isNewInsurance ? ('admin.docUploads.delete' | transloco) : ('admin.docUploads.discard' | transloco) }}
                                </span>
                            </button>
                        </div>

                        <div class="text-right">
                            <button
                                mat-flat-button
                                color="primary"
                                [disabled]="!insuranceForm.valid"
                                (click)="(submitAction)"
                            >
                                {{ submitText }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
  
    <ng-template #showBlock>
        <div class="relative">
            <div class="flex flex-col w-full" *ngIf="toggleInsurerForm">
                <div class="text-lg font-semibold px-4">
                    New Insurer 
                </div>
                <insurer-details
                    [insurer]="{ id: '', languages: {} }" 
                    (closeDetails)="closeInsurerForm($event)"
                ></insurer-details>
            </div>
            <app-add-product-form
                *ngIf="toggleProductForm" 
                [insuranceType]="insuranceForm.get('type').value"
                [insurerId]="insuranceForm.get('insurerId').value"
                [insurerName]="selectedInsurerName"
                [isAddProduct]="false"
                (closeForm)="closeProductForm($event)"
            ></app-add-product-form> 
            <app-object-form 
                *ngIf="togglePeopleAndObjectForm" 
                [method]="'create'"
                [inputData]="{ ownerId: this.document.user.uid, type: 'PERSON' }"
                (closeForm)="closePeopleAndObject($event)"
            ></app-object-form>
        </div>
    </ng-template>
</div>
<ng-template #loadingSpinner>
    <div 
        class="p-4 grid place-items-center"
        [ngClass]="{ 'shadow-lg': !isInAdminTask }"
    >
        <mat-progress-spinner    
        [diameter]="25"
        mode="indeterminate"> </mat-progress-spinner>
    </div>
</ng-template>
<!-- </div> -->
