import { ChangeDetectorRef, Pipe, PipeTransform, OnDestroy } from '@angular/core';
import { Product } from 'app/models/product.model';
import { TranslocoService } from '@ngneat/transloco';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Pipe({
    name: 'productNameGetter',
    pure: false, // Set pure to false to allow asynchronous behavior
    standalone: true,
})
export class ProductNameGetterPipe implements PipeTransform, OnDestroy {
    private _unsubscribeAll: Subject<void> = new Subject<void>();
    selectedLanguage: string;

    constructor(
        private _translocoService: TranslocoService,
        private _changeDetectorRef: ChangeDetectorRef
    ) {
        this._translocoService.langChanges$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((lang) => {
                this.selectedLanguage = lang;
            });
    }

    transform(product: Product): string {
        if (this.selectedLanguage && product?.languages) {
            const translatedName = product.languages[this.selectedLanguage.toUpperCase()]?.name;
            if (translatedName) {
                return translatedName;
            }
        }

        return product?.independentName || '';
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
