import {
    Component,
    EventEmitter,
    Input,
    Output,
    ViewChild,
    OnInit,
    OnChanges,
    OnDestroy,
    SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
    FormGroupDirective,
    UntypedFormGroup,
    Validators,
    FormsModule,
    ReactiveFormsModule,
    AbstractControl,
} from '@angular/forms';
import { NgSelectModule, NgSelectComponent } from '@ng-select/ng-select';
import { TranslocoService, TranslocoModule } from '@ngneat/transloco';
import { MatFormFieldModule } from '@angular/material/form-field';
import { PeopleAndObjects } from 'app/models/people-objects';
import { MatInputModule } from '@angular/material/input';

@Component({
    selector: 'app-health-insurance',
    standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        MatFormFieldModule,
        MatInputModule,
        NgSelectModule,
        TranslocoModule,
    ],
    templateUrl: './health-insurance.component.html',
    styleUrls: ['./health-insurance.component.scss'],
})
export class HealthInsuranceComponent implements OnInit, OnChanges, OnDestroy {
    @Input() people: PeopleAndObjects[] = [];
    @Input() selectDisabled: boolean = false;
    @Output() onNgSelect: EventEmitter<{ group: string; e: Event }> = new EventEmitter();
    @ViewChild('ngSelectField') ngSelectField: NgSelectComponent;

    form: UntypedFormGroup;
    beneficiaryForm: AbstractControl;

    constructor(private rootFormGroup: FormGroupDirective) {}

    ngOnInit(): void {
        this.initializeFormControls();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.selectDisabled && this.beneficiaryForm) {
            this.updateDisabledState();
        }
    }

    private initializeFormControls(): void {
        this.form = this.rootFormGroup.control.get('health') as UntypedFormGroup;
        if (this.form) {
            this.beneficiaryForm = this.form.get('beneficiary');
            if (this.beneficiaryForm) {
                this.beneficiaryForm.get('id')?.disable();
                this.beneficiaryForm.get('name')?.addValidators(Validators.required);
                this.beneficiaryForm.get('id')?.updateValueAndValidity();
                this.beneficiaryForm.get('name')?.updateValueAndValidity();
            }
        }

        this.updateDisabledState();
    }

    private updateDisabledState(): void {
        if (this.selectDisabled) {
            this.beneficiaryForm.get('id')?.disable();
        } else {
            this.beneficiaryForm.get('id')?.enable();
        }
        this.beneficiaryForm.get('id')?.updateValueAndValidity();
    }

    ngOnDestroy(): void {
        if (this.beneficiaryForm) {
            this.beneficiaryForm.get('id')?.clearValidators();
            this.beneficiaryForm.get('name')?.updateValueAndValidity();
        }
    }

    onNgSelectChange(group: string, e: any) {
        this.onNgSelect.emit({ group, e });
    }

    public unfocusBeneficiaryField() {
        if (this.ngSelectField) {
            this.ngSelectField.blur();
        }
    }
}
