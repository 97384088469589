import { Pipe, PipeTransform } from '@angular/core';
import { Event, Log, CommentLog, CollectionTypeMapping } from '../../models/db/event.model';

@Pipe({
  name: 'eventMessage',
  pure: true,
  standalone: true,
})
export class EventMessagePipe implements PipeTransform {
  transform(event: Event): string {
    const { language = 'EN', name, recordId, actionType, collectionType: eventCollectionType, commentLog, logs } = event || {};
    const collectionType = CollectionTypeMapping[eventCollectionType];
    const translations = {
      'EN': {
        'created': `Initiated new ${collectionType} "${name || recordId}"`,
        'deleted': `Deleted record from "${collectionType}" ID: ${recordId}`,
        'request-to-extraction': `
          <p>Your insurance contracts for "${name}" have been uploaded and are visible in your insurance overview.</p>
          <p>We will now proceed with the extraction of the insurances and make them available asap.</p>
        `,
        'insurance-published': `The insurance "${name}" has been inserted in your profile. You have access to all the details now.`,
        'insurance-unpublished': `The insurance "${name}" has been removed from your profile. Please contact us if this is an error.`,
        'insurance-deleted': `The insurance "${name}" has been removed from your profile. Please contact us if this is an error.`,
        'insurance-updated': `The insurance "${name}" has been updated. The changes are instantly available in your insurance overview.`,
        'beneficiary-added': `The beneficiary "${name}" has been created and is available in your profile.`,
        'beneficiary-deleted': `The beneficiary "${name}" has been removed from your profile.`,
      },
      'DE': {
        'created': `Neues ${collectionType} "${name || recordId}" initiiert`,
        'deleted': `Datensatz von "${collectionType}" ID: ${recordId} gelöscht`,
        'request-to-extraction': `
          <p>Ihre Versicherungsverträge für "${name}" wurden hochgeladen und sind in Ihrer Versicherungsübersicht sichtbar.</p>
          <p>Wir werden nun mit der Extraktion der Versicherungen fortfahren und diese so schnell wie möglich verfügbar machen.</p>
        `,
        'insurance-published': `Die Versicherung "${name}" wurde in Ihrem Profil eingefügt. Sie haben jetzt Zugriff auf alle Details.`,
        'insurance-unpublished': `Die Versicherung "${name}" wurde aus Ihrem Profil entfernt. Bitte kontaktieren Sie uns, falls dies ein Fehler ist.`,
        'insurance-deleted': `Die Versicherung "${name}" wurde aus Ihrem Profil entfernt. Bitte kontaktieren Sie uns, falls dies ein Fehler ist.`,
        'insurance-updated': `Die Versicherung "${name}" wurde aktualisiert. Die Änderungen sind sofort in Ihrer Versicherungsübersicht verfügbar.`,
        'beneficiary-added': `Der Begünstigte "${name}" wurde erstellt und ist in Ihrem Profil verfügbar.`,
        'beneficiary-deleted': `Der Begünstigte "${name}" wurde aus Ihrem Profil entfernt.`,
      },
      'IT': {
        'created': `Iniziato nuovo ${collectionType} "${name || recordId}"`,
        'deleted': `Record eliminato da "${collectionType}" ID: ${recordId}`,
        'request-to-extraction': `
          <p>I tuoi contratti di assicurazione per "${name}" sono stati caricati e sono visibili nella tua panoramica delle assicurazioni.</p>
          <p>Procederemo ora con l'estrazione delle assicurazioni e le renderemo disponibili il prima possibile.</p>
        `,
        'insurance-published': `L'assicurazione "${name}" è stata inserita nel tuo profilo. Hai accesso a tutti i dettagli ora.`,
        'insurance-unpublished': `L'assicurazione "${name}" è stata rimossa dal tuo profilo. Contattaci se questo è un errore.`,
        'insurance-deleted': `L'assicurazione "${name}" è stata rimossa dal tuo profilo. Contattaci se questo è un errore.`,
        'insurance-updated': `L'assicurazione "${name}" è stata aggiornata. Le modifiche sono disponibili immediatamente nella tua panoramica delle assicurazioni.`,
        'beneficiary-added': `Il beneficiario "${name}" è stato creato ed è disponibile nel tuo profilo.`,
        'beneficiary-deleted': `Il beneficiario "${name}" è stato rimosso dal tuo profilo.`,
      },
      'FR': {
        'created': `Nouveau ${collectionType} "${name || recordId}" initié`,
        'deleted': `Enregistrement supprimé de "${collectionType}" ID: ${recordId}`,
        'request-to-extraction': `
          <p>Vos contrats d'assurance pour "${name}" ont été téléchargés et sont visibles dans votre aperçu des assurances.</p>
          <p>Nous allons maintenant procéder à l'extraction des assurances et les rendre disponibles dès que possible.</p>
        `,
        'insurance-published': `L'assurance "${name}" a été insérée dans votre profil. Vous avez maintenant accès à tous les détails.`,
        'insurance-unpublished': `L'assurance "${name}" a été retirée de votre profil. Veuillez nous contacter si c'est une erreur.`,
        'insurance-deleted': `L'assurance "${name}" a été retirée de votre profil. Veuillez nous contacter si c'est une erreur.`,
        'insurance-updated': `L'assurance "${name}" a été mise à jour. Les changements sont immédiatement disponibles dans votre aperçu des assurances.`,
        'beneficiary-added': `Le bénéficiaire "${name}" a été créé et est disponible dans votre profil.`,
        'beneficiary-deleted': `Le bénéficiaire "${name}" a été retiré de votre profil.`,
      }
    };

    if (actionType === 'updated') {
      if (commentLog) {
        return this.formatCommentLog(commentLog, language);
      } else {
        return this.formatLogs(logs, collectionType, name, language);
      }
    }

    return translations[language][actionType];
  }

  private getTranslation(key: string, lang: 'DE' | 'EN' | 'IT' | 'FR', replacements: { [key: string]: string } = {}): string {
    const translations = {
      'noDetailsUpdated': {
        'EN': 'No details updated.',
        'DE': 'Keine Details aktualisiert.',
        'IT': 'Nessun dettaglio aggiornato.',
        'FR': 'Aucun détail mis à jour.',
      },
      'personalNotesChanged': {
        'EN': `The personal notes for the insurance "{insuranceName}" have been changed. Old value: "{oldValue}" -> New value: "{newValue}"`,
        'DE': `Die persönlichen Notizen für die Versicherung "{insuranceName}" wurden geändert. Alter Wert: "{oldValue}" -> Neuer Wert: "{newValue}"`,
        'IT': `Le note personali per l'assicurazione "{insuranceName}" sono state modificate. Valore vecchio: "{oldValue}" -> Valore nuovo: "{newValue}"`,
        'FR': `Les notes personnelles pour l'assurance "{insuranceName}" ont été modifiées. Ancienne valeur : "{oldValue}" -> Nouvelle valeur : "{newValue}"`,
      },
      'propertyUpdated': {
        'EN': 'Updated {propertyName} from "{oldValue}" to "{newValue}"',
        'DE': 'Aktualisiert {propertyName} von "{oldValue}" zu "{newValue}"',
        'IT': 'Aggiornato {propertyName} da "{oldValue}" a "{newValue}"',
        'FR': 'Mis à jour {propertyName} de "{oldValue}" à "{newValue}"',
      },
      'updatedFollowing': {
        'EN': 'Updated following',
        'DE': 'Folgendes aktualisiert:',
        'IT': 'Aggiornato il seguente:',
        'FR': 'Mise à jour suivante :',
      },
      'personalInformationChanged': {
        'EN': `The personal information for the insurance "{insuranceName}" has been changed. Old value: "{oldValue}" -> New value: "{newValue}"`,
        'DE': `Die persönlichen Informationen für die Versicherung "{insuranceName}" wurden geändert. Alter Wert: "{oldValue}" -> Neuer Wert: "{newValue}"`,
        'IT': `Le informazioni personali per l'assicurazione "{insuranceName}" sono state cambiate. Valore vecchio: "{oldValue}" -> Valore nuovo: "{newValue}"`,
        'FR': `Les informations personnelles pour l'assurance "{insuranceName}" ont été changées. Ancienne valeur : "{oldValue}" -> Nouvelle valeur : "{newValue}"`,
      },
      'commentAdded': {
        'EN': 'Added new comment "{description}"',
        'DE': 'Neuer Kommentar hinzugefügt "{description}"',
        'IT': 'Aggiunto nuovo commento "{description}"',
        'FR': 'Nouveau commentaire ajouté "{description}"',
      },
      'commentDeleted': {
        'EN': 'Deleted comment ID: {commentId}',
        'DE': 'Kommentar ID gelöscht: {commentId}',
        'IT': 'ID commento eliminato: {commentId}',
        'FR': 'ID du commentaire supprimé : {commentId}',
      },
      'commentUpdated': {
        'EN': 'Updated comment from "{prevDescription}" to "{description}"',
        'DE': 'Kommentar aktualisiert von "{prevDescription}" zu "{description}"',
        'IT': 'Commento aggiornato da "{prevDescription}" a "{description}"',
        'FR': 'Commentaire mis à jour de "{prevDescription}" à "{description}"',
      },
      'defaultComment': {
        'EN': 'Comment action performed',
        'DE': 'Kommentaraktion durchgeführt',
        'IT': 'Azione sul commento eseguita',
        'FR': 'Action sur le commentaire effectuée',
      },
    };

    let message = translations[key] ? (translations[key][lang] || translations[key]['EN']) : 'N/A';
    Object.keys(replacements).forEach(replaceKey => {
      message = message.replace(new RegExp(`\\{${replaceKey}\\}`, 'g'), replacements[replaceKey]);
    });

    return message;
}


  private formatLogs(logs: Log[], collectionType: string, insuranceName: string, lang: 'DE' | 'EN' | 'IT' | 'FR'): string {
    if (logs.length === 0) {
      return this.getTranslation('noDetailsUpdated', lang);
    }

    if (logs.length === 1) {
      const log = logs[0];
      const propertyName = log.propertyName;
      const oldValue = log.previousValue ? `${log.previousValue}` : this.getTranslation('notProvided', lang);
      const newValue = `${log.newValue}`;

      if (collectionType === 'insurances') {
        if (propertyName === 'details, notes') {
            return this.getTranslation('personalNotesChanged', lang, { insuranceName, oldValue, newValue });
        } else if (propertyName === 'details, information') {
            return this.getTranslation('personalInfoChanged', lang, { insuranceName, oldValue, newValue });
        }
      }

      return this.getTranslation('propertyUpdated', lang, { propertyName, oldValue, newValue });
    }
  
    const listItems = logs.map(log => {
      const replacements = {
        insuranceName: insuranceName,
        propertyName: log.propertyName,
        oldValue: log.previousValue ? `${log.previousValue}` : `N/A`,
        newValue: log.newValue
      };
  
      if (collectionType === 'insurances') {
        if (log.propertyName === 'details, notes') {
            return `<li>${this.getTranslation('personalNotesChanged', lang, replacements)}</li>`;
        } else if (log.propertyName === 'details, information') {
            return `<li>${this.getTranslation('personalInfoChanged', lang, replacements)}</li>`;
        }
      }

      return `<li>${this.getTranslation('propertyUpdated', lang, replacements)}</li>`;
    }).join('');
  
    return `<ul>${this.getTranslation('updatedFollowing', lang)}: ${listItems}</ul>`;
  }

  private formatCommentLog(commentLog: CommentLog, lang: 'DE' | 'EN' | 'IT' | 'FR' = 'EN'): string {
    const replacements = {
      commentId: commentLog.id,
      description: commentLog.description,
      prevDescription: commentLog.prevDescription,
    };

    switch (commentLog.type) {
      case 'created':
        return this.getTranslation('commentAdded', lang, { description: commentLog.description });
      case 'deleted':
        return this.getTranslation('commentDeleted', lang, { commentId: commentLog.id });
      case 'updated':
        return this.getTranslation('commentUpdated', lang, replacements);
      default:
        return this.getTranslation('defaultComment', lang);
    }
  }

}
