import { Injectable } from '@angular/core';
import { getFunctions, httpsCallable } from '@angular/fire/functions';
import { from, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { EmailNotification } from 'app/models/db/event.model';

@Injectable({
    providedIn: 'root',
})
export class AdminUserService {
    constructor() {}

    getAdminUsers(): Observable<any> {
        const functions = getFunctions(undefined, 'europe-west6');
        const callAdminUsers = httpsCallable(functions, 'getAdminUsers');

        return from(callAdminUsers({})).pipe(
            map((response) => response.data),
            catchError((error) => {
                throw error;
            })
        );
    }

    sendEmailNotification(notificationData: EmailNotification): Observable<any> {
        const functions = getFunctions(undefined, 'europe-west6');
        const sendActivityNotification = httpsCallable(
            functions,
            'sendActivityNotification'
        );

        return from(sendActivityNotification(notificationData)).pipe(
            map((response) => response.data),
            catchError((error) => {
                // Handle or propagate the error as needed
                console.error('Error sending email notification:', error);
                throw error;
            })
        );
    }
}
