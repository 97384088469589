
<div class="flex flex-col w-full h-full max-h-[calc(100vh-160px)] relative">
    <div *ngIf="isLoading || insuranceLoading" class="absolute inset-x-0 top-0">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>

    <div class="flex items-center justify-between p-4 bg-white shadow border-b">
        <div class="flex justify-start items-center gap-3">
            <button 
                class="flex justify-center items-center"
                matTooltip="Return to task list"
                (click)="navigateToTasks()"
            >
                <mat-icon svgIcon="heroicons_outline:arrow-small-left"></mat-icon>
            </button>
            <h1 *ngIf="document" class="text-lg font-bold">
                Task {{document?.taskId || document?.id}}: Extraction - {{document?.user?.displayName}}
            </h1>
        </div>
        <div *ngIf="document" class="flex justify-center items-center gap-3">
            <button 
                matTooltip="Delete Extraction" 
                [disabled]="isLoading || eventsLoading || insuranceLoading" 
                (click)="delete()"
            >
                <mat-icon class="icon-size-5" svgIcon="heroicons_outline:trash"></mat-icon>
            </button>
            <button 
                matTooltip="Publish all insurances and complete extraction" 
                [disabled]="isLoading || eventsLoading || insuranceLoading || !canMarkAsComplete" 
                (click)="markAsCompleted()"
            >
                <mat-icon class="icon-size-5" svgIcon="mat_outline:publish"></mat-icon>
            </button>
        </div>
    </div>

    <mat-drawer-container class="custom-drawer-container flex h-full max-h-[calc(100vh-220px)] overflow-auto">
        <mat-drawer class="w-full sm:w-128 dark:bg-gray-900" mode="over" [opened]="false" position="end" [disableClose]="true" #matDrawer>
            <ng-container *ngIf="matDrawerContext === 'extraction'">
                <app-document-extraction-form 
                    [docExtraction]="document" 
                    [isAdmin]="true" 
                    (closeDetails)="closeDrawer()"
                >
                </app-document-extraction-form>
            </ng-container>
            <ng-container *ngIf="matDrawerContext === 'beneficiary'">
                <div class="flex flex-col justify-center p-4">
                    <div class="flex justify-between items-center mb-4">
                        <div class="text-lg font-semibold text-gray-700">
                            Add Beneficiary
                        </div>
                        <button (click)="closeDrawer()">
                            <mat-icon>close</mat-icon>
                        </button>
                    </div>
                    <app-select-people-objects 
                        [ownerId]="document.user.uid"
                        [selectedIds]="beneficiaryIds"
                        label="Select Beneficiary"
                        (onClickSave)="addBeneficiary($event)"
                    ></app-select-people-objects>
                </div>
            </ng-container>
            <ng-container *ngIf="matDrawerContext === 'add-insurance'">
                <div class="flex flex-col justify-center p-4">
                    <div class="flex justify-between items-center mb-8">
                        <div class="text-lg font-semibold text-gray-700">
                            Add Insurance
                        </div>
                        <button (click)="closeDrawer()">
                            <mat-icon>close</mat-icon>
                        </button>
                    </div>
                    <insurance-details 
                        [insurance]="objectInsurance" 
                        [documentId]="document.id" 
                        [document]="document" 
                        [method]="method" 
                        mode="inline" 
                        isInAdmin="true" 
                        isInAdminTask="true"
                        (closeDetails)="handleInsuranceFormClose()" 
                    >
                    </insurance-details>
                </div>
            </ng-container>
            <ng-container *ngIf="matDrawerContext === 'contract-data'">
                <div class="flex flex-col justify-center p-4">
                    <div class="flex justify-between items-center mb-8">
                        <div class="text-lg font-semibold text-gray-700">
                            {{'tasks.extraction.addDocData' | transloco | slice: 2}}
                        </div>
                        <button (click)="closeDrawer()">
                            <mat-icon>close</mat-icon>
                        </button>
                    </div>
                    <app-contract-data-form
                        [ownerId]="document?.user?.uid"
                        [contractData]="document?.contractData"
                        (formSubmit)="updateContractData($event)"
                    >
                    </app-contract-data-form>
                </div>
            </ng-container>
        </mat-drawer>
        <mat-drawer-content class="h-full w-full bg-default">
            <div class="h-full w-full grid grid-cols-5 gap-4 px-4">
                <div class="col-span-2 sticky top-14 pb-10">
                    <pdf-view [downloadURL]="document?.path"></pdf-view>
                </div>
    
                <div class="col-span-3 p-4 max-h-[calc(100vh-260px)] overflow-auto bg-white rounded-md mt-5">
                    <ng-container *ngIf="document?.contractData">
                        <div class="contract-data-section w-full mb-8">
                             <div class="flex justify-start items-center border p-4 bg-gray-50">
                                 <h2 class="text-lg font-bold">
                                    {{'tasks.extraction.contractData' | transloco}} {{document?.filename}}
                                 </h2>
                             </div>
                             <div class="overflow-x-auto">
                                 <table mat-table class="min-w-full divide-y divide-gray-200 relative">
                                     <thead>
                                         <tr>
                                             <th scope="col"
                                                 class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                 {{'tasks.extraction.insurer' | transloco}}
                                             </th>
                                             <th scope="col"
                                                 class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                 {{'tasks.extraction.holder' | transloco}}
                                             </th>
                                             <th scope="col"
                                                 class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                 {{'tasks.extraction.contractDate' | transloco}}
                                             </th>
                                             <th scope="col"
                                                 class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                 {{'tasks.extraction.validity' | transloco}}
                                             </th>
                                             <th scope="col"
                                                 class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                 {{'tasks.extraction.periodicity' | transloco}}
                                             </th>
                                             <th scope="col"
                                                 class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                 {{'tasks.actions' | transloco}}
                                             </th>
                                         </tr>
                                     </thead>
                                     <tbody class="bg-white divide-y divide-gray-200">
                                        <tr>
                                            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                <ng-container *ngIf="document?.contractData?.insurer?.languages[currentLanguage] as insurerLang">
                                                    {{ insurerLang?.name || document?.contractData?.insurer?.independentName }}
                                                </ng-container>
                                            </td>
                                            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                <ng-container *ngIf="document?.contractData?.holder as contractHolder">
                                                    {{ contractHolder.firstName + ' ' + contractHolder.lastName }}
                                                </ng-container>
                                            </td>
                                            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                {{ document?.contractData?.contractDate | swissDateFormat }}
                                            </td>
                                            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                {{ document?.contractData?.effectiveDate | swissDateFormat }} - {{ document?.contractData?.expirationDate | swissDateFormat }}
                                            </td>
                                            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                {{ document?.contractData?.paymentPeriodicity }}
                                            </td>
                                            <td class="flex justify-center items-center gap-2 px-6 py-4">
                                                <button matTooltip="Edit" [disabled]="isLoading" (click)="openDrawer('contract-data')">
                                                    <mat-icon class="icon-size-5" svgIcon="heroicons_outline:pencil"></mat-icon>
                                                </button>
                                                <button matTooltip="Delete" [disabled]="isLoading" (click)="updateContractData(null)">
                                                    <mat-icon class="icon-size-5" svgIcon="heroicons_outline:trash">
                                                    </mat-icon>
                                                </button>
                                            </td>
                                        </tr>
                                     </tbody>
                                 </table>
                             </div>
                        </div>
                    </ng-container>

                    <button
                        *ngIf="document && !document?.contractData" 
                        class="border-dashed border-2 text-md border-gray-300 rounded px-4 py-2 flex items-center gap-2 justify-center w-full mb-4"
                        [disabled]="isLoading || eventsLoading || insuranceLoading"
                        (click)="openDrawer('contract-data')"
                    >
                        {{'tasks.extraction.addDocData' | transloco}}
                    </button>

                    <ng-container *ngFor="let beneficiary of (document?.beneficiaries || [])">
                       <div class="beneficiary-section w-full mb-4">
                            <div class="flex justify-between items-center border p-4 bg-gray-50">
                                <h2 class="text-lg font-bold">
                                    {{beneficiary.firstName}} {{beneficiary.lastName}} {{ beneficiary.birthday | toDate | date: 'dd.MM.yyyy' }}
                                </h2>
                                <div class="flex justify-center items-center gap-2">
                                    <button matTooltip="Delete beneficiary and their insurance." (click)="deleteBeneficiary(beneficiary)">
                                        <mat-icon class="icon-size-5" svgIcon="heroicons_outline:trash">
                                        </mat-icon>
                                    </button>
                                    <button 
                                        [matTooltip]="!insurancesGroupedByBeneficiary[beneficiary.id]?.pendingCount ? null : 'Publish all beneficiary insurances'" 
                                        [disabled]="!insurancesGroupedByBeneficiary[beneficiary.id]?.pendingCount" 
                                        (click)="publishInsurancesByBeneficiary(beneficiary.id)"
                                    >
                                        <mat-icon class="icon-size-5" svgIcon="mat_outline:published_with_changes">
                                        </mat-icon>
                                    </button>
                                </div>
                            </div>
                            <div class="overflow-x-auto">
                                <table mat-table class="min-w-full divide-y divide-gray-200 relative">
                                    <thead>
                                        <tr>
                                            <th scope="col"
                                                class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Type
                                            </th>
                                            <th scope="col"
                                                class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Holder
                                            </th>
                                            <th scope="col"
                                                class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Insurance Product
                                            </th>
                                            <th scope="col"
                                                class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Validity
                                            </th>
                                            <th scope="col"
                                                class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Net Premium
                                            </th>
                                            <th scope="col"
                                                class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Actions
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody class="bg-white divide-y divide-gray-200">
                                        <ng-container *ngIf="insurancesGroupedByBeneficiary[beneficiary.id]?.insurances?.length; else noInsurance">
                                            <ng-container *ngFor="let insurance of insurancesGroupedByBeneficiary[beneficiary.id].insurances">
                                                <tr>
                                                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                        {{ insurance.type }}
                                                    </td>
                                                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                        {{ insurance.holder.name }}
                                                    </td>
                                                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                        <ng-container *ngIf="insurance?.product?.languages[currentLanguage] as productLang">
                                                            {{ productLang?.name || insurance?.product?.independentName }}
                                                        </ng-container>
                                                    </td>
                                                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                        {{ insurance.effectiveDate | swissDateFormat }} - {{ insurance.expirationDate | swissDateFormat }}
                                                    </td>
                                                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                        {{ insurance.premium.netCents / 100 | number:'1.2-2' }}
                                                    </td>
                                                    <td class="flex justify-center items-center gap-2 px-6 py-4">
                                                        <button matTooltip="Edit" (click)="editBeneficiaryInsurance(insurance)">
                                                            <mat-icon class="icon-size-5" svgIcon="heroicons_outline:pencil"></mat-icon>
                                                        </button>
                                                        <button matTooltip="Duplicate" (click)="duplicateBeneficiaryInsurance(insurance)">
                                                            <mat-icon class="icon-size-5" svgIcon="feather:copy"></mat-icon>
                                                        </button>
                                                        <button matTooltip="Delete" (click)="deleteInsurance(insurance)">
                                                            <mat-icon class="icon-size-5" svgIcon="heroicons_outline:trash">
                                                            </mat-icon>
                                                        </button>
                                                        <button
                                                            [matTooltip]="insurance.captureStatus === 'PENDING' ? 'Publish' : 'Unpublish'"
                                                            (click)="updateInsuranceStatus(insurance)"
                                                        >
                                                            <mat-icon *ngIf="insurance.captureStatus === 'PENDING'"
                                                                class="icon-size-5" svgIcon="mat_outline:publish">
                                                            </mat-icon>
                                                            <mat-icon *ngIf="insurance.captureStatus === 'COMPLETED'"
                                                                class="icon-size-5" svgIcon="mat_outline:unpublished">
                                                            </mat-icon>
                                                        </button>
                                                    </td>
                                                </tr>
                                            </ng-container>
                                        </ng-container>
                                    
                                        <ng-template #noInsurance>
                                            <tr>
                                                <td colspan="6" class="px-6 py-4 whitespace-nowrap text-center text-sm text-gray-500">
                                                    No insurance policies found.
                                                </td>
                                            </tr>
                                        </ng-template>
                                    </tbody>
                                </table>
                            </div>
                            <div class="flex justify-center items-center border mt-4 p-2 rounded-sm">
                                <button 
                                    class="flex items-center px-2 py-1 text-sm text-secondary hover:text-black"
                                    (click)="addBeneficiaryInsurance(beneficiary)"
                                >
                                    + Add Insurance
                                </button>
                            </div>
                       </div>
                    </ng-container>

                    <button
                        *ngIf="document" 
                        class="border-dashed border-2 text-md border-gray-300 rounded px-4 py-2 flex items-center justify-center gap-2 w-full my-4 mb-8"
                        [disabled]="isLoading || eventsLoading || insuranceLoading"
                        (click)="openDrawer('beneficiary')"
                    >
                     {{'tasks.extraction.addBeneficiary' | transloco}}
                    </button>

                    <div *ngIf="events.length" class="activities-section w-full">
                        <div class="flex justify-between items-center border p-4 bg-gray-50">
                            <h2 class="text-lg font-bold">
                                {{'tasks.extraction.activities' | transloco}}
                            </h2>
                            <div class="flex justify-center items-center gap-2">
                                <button [matTooltip]="!unpublishEventIds.length ? null : 'Publish all'" [disabled]="!unpublishEventIds.length" (click)="publishAllEvent()">
                                    <mat-icon class="icon-size-5" svgIcon="mat_outline:published_with_changes">
                                    </mat-icon>
                                </button>
                            </div>
                        </div>
                        <div class="overflow-x-auto">
                            <table mat-table class="min-w-full divide-y divide-gray-200 relative">
                                <div *ngIf="eventsLoading" class="absolute inset-x-0 top-0">
                                    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                                </div>
                                <thead>
                                    <tr>
                                        <th scope="col"
                                            class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            {{'tasks.extraction.date' | transloco}}
                                        </th>
                                        <th scope="col"
                                            class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            {{'tasks.extraction.time' | transloco}}
                                        </th>
                                        <th scope="col"
                                            class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            {{'tasks.extraction.who' | transloco}}
                                        </th>
                                        <th scope="col"
                                            class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            {{'tasks.extraction.what' | transloco}}
                                        </th>
                                        <th scope="col"
                                            class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            {{'tasks.extraction.settings' | transloco}}
                                        </th>
                                        <th scope="col"
                                            class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            {{'tasks.actions' | transloco}}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody class="bg-white divide-y divide-gray-200">
                                    <ng-container *ngFor="let event of events">
                                        <tr>
                                            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                {{ event.createdAt | toDate | date: 'dd.MM.yyyy' }}
                                            </td>
                                            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                {{ event.createdAt | toDate | date: 'HH:mm' }}
                                            </td>
                                            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                {{ event.createdBy.displayName }}
                                            </td>
                                            <td 
                                                class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 truncate max-w-80" 
                                                [innerHTML]="event | eventMessage"
                                                [matTooltip]="event | eventMessage | stripHtml"
                                            >
                                            </td>
                                            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 capitalize">
                                                {{ event?.status || 'unpublished' }}
                                            </td>
                                            <td class="flex justify-center items-center gap-2 px-6 py-4">
                                                <button
                                                    [matTooltip]="!event?.status || event?.status === 'unpublished' ? 'Publish' : 'Unpublish'"
                                                    (click)="updateEventStatus(event)"
                                                >
                                                    <mat-icon *ngIf="!event?.status || event?.status === 'unpublished'"
                                                        class="icon-size-5" svgIcon="mat_outline:publish">
                                                    </mat-icon>
                                                    <mat-icon *ngIf="event?.status && event?.status === 'published'"
                                                        class="icon-size-5" svgIcon="mat_outline:unpublished">
                                                    </mat-icon>
                                                </button>
                                            </td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                        <div class="flex justify-center items-center border mt-4 p-2 rounded-sm">
                            <button 
                                class="flex items-center px-2 py-1 text-sm text-secondary hover:text-black"
                                [disabled]="!document"
                                (click)="openDrawer('extraction')"
                            >
                            {{ 'tasks.extraction.addComment' | transloco }}
                            </button>
                        </div>
                    </div>

                </div>
            </div>
        </mat-drawer-content>
    </mat-drawer-container>
</div>