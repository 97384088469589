import { Injectable } from '@angular/core';
import {
    doc,
    query,
    where,
    addDoc,
    getDoc,
    getDocs,
    orderBy,
    deleteDoc,
    Firestore,
    updateDoc,
    collection,
    writeBatch,
    DocumentData,
    runTransaction,
    collectionData,
    DocumentReference,
    CollectionReference,
} from '@angular/fire/firestore';
import { Observable, switchMap, from, mapTo } from 'rxjs';

import { InsurerEntity } from '../../models/db/insurer.model';
import {
    InsuranceRetrievalEntity,
    InsuranceRetrieval,
    Comment,
    Status,
} from '../../models/db/insurance-retrieval.model';
import { CollectionType, CommentLog } from '../../models/db/event.model';
import { EventService } from './event.service';

@Injectable({
    providedIn: 'root',
})
export class InsuranceRetrievalService {
    private collectionRef = collection(this.firestore, 'insurance-retrievals');
    public insuranceRetrievals$: Observable<InsuranceRetrieval[]>;

    constructor(private firestore: Firestore, private eventService: EventService) {
        this.insuranceRetrievals$ = collectionData(
            query(this.collectionRef, orderBy('updatedAt')),
            { idField: 'id' }
        ).pipe(
            switchMap((docs: DocumentData[]) =>
                from(
                    Promise.all(
                        docs.map(async (doc: DocumentData) => {
                            const insuranceRetrieval = doc as InsuranceRetrievalEntity;
                            let insurerData: InsurerEntity | null = null;

                            if (insuranceRetrieval.insurer) {
                                insurerData = await this.resolveInsurerReference(
                                    insuranceRetrieval.insurer
                                );
                            }

                            const result = {
                                ...insuranceRetrieval,
                                insurer: insurerData,
                            } as InsuranceRetrieval;

                            return result;
                        })
                    )
                )
            )
        );
    }

    onProcessRetrievals$(uid: string): Observable<InsuranceRetrieval[]> {
        return collectionData(
            query(
                this.collectionRef,
                where('status', '!=', 'completed'),
                where('user.uid', '==', uid),
                orderBy('status'),
                orderBy('updatedAt')
            ),
            { idField: 'id' }
        ).pipe(
            switchMap((docs: DocumentData[]) =>
                from(
                    Promise.all(
                        docs.map(async (doc: DocumentData) => {
                            const insuranceRetrieval = doc as InsuranceRetrievalEntity;
                            let insurerData: InsurerEntity | null = null;

                            if (insuranceRetrieval.insurer) {
                                insurerData = await this.resolveInsurerReference(
                                    insuranceRetrieval.insurer
                                );
                            }

                            return {
                                ...insuranceRetrieval,
                                insurer: insurerData,
                            } as InsuranceRetrieval;
                        })
                    )
                )
            )
        );
    }

    private async resolveInsurerReference(
        ref: DocumentReference
    ): Promise<InsurerEntity | null> {
        const snapshot = await getDoc(ref);
        return snapshot.exists() ? (snapshot.data() as InsurerEntity) : null;
    }

    // Maps InsuranceRetrieval to InsuranceRetrievalEntity for saving to Firestore
    private mapToEntity(data: Partial<InsuranceRetrieval>): InsuranceRetrievalEntity {
        return {
            ...data,
            insurer: doc(this.firestore, 'insurers', data.insurer.id),
            comments: data.comments.map((comment) => ({
                ...comment,
                createdAt: new Date(),
                updatedAt: new Date(),
            })),
            createdAt: new Date(),
            updatedAt: new Date(),
        } as InsuranceRetrievalEntity;
    }

    getById(id: string): Observable<InsuranceRetrieval | undefined> {
        const docRef = doc(this.firestore, 'insurance-retrievals', id);
        return from(getDoc(docRef)).pipe(
            switchMap(async (docSnapshot) => {
                if (!docSnapshot.exists()) {
                    console.log('No such document!');
                    return undefined;
                } else {
                    const data = docSnapshot.data() as InsuranceRetrievalEntity;
                    let insurerData: InsurerEntity | null = null;

                    if (data.insurer) {
                        insurerData = await this.resolveInsurerReference(data.insurer);
                    }

                    return {
                        ...data,
                        insurer: insurerData,
                        id: docSnapshot.id,
                    } as InsuranceRetrieval;
                }
            })
        );
    }

    create(data: Partial<InsuranceRetrieval>, insurerName = ''): Promise<void> {
        const docRef = collection(this.firestore, 'insurance-retrievals');
        const entity: InsuranceRetrievalEntity = this.mapToEntity(data);

        return addDoc(docRef, { ...entity })
            .then(async (documentReference) => {
                console.log('Document written with ID: ', documentReference.id);
                const recordOwnerId = data.user.uid;
                const collectionType: CollectionType = 'insurance-retrievals';

                // Create an event for the creation of this insurance retrieval
                await this.eventService
                    .createCreateEvent(
                        documentReference.id,
                        recordOwnerId,
                        collectionType,
                        insurerName
                    )
                    .toPromise();
                console.log('Event created for new insurance retrieval');
            })
            .catch((error) => {
                console.error('Error adding document: ', error);
                throw new Error('Error adding document');
            });
    }

    // Method to update the policy request's
    updatePolicyRequest(
        policyRequestId: string,
        updatedProperty: Partial<InsuranceRetrieval>
    ): Promise<void> {
        const policyDocRef = doc(this.firestore, 'insurance-retrievals', policyRequestId);
        return getDoc(policyDocRef).then((snapshot) => {
            if (!snapshot.exists()) {
                throw new Error('Document does not exist!');
            }
            const data = { ...snapshot.data() } as InsuranceRetrievalEntity;

            // update prev and new data `insurer` value to `null` to skip the expand/populate insurer reference by id
            // `prevData` and `newData` use for event changes comparison
            const prevData = { ...data, insurer: null };
            const newData = { ...data, ...updatedProperty, insurer: null };
            // `updatedData` data to save into the database
            const updatedData = { ...data, ...updatedProperty, updatedAt: new Date() };

            return updateDoc(policyDocRef, updatedData).then(() => {
                return this.eventService
                    .createUpdateEvent(
                        prevData,
                        newData,
                        policyRequestId,
                        data.user.uid,
                        'insurance-retrievals'
                    )
                    .toPromise();
            });
        });
    }

    // Adds a new comment to a policy request
    addComment(policyRequestId: string, comment: Partial<Comment>): Promise<void> {
        const policyDocRef = doc(this.firestore, 'insurance-retrievals', policyRequestId);
        // Explicitly state that you're returning a Promise<void>
        return runTransaction(this.firestore, async (transaction) => {
            const policyDoc = await transaction.get(policyDocRef);
            if (!policyDoc.exists()) throw new Error('Document does not exist!');
            const policyData = policyDoc.data() as InsuranceRetrievalEntity;
            const newComment = {
                ...comment,
                createdAt: new Date(),
                updatedAt: new Date(),
            };
            const updatedComments = [...policyData.comments, newComment];
            transaction.update(policyDocRef, { comments: updatedComments });

            // After transaction is successful, create a comment event
            const commentLog: CommentLog = {
                type: 'created',
                id: newComment.id,
                description: newComment.description,
            };

            await this.eventService
                .createCommentEvent(
                    policyRequestId,
                    policyData.user.uid,
                    'insurance-retrievals',
                    commentLog
                )
                .toPromise();

            return;
        });
    }

    // Updates an existing comment in a policy request
    updateComment(
        policyRequestId: string,
        commentId: string,
        updatedComment: Partial<Comment>
    ): Promise<void> {
        const policyDocRef = doc(this.firestore, 'insurance-retrievals', policyRequestId);
        return runTransaction(this.firestore, async (transaction) => {
            const policyDoc = await transaction.get(policyDocRef);
            if (!policyDoc.exists()) throw new Error('Document does not exist!');
            const policyData = policyDoc.data() as InsuranceRetrievalEntity;
            const activityIndex = policyData.comments.findIndex(
                (a) => a.id === commentId
            );

            if (activityIndex === -1) throw new Error('Comment not found!');

            const oldComment = policyData.comments[activityIndex];
            const comments = [...policyData.comments];
            comments[activityIndex] = {
                ...oldComment,
                ...updatedComment,
                updatedAt: new Date(),
            };

            transaction.update(policyDocRef, { comments });

            // After transaction is successful, create a comment event
            const commentLog: CommentLog = {
                type: 'updated',
                id: commentId,
                description: comments[activityIndex].description,
                prevDescription: oldComment.description,
            };

            await this.eventService
                .createCommentEvent(
                    policyRequestId,
                    policyData.user.uid,
                    'insurance-retrievals',
                    commentLog
                )
                .toPromise();

            return;
        });
    }

    // Deletes an existing comment from a policy request
    deleteComment(policyRequestId: string, commentId: string): Promise<void> {
        const policyDocRef = doc(this.firestore, 'insurance-retrievals', policyRequestId);
        return runTransaction(this.firestore, async (transaction) => {
            const policyDoc = await transaction.get(policyDocRef);
            if (!policyDoc.exists()) throw new Error('Document does not exist!');
            const policyData = policyDoc.data() as InsuranceRetrievalEntity;

            const commentToDelete = policyData.comments.find(
                (comment) => comment.id === commentId
            );
            if (!commentToDelete) throw new Error('Comment not found!');

            const updatedComments = policyData.comments.filter(
                (comment) => comment.id !== commentId
            );
            transaction.update(policyDocRef, { comments: updatedComments });

            // After transaction is successful, create a comment event
            const commentLog: CommentLog = {
                type: 'deleted',
                id: commentId,
                description: commentToDelete.description,
            };

            await this.eventService
                .createCommentEvent(
                    policyRequestId,
                    policyData.user.uid,
                    'insurance-retrievals',
                    commentLog
                )
                .toPromise();

            return;
        });
    }

    // Delete an insurance retrieval document
    delete(id: string, ownerId: string): Observable<void> {
        const docRef = doc(this.collectionRef, id);
        return from(deleteDoc(docRef)).pipe(
            switchMap(() => {
                return this.eventService.createDeleteEvent(
                    id,
                    ownerId,
                    'insurance-retrievals'
                );
            }),
            mapTo(undefined)
        );
    }
}
