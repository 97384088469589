import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { UsersnapComponent } from './mock-api/apps/usersnap/usersnap.component';
import { TranslocoModule } from '@ngneat/transloco';
import { AvailableLangs, TranslocoService } from '@ngneat/transloco';
import { UserService } from './core/user/user.service';
import { UserPreferencesService } from './core/user/user-preferences.service';
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [RouterOutlet, UsersnapComponent, TranslocoModule,],
})
export class AppComponent implements OnInit {
  activeLangDisplay: string;
  defaultLanguage: string;
  userId: string;
  savedLanguage: string;

  constructor(
    private _translocoService: TranslocoService,
    private userService: UserService,
    private userPreferencesService: UserPreferencesService
  ) {}

  ngOnInit(): void {
    const browserLanguage = navigator.language || 'en-US';
    this.defaultLanguage = browserLanguage.split('-')[0];

    // Subscribe to user changes
    this.userService.user$.subscribe(user => {
        if (user) {
          this.userId = user.id;
          // Retrieve user preferences using the user's ID
          this.userPreferencesService.getUserPreferences(this.userId).subscribe(preferences => {
            if (preferences && preferences.languagePreference) {
                this.savedLanguage = preferences.languagePreference;
            } else {
              // Fallback to default language
              this.savedLanguage = this.defaultLanguage;
            }

            // Set the active lang after retrieving the language preference
            this._translocoService.setActiveLang(this.savedLanguage);
          });
        }
      });
}


 
}
