import { Injectable, inject } from '@angular/core';
import { PeopleAndObjects } from 'app/models/people-objects';
import { SwissLuxonDateAdapter } from './swiss-luxondateadapter';

@Injectable({
    providedIn: 'root'
})
/**
 * Service for formatting person data.
 */
export class PersonFormatterService {

    private swissLuxonDateAdapter = inject(SwissLuxonDateAdapter);
    
    constructor() { }

    /**
     * Formats the name of a person.
     * If the person type is not "PERSON", returns "-".
     * Otherwise, returns the formatted name in the format "lastName, firstName".
     * 
     * @param person - The person object to format the name for.
     * @returns The formatted name of the person.
     */
    name(person: PeopleAndObjects): string {

        if (person === null || person === undefined) { return ""; }
        if (!(person.type === "PERSON")) { return ""; }
        
        return `${person.lastName}, ${person.firstName}`;
    }

    /**
     * Formats the name of a person along with their birthdate.
     * 
     * @param person - The person object to format the name for.
     * @returns The formatted name with birthdate.
     */
    nameWithBirthdate(person: PeopleAndObjects): string {

        if (person === null || person === undefined) { return ""; }
        if (!(person.type === "PERSON")) { return ""; }

        return `${this.name(person)} ${this.swissLuxonDateAdapter.toSwissFormat(person.birthday)}`;
    }
}

