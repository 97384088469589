<div class="flex flex-col flex-auto relative w-full h-full p-4">
    <div class="flex justify-between items-center mb-4">
        <h3 class="text-2xl font-medium">{{ headerTitle }}</h3>
        <button (click)="onClickClose()" [matTooltip]="'Close Drawer'">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div class="dropzone" dropzone (dropped)="onFilesDropped($event)" (click)="fileInput.nativeElement.click()">
        <div *ngIf="!uploading; else uploadingMessage" class="flex flex-col justify-center items-center">
            <h3 class="mb-5 px-2 text-center">
                {{ 'addInsurance.drag' | transloco }}<br />(PDF, JPG, PNG)
            </h3>
            <button mat-flat-button type="button" class="bg-gray-200">{{ 'addInsurance.orClick' | transloco }}</button>
        </div>

        <ng-template #uploadingMessage>
            <div class="flex flex-wrap p-4 gap-4">
                <div *ngFor="let file of files" class="flex-none" style="width: 8rem;">
                    <div class="flex flex-col items-center mb-1">
                        <div class="flex items-center">
                            <span class="inline-block text-xl">📄</span>
                            <span class="truncate w-full max-w-24" matTooltip="{{ file.name }}">
                                {{ file.name }}
                            </span>
                        </div>
                        <div class="w-full">
                            <span class="text-sm font-medium">{{ file.progress | number:'1.0-0' }}%</span>
                            <mat-progress-bar mode="determinate" [value]="file.progress"></mat-progress-bar>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>

        <div #fileBox class="file" *ngIf="!uploading">
            <label class="file-label">
                <input hidden class="file-input" #fileInput type="file" [multiple]="multiple"
                    (change)="startUpload($event.target.files)" accept="application/pdf, image/jpeg, image/png" />
            </label>
        </div>
    </div>

    <ng-container *ngIf="isUploading">
        <div class="absolute inset-0 bg-gray-500 bg-opacity-50 flex justify-center items-center rounded">
            <mat-progress-spinner [diameter]="40" mode="indeterminate"></mat-progress-spinner>
        </div>
    </ng-container>
</div>