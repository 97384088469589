<ng-container *ngIf="!toggleInsurerForm; else displayForm;">
  <div class="flex flex-col justify-center items-center">
    <mat-form-field appearance="outline" class="w-full">
      <mat-label>{{ label }}</mat-label>
      <mat-select [(ngModel)]="selectedInsurer" (ngModelChange)="onSelect($event)" [compareWith]="compareWithFunction">
        <mat-option [value]="0">+ Add insurer</mat-option>
        <mat-option *ngFor="let insurer of (insurers$ | async); trackBy: trackById" [value]="insurer"
          [disabled]="selectedId === insurer.id">
          <ng-container *ngIf="insurer.languages[currentLanguage] as insurerLang">
            {{ insurerLang.name }}
          </ng-container>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div *ngIf="!onChangeOnly" class="w-full flex justify-end items-center mt-4 px-4">
      <button mat-raised-button color="primary" (click)="onSave()">
        {{ btnLabel }}
      </button>
    </div>
  </div>
</ng-container>
<ng-template #displayForm>
    <insurer-details [insurer]="{ id: '', languages: {} }" (closeDetails)="closeInsurer($event)"></insurer-details>
</ng-template>