import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { of, switchMap } from 'rxjs';

export const AdminhGuard: CanActivateFn | CanActivateChildFn = (route, state) =>
{
    const router: Router = inject(Router);
    const auth: AuthService = inject(AuthService);    
    const isAdmin: boolean = auth.isAdmin();
    const isSuperUser: boolean = auth.isSuperUser();
    if(!isAdmin && !isSuperUser) router.navigate(['/'])
    
    return true
};
