import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class DeviceService {
    constructor() {}

    isMobile(): boolean {
        const userAgent = navigator.userAgent || navigator.vendor;

        // Regular expressions for mobile detection
        return /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
            userAgent.toLowerCase()
        );
    }
}
