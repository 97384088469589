<ng-select 
    #ngSelectProduct
    matInput
    appearance="outline"
    [items]="products$ | async" 
    bindLabel="name" 
    bindValue="id" 
    [formControl]="selectedProductControl"
    [markFirst]="false"
    [editableSearchTerm]="true"
    (change)="onProductSelected($event)"
>
</ng-select>
