import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { DatePipe, NgClass, NgFor, NgIf, NgTemplateOutlet } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewChild,
    ViewContainerRef,
    ViewEncapsulation,
} from '@angular/core';
import { User } from '@angular/fire/auth';
import { MatButton, MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';

import { EventMessagePipe } from 'app/common//pipes/event-message.pipe';
import { EventService } from 'app/common/services/event.service';
import { Event, LangCode } from 'app/models/db/event.model';
import { ToDatePipe } from 'app/common/pipes/to-date.pipe';
import { AuthService } from 'app/core/auth/auth.service';

interface ExtendedEvent extends Event {
    expanded: boolean;
    routerLink: string[];
    queryParams: object;
}

@Component({
    selector: 'notifications',
    templateUrl: './notifications.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'notifications',
    standalone: true,
    imports: [
        NgIf,
        NgFor,
        NgClass,
        DatePipe,
        RouterLink,
        ToDatePipe,
        MatIconModule,
        MatButtonModule,
        MatTooltipModule,
        NgTemplateOutlet,
        EventMessagePipe,
    ],
})
export class NotificationsComponent implements OnInit, OnDestroy {
    @ViewChild('notificationsOrigin') private _notificationsOrigin: MatButton;
    @ViewChild('notificationsPanel') private _notificationsPanel: TemplateRef<any>;
    private _overlayRef: OverlayRef;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    private authUser: User;
    unreadCount: number = 0;
    events: ExtendedEvent[] = [];

    /**
     * Constructor
     */
    constructor(
        private _overlay: Overlay,
        private _changeDetectorRef: ChangeDetectorRef,
        private _viewContainerRef: ViewContainerRef,
        private eventService: EventService,
        private _authService: AuthService
    ) {
        this._authService.user.pipe(takeUntil(this._unsubscribeAll)).subscribe((user) => {
            this.authUser = user;
            if (this.authUser) {
                this.initializeEventNotifications(this.authUser.uid);
            }
        });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {}

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();

        // Dispose the overlay
        if (this._overlayRef) {
            this._overlayRef.dispose();
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    initializeEventNotifications(userUid: string) {
        // Subscribe to events changes
        this.eventService.listenForEventNotifications(userUid).subscribe((events) => {
            this.events = events.map((event: ExtendedEvent) => {
                let routerLink = [];
                let queryParams = {};

                switch (event.actionType) {
                    case 'request-to-extraction':
                        routerLink = ['/policies'];
                        break;
                    case 'insurance-published':
                    case 'insurance-updated':
                        routerLink = ['/policies'];
                        queryParams = { insurance: event.recordId };
                        break;
                    case 'insurance-unpublished':
                    case 'insurance-deleted':
                        routerLink = ['/policies'];
                        break;
                    case 'beneficiary-added':
                        routerLink = ['/policies'];
                        queryParams = { extraction: event.recordId };
                        break;
                    case 'beneficiary-deleted':
                        routerLink = ['/policies'];
                        break;
                    default:
                        break;
                }

                return { ...event, routerLink, queryParams, expanded: false };
            });

            // Calculate the unread count
            this._calculateUnreadCount();
            // Mark for check
            this._changeDetectorRef.markForCheck();
        });
    }

    expandNotification(index: number): void {
        this.events[index] = {
            ...this.events[index],
            expanded: !this.events[index].expanded,
        };
        this._changeDetectorRef.markForCheck();
    }

    /**
     * Open the events panel
     */
    openPanel(): void {
        // Return if the events panel or its origin is not defined
        if (!this._notificationsPanel || !this._notificationsOrigin) {
            return;
        }

        // Create the overlay if it doesn't exist
        if (!this._overlayRef) {
            this._createOverlay();
        }

        // Attach the portal to the overlay
        this._overlayRef.attach(
            new TemplatePortal(this._notificationsPanel, this._viewContainerRef)
        );
    }

    /**
     * Close the events panel
     */
    closePanel(): void {
        this._overlayRef.detach();
    }

    get eventIds(): string[] {
        return this.events.map((event: ExtendedEvent) => event.id as string);
    }

    /**
     * Mark all events as read
     */
    markAllAsRead(): void {
        // Mark all as read
        const ids = this.eventIds;
        this.eventService.markEventsReadOrUnread(ids, true).subscribe({
            next: () => {},
            error: (error) => {
                // Handle error
                console.error('Failed to mark all events as read', error);
            },
        });
    }

    /**
     * Toggle read status of the given event
     */
    toggleRead(event: ExtendedEvent): void {
        const read: boolean = !event?.read;
        this.eventService.markEventsReadOrUnread([event.id], read).subscribe({
            next: () => {},
            error: (error) => {
                // Handle error
                console.error(
                    `Failed to mark event as ${!read ? 'unread' : 'read'}`,
                    error
                );
            },
        });
    }

    /**
     * Delete the given event
     */
    delete(event: ExtendedEvent): void {
        // Delete the event
        this.eventService.removeEventsFromNotifications([event.id]).subscribe({
            next: () => {},
            error: (error) => {
                // Handle error
                console.error('Failed to remove event from notification list', error);
            },
        });
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create the overlay
     */
    private _createOverlay(): void {
        // Create the overlay
        this._overlayRef = this._overlay.create({
            hasBackdrop: true,
            backdropClass: 'fuse-backdrop-on-mobile',
            scrollStrategy: this._overlay.scrollStrategies.block(),
            positionStrategy: this._overlay
                .position()
                .flexibleConnectedTo(this._notificationsOrigin._elementRef.nativeElement)
                .withLockedPosition(true)
                .withPush(true)
                .withPositions([
                    {
                        originX: 'start',
                        originY: 'bottom',
                        overlayX: 'start',
                        overlayY: 'top',
                    },
                    {
                        originX: 'start',
                        originY: 'top',
                        overlayX: 'start',
                        overlayY: 'bottom',
                    },
                    {
                        originX: 'end',
                        originY: 'bottom',
                        overlayX: 'end',
                        overlayY: 'top',
                    },
                    {
                        originX: 'end',
                        originY: 'top',
                        overlayX: 'end',
                        overlayY: 'bottom',
                    },
                ]),
        });

        // Detach the overlay from the portal on backdrop click
        this._overlayRef.backdropClick().subscribe(() => {
            this._overlayRef.detach();
        });
    }

    /**
     * Calculate the unread count
     *
     * @private
     */
    private _calculateUnreadCount(): void {
        let count = 0;

        if (this.events && this.events.length) {
            count = this.events.filter((event) => !event.read).length;
        }

        this.unreadCount = count;
    }
}
