import { BooleanInput } from '@angular/cdk/coercion';
import { NgClass, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { Router, RouterModule } from '@angular/router';
import { UserService } from 'app/core/user/user.service';
import { User } from 'app/core/user/user.types';
import { Subject, takeUntil } from 'rxjs';
import { SharedSwitcherService } from 'app/common/services/shared-switcher.service';
import { AuthService } from 'app/core/auth/auth.service';
import { inject } from '@angular/core';
import { NavigationService } from 'app/core/navigation/navigation.service';

@Component({
    selector: 'user',
    templateUrl: './user.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs       : 'user',
    standalone     : true,
    imports        : [MatButtonModule, MatMenuModule, NgIf, MatIconModule, NgClass, MatDividerModule, RouterModule],
})
export class UserComponent implements OnInit, OnDestroy {
    /* eslint-disable @typescript-eslint/naming-convention */
    static ngAcceptInputType_showAvatar: BooleanInput;
    /* eslint-enable @typescript-eslint/naming-convention */
    @Input() showAvatar: boolean = true;
    user: User;

    private _unsubscribeAll: Subject<any> = new Subject<any>();
    auth: AuthService = inject(AuthService);
    isAdminDisplay: boolean = true;
    isAdmin: boolean = true;
    isSuperUser: boolean = false;

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _router: Router,
        private _userService: UserService,
        private sharedSwitcherService: SharedSwitcherService,
        private _navigationService: NavigationService,
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to user changes
        this._userService.user$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((user: User) => {
                this.user = user;

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });

        this.isAdmin = this.auth.isAdmin();
        this.isSuperUser = this.auth.isSuperUser();
        this.isAdminDisplay = this.sharedSwitcherService.getSwitcherStateLocal();
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Update the user status
     *
     * @param status
     */
    updateUserStatus(status: string): void {
        // Return if user is not available
        if (!this.user) {
            return;
        }

        // Update the user
        this._userService.update({
            ...this.user,
            status,
        }).subscribe();
    }

    /**
     * Sign out
     */
    signOut(): void {
        this._router.navigate(['/sign-out']);
    }

    async switchToAdmin() {
        // Switch to admin display (does not affect authentication)
        this.isAdminDisplay = true;
        try {
            this._navigationService.navigation$
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe((navigation) => {
                    this._navigationService.setAdminMenu(navigation);
                    this._router.navigate(['admin/tasks']);
                });
            this.sharedSwitcherService.setSwitcherStateLocal(true);
        }
        catch (error) {
            console.error('Error occured:', error);
        } 
    }

    async switchToUser() {
        // Switch to user display (does not affect authentication)
        this.isAdminDisplay = false;
        try {
            this._navigationService.navigation$
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe((navigation) => {
                    this._navigationService.setAdminMenu(navigation);
                    this._router.navigate(['/dashboard']);
                });
                this.sharedSwitcherService.setSwitcherStateLocal(false);
        } catch (error) {
            console.error('Error occured:', error);
        } 
    }
}
