import { CommonModule } from '@angular/common';
import {
    Component,
    ChangeDetectionStrategy,
    ViewEncapsulation,
    ChangeDetectorRef,
    EventEmitter,
    ElementRef,
    ViewChild,
    OnDestroy,
    OnInit,
    Output,
    Input,
} from '@angular/core';
import { User } from '@angular/fire/auth';
import { Firestore } from '@angular/fire/firestore';
import {
    Storage,
    StorageReference,
    getDownloadURL,
    getStorage,
    ref,
    uploadBytesResumable,
} from '@angular/fire/storage';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { TranslocoService, TranslocoModule } from '@ngneat/transloco';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DropZoneDirective } from 'app/common/directives/dropzone.directive';
import { Document } from 'app/models/db/insurance-retrieval.model';
import { AuthService } from 'app/core/auth/auth.service';

type ViewState = 'default' | 'selectInsurer' | 'uploadFile' | 'takePicture';

@Component({
    selector: 'app-upload',
    templateUrl: './upload.component.html',
    styleUrls: ['./upload.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        CommonModule,
        MatIconModule,
        MatButtonModule,
        MatTooltipModule,
        DropZoneDirective,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        TranslocoModule,
    ],
})
export class UploadComponent implements OnInit, OnDestroy {
    @ViewChild('fileInput', { static: false }) fileInput: ElementRef;
    @Input() multiple: boolean = false;
    @Input() headerTitle: string;
    @Input() basePath: string;
    @Output() fileUploaded = new EventEmitter<Document | Document[]>();
    @Output() onClose = new EventEmitter<void>();
    private storage: Storage = getStorage();
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    private user: User;
    files: any[] = [];
    uploading: boolean = false;
    isProcessComplete: boolean = false;

    constructor(
        private _firestore: Firestore,
        private _authService: AuthService,
        private _changeDetectorRef: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        this._authService.user
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((user: User) => {
                this.user = user;
            });
    }

    startUpload(event: FileList): Promise<void> {
        this.uploading = true;
        this.files = [];
        const uploadedFiles: Document[] = [];

        const uploadTasks = Array.from(event).map((file) => {
            const uniqueFileName = `${this.user.uid}_${Date.now()}_${Math.random()
                .toString(36)
                .substring(2, 15)}_${file.name}`;
            const path = `${this.basePath}/${uniqueFileName}`;
            const storageRef: StorageReference = ref(this.storage, path);

            return new Promise<void>((resolve, reject) => {
                const task = uploadBytesResumable(storageRef, file);
                const fileUpload = {
                    name: file.name,
                    progress: 0,
                    task: task,
                    completed: false,
                    path,
                };
                this.files.push(fileUpload);

                task.on(
                    'state_changed',
                    (snapshot) => {
                        const progress =
                            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        const index = this.files.findIndex((f) => f.name === file.name);
                        if (index !== -1) {
                            this.files[index].progress = progress;
                            this._changeDetectorRef.detectChanges();
                        }
                    },
                    (error) => {
                        console.error('Upload error:', error);
                        reject(error);
                    },
                    async () => {
                        const url = await getDownloadURL(task.snapshot.ref);
                        const index = this.files.findIndex((f) => f.name === file.name);
                        if (index !== -1) {
                            this.files[index].completed = true;
                            uploadedFiles.push({
                                path: url,
                                filename: this.files[index].name,
                            });
                            this._changeDetectorRef.detectChanges();
                        }
                        resolve();
                    }
                );
            });
        });

        return Promise.all(uploadTasks).then(() => {
            const uploadedDocs: Document | Document[] = !this.multiple
                ? uploadedFiles[0]
                : uploadedFiles;
            this.fileUploaded.emit(uploadedDocs);
            this.isProcessComplete = true;
            this.uploading = false;
            this._changeDetectorRef.detectChanges();
            this.onClickClose();
        });
    }

    async onFilesDropped(event: FileList): Promise<void> {
        try {
            await this.startUpload(event);
            this.isProcessComplete = true;
            this._changeDetectorRef.detectChanges();
        } catch (error) {
            console.error('Error during file upload:', error);
        }
    }

    get isUploading() {
        return this.files.some((file) => !file.completed);
    }

    onClickClose(): void {
        this.onClose.emit();
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }
}
