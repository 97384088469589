import {
    Component,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    ViewEncapsulation,
    ViewChild,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDrawer, MatSidenavModule } from '@angular/material/sidenav';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { ActivatedRoute, Router } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { fuseAnimations } from '@fuse/animations';
import cloneDeep from 'lodash-es/cloneDeep';
import { Subscription } from 'rxjs';

import { PolicyRequestFormComponent } from 'app/common/components/policy-request-form/policy-request-form.component';
import { DocumentUploadService } from 'app/modules/admin/apps/document-uploads/document-upload.service';
import { InsuranceRetrievalService } from 'app/common/services/insurance-retrieval.service';
import { InsuranceRetrieval, Document } from 'app/models/db/insurance-retrieval.model';
import { UploadComponent } from 'app/common/components/upload/upload.component';
import { Event, CollectionType, LangCode } from 'app/models/db/event.model';
import { EventMessagePipe } from 'app/common/pipes/event-message.pipe';
import { DocumentUpload } from 'app/models/document-uploads.model';
import { EventService } from 'app/common/services/event.service';
import { StripHtmlPipe } from 'app/common/pipes/strip-html.pipe';
import { ToDatePipe } from 'app/common/pipes/to-date.pipe';
import { generateTaskID } from 'app/core/helpers/helper';

@Component({
    selector: 'app-policy-request-task',
    templateUrl: './policy-request-task.component.html',
    styleUrls: ['./policy-request-task.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: fuseAnimations,
    standalone: true,
    imports: [
        CommonModule,
        MatIconModule,
        MatButtonModule,
        TranslocoModule,
        MatTooltipModule,
        MatSidenavModule,
        PolicyRequestFormComponent,
        MatProgressBarModule,
        EventMessagePipe,
        UploadComponent,
        StripHtmlPipe,
        ToDatePipe,
    ],
})
export class PolicyRequestTaskComponent implements OnInit, OnDestroy {
    private subscription = new Subscription();
    private languageSubscription: Subscription;
    @ViewChild('matDrawer', { static: false }) matDrawer: MatDrawer;
    matDrawerContext: string = '';
    insuranceRetrieval?: InsuranceRetrieval;
    events: Event[] = [];
    currentLanguage: LangCode;
    eventsLoading = false;
    isLoading = false;

    constructor(
        private router: Router,
        private eventService: EventService,
        private activatedRoute: ActivatedRoute,
        private translocoService: TranslocoService,
        private changeDetectorRef: ChangeDetectorRef,
        private documentUploadService: DocumentUploadService,
        private insuranceRetrievalService: InsuranceRetrievalService
    ) {
        this.languageSubscription = this.translocoService.langChanges$.subscribe(
            (lang: string) => {
                this.currentLanguage = lang.toUpperCase() as LangCode;
            }
        );
    }

    ngOnInit(): void {
        const id = this.activatedRoute.snapshot.paramMap.get('id');
        if (id) {
            this.isLoading = true;
            const dataSubscription = this.insuranceRetrievalService
                .getById(id)
                .subscribe({
                    next: (data) => {
                        this.insuranceRetrieval = data;
                        this.isLoading = false;
                        this.fetchEvents(data.id, data.user.uid);
                        this.changeDetectorRef.markForCheck();
                    },
                    error: () => {
                        this.isLoading = false;
                        // Handle error
                    },
                });
            this.subscription.add(dataSubscription);
        }
    }

    private fetchEvents(recordId: string, ownerId: string): void {
        this.eventsLoading = true;
        const eventsSubscription = this.eventService
            .getEventsByRecordIdAndOwnerId(recordId, ownerId)
            .subscribe({
                next: (events) => {
                    this.events = events.map((event) => ({
                        ...event,
                        language: this.currentLanguage,
                    }));
                    this.eventsLoading = false;
                    this.changeDetectorRef.markForCheck();
                },
                error: () => {
                    this.eventsLoading = false;
                    // Handle error
                },
            });
        this.subscription.add(eventsSubscription);
    }

    get unpublishEventIds(): string[] {
        return this.events
            .filter((event: Event) => !event.status || event.status === 'unpublished')
            .map((event: Event) => event.id as string);
    }

    updateSelectedEventsStatus(
        ids: string[],
        newStatus: 'unpublished' | 'published'
    ): void {
        this.eventsLoading = true;
        this.eventService.updateEventStatus(ids, newStatus).subscribe({
            next: () => {
                this.eventsLoading = false;
            },
            error: (error) => {
                // Handle error
                console.error('Failed to update event status', error);
                this.eventsLoading = false;
            },
        });
    }

    publishAllEvent() {
        const unpublishIds: string[] = this.unpublishEventIds;
        this.updateSelectedEventsStatus(unpublishIds, 'published');
    }

    updateEventStatus(event: Event) {
        let newStatus: 'unpublished' | 'published' = event?.status || 'unpublished';
        if (newStatus === 'unpublished') {
            newStatus = 'published';
        } else {
            newStatus = 'unpublished';
        }
        this.updateSelectedEventsStatus([event.id], newStatus);
    }

    async updatePolicyRequestDocument(documents: Document[] = []) {
        const updateDocs = [...(this.insuranceRetrieval?.documents || []), ...documents];
        this.isLoading = true;
        try {
            await this.insuranceRetrievalService.updatePolicyRequest(
                this.insuranceRetrieval.id,
                { documents: updateDocs }
            );
            // Update the local state
            this.insuranceRetrieval = cloneDeep({
                ...this.insuranceRetrieval,
                documents: updateDocs,
            });
        } catch (error) {
            console.error('Error updating policy:', error);
        } finally {
            this.isLoading = false;
        }
    }

    deleteEventById(eventId: string): void {
        this.eventsLoading = true;
        const deleteEventSubscription = this.eventService.deleteEvent(eventId).subscribe({
            next: (events) => {
                this.eventsLoading = false;
                this.changeDetectorRef.markForCheck();
            },
            error: () => {
                this.eventsLoading = false;
                // Handle error
            },
        });
        this.subscription.add(deleteEventSubscription);
    }

    deletePolicy(): void {
        this.isLoading = true;
        const deletePolicyRequestSubscription = this.insuranceRetrievalService
            .delete(this.insuranceRetrieval.id, this.insuranceRetrieval.user.uid)
            .subscribe({
                next: (events) => {
                    this.isLoading = false;
                    this.navigateToTasks();
                },
                error: () => {
                    this.isLoading = false;
                    // Handle error
                },
            });
        this.subscription.add(deletePolicyRequestSubscription);
    }

    async createExtractionFromDocuments(): Promise<string[]> {
        const { user, documents, insurer } = this.insuranceRetrieval;
        const uploadPromises = documents.map(async (doc) => {
            const data: DocumentUpload = {
                taskId: generateTaskID(),
                path: doc.path,
                filename: doc.filename,
                status: 'pending',
                user,
                comments: [],
                urgency: 'low',
                assignee: null,
                beneficiaries: [],
                createdAt: new Date(),
                updatedAt: new Date(),
            };

            try {
                const insurerName = insurer?.languages[this.currentLanguage].name
                // The create method now returns the document ID
                return await this.documentUploadService.create(data, insurerName);
            } catch (error) {
                console.error('Error uploading document:', error);
                // Return null or throw error depending on how you want to handle failures
                return null; // Opting to return null for this example
            }
        });

        // Await all promises and filter out any nulls (failed uploads)
        const documentIds = (await Promise.all(uploadPromises)).filter(
            (id) => id !== null
        );

        return documentIds;
    }

    async convertRequestToExtraction(): Promise<void> {
        this.isLoading = true;
        try {
            await this.insuranceRetrievalService.updatePolicyRequest(
                this.insuranceRetrieval.id,
                { status: 'completed' }
            );
            const extractionIds: string[] = await this.createExtractionFromDocuments();

            // Assuming `this.events` exists and is an array of events you want to update and log
            // Clone `this.events` and update `recordId` for each cloned event using `extractionIds`
            const eventsToLog = extractionIds
                .map((extractionId) => {
                    return this.events.map(({ id, ...otherEventProps }: Event) => ({
                        ...otherEventProps,
                        recordId: extractionId,
                        collectionType: 'policy-extractions' as CollectionType,
                    }));
                })
                .flat(); // Flatten the array of arrays into a single array of events

            // Call createMultipleEventLogs with the updated events
            await this.eventService.createMultipleEventLogs(eventsToLog).toPromise();
            // Call createEvent log, create conversation event log.
            await this.eventService.createEvent(
                this.insuranceRetrieval.id,
                this.insuranceRetrieval.user.uid,
                'insurance-retrievals',
                'request-to-extraction',
                this.insuranceRetrieval?.insurer?.languages[this.currentLanguage].name
            );
        } catch (error) {
            console.error('Error converting policy request to policy extraction:', error);
        } finally {
            this.isLoading = false;
            this.navigateToTasks();
        }
    }

    async closeRequest() {
        this.isLoading = true;
        try {
            await this.insuranceRetrievalService.updatePolicyRequest(
                this.insuranceRetrieval.id,
                { status: 'closed' }
            );
        } catch (error) {
            this.isLoading = false;
            console.error('Error updating policy status:', error);
        } finally {
            this.isLoading = false;
            this.navigateToTasks();
        }
    }

    navigateToTasks(): void {
        this.router.navigate(['admin/tasks']);
    }

    openDrawer(drawerType: string): void {
        if (this.matDrawer) {
            this.matDrawer.open();
            this.matDrawerContext = drawerType;
        } else {
            console.warn('Drawer is not available');
        }
    }

    closeDrawer(): void {
        if (this.matDrawer) {
            this.matDrawer.close();
            this.matDrawerContext = '';
        } else {
            console.warn('Drawer is not available');
        }
    }

    get isRequestCloseOrCompleted(): boolean {
        return ['completed', 'closed'].includes(this.insuranceRetrieval?.status);
    }

    get isRequestClosed(): boolean {
        return this.insuranceRetrieval?.status === 'closed';
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
        if (this.languageSubscription) {
            this.languageSubscription.unsubscribe();
        }
    }
}
