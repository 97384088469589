import {
    Component,
    ViewChild,
    EventEmitter,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Input,
    OnInit,
    Output,
    OnChanges,
    SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { ReactiveFormsModule, FormControl } from '@angular/forms';
import { NgSelectModule, NgSelectComponent } from '@ng-select/ng-select';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';

import { Product } from '../../../models/product.model';
import { ProductService } from '../../services/product.service';
import { TranslocoService, TranslocoModule} from '@ngneat/transloco';
import { ProductNameGetterPipe } from 'app/common/pipes/get-product-name.pipe';

@Component({
    selector: 'select-product',
    templateUrl: './select-product.component.html',
    styleUrls: ['./select-product.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        CommonModule,
        MatIconModule,
        NgSelectModule,
        ReactiveFormsModule,
        TranslocoModule,
        ProductNameGetterPipe,
    ],
    providers: [ProductNameGetterPipe],
})
export class SelectProductComponent implements OnInit, OnChanges {
    @ViewChild('ngSelectProduct') ngSelectProduct: NgSelectComponent;
    @Input() insuranceType: string;
    @Input() insurerId: string;
    @Input() insurerName: string;
    @Input() productId: string | null = null;
    @Output() selectedProduct = new EventEmitter<Product>();

    products$ = new BehaviorSubject<Product[]>([]);
    selectedProductControl = new FormControl();
    selectedLanguage: string;
    private ngUnsubscribe = new Subject<void>();

    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private productService: ProductService,
        private translocoService: TranslocoService,
        private productNameGetter: ProductNameGetterPipe
    ) {}

    ngOnInit() {
        this.translocoService.langChanges$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((lang) => {
                // Update the selected language
                this.selectedLanguage = lang;
                this.fetchProducts();

                // Manually trigger change detection
                this._changeDetectorRef.detectChanges();
            });

    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.insuranceType || changes.insurerId) {
            this.fetchProducts();
        }
    }

    onProductSelected(product: Product) {
        if (!product) {
            this.selectedProduct.emit(null);
        } else {
            if (product.id === 'add-new') {
                this.selectedProductControl.setValue(null);
                this._changeDetectorRef.detectChanges();
                this.selectedProduct.emit(product);
            } else {
                this.selectedProduct.emit(product);
            }
        }
    }

    fetchProducts() {
        this.productService
            .getAll(this.insuranceType, this.insurerId)
            .subscribe({
                next: (products) => {
                    const addNewItem: any = {
                        id: 'add-new',
                        name: this.translocoService.translate('product.addItem'),
                        insuranceType: '',
                        insurerId: '',
                    };

                    // Translate product names based on the selected language
                    const translatedProducts = products.map(product => ({
                        ...product,
                        name: this.productNameGetter.transform(product),
                    }));

                    this.products$.next([addNewItem as Product, ...translatedProducts]);

                    const productExists = products.some(
                        (product) => product.id === this.productId
                    );

                    if (this.selectedProductControl.value && !productExists) {
                        // Clear the selected product control`
                        this.selectedProductControl.reset();
                        // Emit null to indicate no product is selected
                        this.selectedProduct.emit(null);
                        // Programmatically trigger the 'Clear all' functionality of ng-select
                        this.ngSelectProduct.handleClearClick();
                        this.ngSelectProduct.blur();
                        // Manually trigger change detection to update the view
                        this._changeDetectorRef.detectChanges();
                    } else {
                        // Set the selected product ID
                        this.selectedProductControl.setValue(this.productId);
                    }
                },
                error: (err) => {
                    console.error('Error fetching products:', err);
                },
            });
    }

    ngOnDestroy() {
        // Unsubscribe from all subscriptions when the component is destroyed
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}
