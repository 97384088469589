<ng-container [formGroup]="form">
    <span class="font-bold">Vehicle</span>
    <div class="flex mt-3">
        <mat-form-field class="w-1/2 pr-2">
            <mat-label>Make</mat-label>
            <input matInput [formControlName]="'make'" type="text" />
        </mat-form-field>
        <mat-form-field class="w-1/2 pl-2">
            <mat-label>Model</mat-label>
            <input matInput [formControlName]="'model'" type="text" />
        </mat-form-field>
    </div>

    <div class="flex">
        <mat-form-field class="w-1/2 pr-2">
            <mat-label>Year</mat-label>
            <input matInput [formControlName]="'year'" type="number" />
        </mat-form-field>
        <mat-form-field class="w-1/2 pl-2">
            <mat-label>VIN</mat-label>
            <input matInput [formControlName]="'VIM'" type="text" />
        </mat-form-field>
    </div>
</ng-container>
