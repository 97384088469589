import { Pipe, PipeTransform, inject } from '@angular/core';
import { SwissLuxonDateAdapter } from '../services/swiss-luxondateadapter';
import { Timestamp } from '@angular/fire/firestore';
@Pipe({
    name: 'swissDateFormat',
    pure: true,
    standalone: true,
})
export class SwissDateFormatPipe implements PipeTransform {
    private swissLuxonDateAdapter = inject(SwissLuxonDateAdapter);

    constructor() {}

    transform(dateString: any): string {
        if (!dateString) {
            return '';
        }
        if (dateString instanceof Timestamp) {
            return this.swissLuxonDateAdapter.toSwissFormat(
                dateString?.toDate().toISOString()
            );
        }
        return this.swissLuxonDateAdapter.toSwissFormat(dateString);
    }
}

@Pipe({
    name: 'swissDateTimeFormat',
    pure: true,
    standalone: true,
})
export class SwissDateTimePipe implements PipeTransform {
    private swissLuxonDateAdapter = inject(SwissLuxonDateAdapter);

    constructor() {}

    transform(dateString: any): string {
        if (!dateString) {
            return '';
        }
        if (dateString instanceof Timestamp) {
            return (
                this.swissLuxonDateAdapter.toSwissFormat(
                    dateString?.toDate().toISOString()
                ) +
                ' - ' +
                new Date(dateString.toDate().toISOString()).toLocaleTimeString('de-CH', {
                    hour: '2-digit',
                    minute: '2-digit',
                })
            );
        }
        return this.swissLuxonDateAdapter.toSwissFormat(dateString);
    }
}
