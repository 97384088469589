import { Injectable, OnDestroy } from '@angular/core';
import {
    doc,
    query,
    where,
    addDoc,
    getDoc,
    setDoc,
    getDocs,
    orderBy,
    Firestore,
    deleteDoc,
    updateDoc,
    collection,
    writeBatch,
    DocumentData,
    QuerySnapshot,
    collectionData,
    runTransaction,
    serverTimestamp,
    DocumentReference,
} from '@angular/fire/firestore';
import {
    BehaviorSubject,
    Observable,
    takeUntil,
    switchMap,
    forkJoin,
    Subject,
    from,
    map,
    take,
    tap,
    of,
} from 'rxjs';

import { PeopleAndObjectsEntity } from '../../../../models/db/peoples-and-objects.model';
import { CommentLog, CollectionType } from '../../../../models/db/event.model';
import { Insurer as InsurerCore } from '../../../../models/core/insurer.model';
import { EventService } from '../../../../common/services/event.service';
import { GenericPagination } from '../../../../models/common.model';
import { InsurerEntity } from '../../../../models/db/insurer.model';
import { InsuranceService } from '../insurances/insurance.service';
import { Insurance } from '../../../../models/insurance.model';
import {
    DocumentUpload,
    ContractData,
    Comment,
    Status,
} from '../../../../models/document-uploads.model';

@Injectable({
    providedIn: 'root',
})
export class DocumentUploadService implements OnDestroy {
    private _documentUploads$: BehaviorSubject<DocumentUpload[] | null> =
        new BehaviorSubject(null);
    private _insurances$: BehaviorSubject<Insurance[] | null> = new BehaviorSubject(null);
    private _pagination: BehaviorSubject<GenericPagination | null> = new BehaviorSubject(
        null
    );
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    private readonly _documentUploadCollectionName = 'policy-extractions';

    public _document: BehaviorSubject<DocumentUpload | null> = new BehaviorSubject(null);
    public document: DocumentUpload | undefined = undefined;
    public insurances: Insurance[] | any[] = [];

    insurancesRef = collection(this._firestore, `insurances`);
    documentUploadsRef = collection(this._firestore, this._documentUploadCollectionName);
    documents$ = collectionData(query(this.documentUploadsRef, orderBy('createdAt')), {
        idField: 'id',
    }).pipe(
        map((snapshot) => snapshot.map((doc: DocumentData) => doc as DocumentUpload)),
        tap((documents) => {
            const docs = documents.map((doc) => {
                const beneficiaries = doc?.beneficiaries?.map((ref) => ref.id) || [];
                const contractData = this.transformContractData(doc?.contractData);

                return { ...doc, beneficiaries, contractData };
            });
            this._documentUploads$.next(docs);
        })
    );

    constructor(
        private _firestore: Firestore,
        private eventService: EventService,
        private _insuranceService: InsuranceService
    ) {}

    onProcessExtractions$(uid: string): Observable<DocumentUpload[]> {
        return collectionData(
            query(
                this.insurancesRef,
                where('status', '!=', 'completed'),
                where('user.uid', '==', uid),
                orderBy('status'),
                orderBy('updatedAt')
            ),
            { idField: 'id' }
        ).pipe(
            switchMap((docs: DocumentData[]) =>
                from(
                    Promise.all(
                        docs.map(async (doc: DocumentData) => {
                            const extraction = doc as DocumentUpload;
                            const beneficiaries = doc?.beneficiaries?.map((ref) => ref.id) || [];
                            const contractData = this.transformContractData(doc?.contractData);

                            return {
                                ...extraction,
                                beneficiaries,
                                contractData,
                            } as DocumentUpload;
                        })
                    )
                )
            )
        );
    }
    /**
     * Getter documentUploads$
     */
    get documentUploads$(): Observable<DocumentUpload[]> {
        return this._documentUploads$.asObservable();
    }

    /**
     * Getter for pagination
     */
    get pagination$(): Observable<GenericPagination> {
        return this._pagination.asObservable();
    }

    /**
     * Create a new document in the policy-extractions collection
     * @param data
     * @returns
     */
    async create(data: DocumentUpload, insurerName = ''): Promise<string> {
        const documentUploadRef = collection(this._firestore, 'policy-extractions');
        try {
            const documentReference = await addDoc(documentUploadRef, data);
            console.log('Document written with ID: ', documentReference.id);
            const recordOwnerId = data.user.uid;
            const collectionType: CollectionType = 'policy-extractions';
    
            await this.eventService
                .createCreateEvent(documentReference.id, recordOwnerId, collectionType, insurerName)
                .toPromise();
    
            console.log('Event created for new document upload');
            return documentReference.id;
        } catch (error) {
            console.error('Error adding document: ', error);
            throw new Error('Error adding document');
        }
    }

    /**
     * Deletes an uploaded document and related insurances
     * @param documentId
     */
    async delete(documentId: string): Promise<void> {
        const batch = writeBatch(this._firestore);
        this.getDoCumentInsurances(documentId).then((insurances) => {
            insurances.forEach((ref) => {
                batch.delete(doc(this._firestore, 'insurances', ref.id));
            });

            return batch.commit().then(() => {
                deleteDoc(doc(this._firestore, 'policy-extractions', documentId));
                // TODO: Also delete file from storage
            });
        });
    }

    /**
     * Check if document can be marked as completed
     * @param document
     * @returns
     */
    async canMarkAsComplete(document: DocumentUpload): Promise<boolean> {
        const insurances: QuerySnapshot<DocumentData> = await this.getDoCumentInsurances(
            document.id
        );
        return insurances.docs.length > 0;
    }

    /**
     *
     * @param document Mark a document and its insurances as completed
     */
    async markAsComplete(document: DocumentUpload): Promise<void> {
        const batch = writeBatch(this._firestore);

        return new Promise((resolve) => {
            this.getDoCumentInsurances(document.id).then((insurances) => {
                insurances.forEach((insurance) => {
                    batch.set(
                        doc(this._firestore, 'insurances', insurance.id),
                        { captureStatus: 'COMPLETED' },
                        { merge: true }
                    );
                });

                batch.set(
                    doc(this.documentUploadsRef, document.id),
                    { status: 'completed' },
                    { merge: true }
                );

                resolve(batch.commit());
            });
        });
    }

    /**
     * Get document's insurances
     * @param document DocumentUpload
     * @returns
     */
    getDoCumentInsurances(id: string) {
        return getDocs(query(this.insurancesRef, where('documentId', '==', id)));
    }

    /**
     * Retrieves a specific insurance document by its ID
     * @param documentId The ID of the document to retrieve
     * @returns A promise resolving to the DocumentUpload object including its ID
     */
    async getDocument(documentId: string): Promise<DocumentUpload> {
        const documentRef = doc(
            this._firestore,
            this._documentUploadCollectionName,
            documentId
        );
        const documentSnapshot = await getDoc(documentRef);
        if (documentSnapshot.exists()) {
            const data = {
                id: documentSnapshot.id,
                ...documentSnapshot.data(),
            } as DocumentUpload;
            const beneficiaries = data?.beneficiaries?.map((ref) => ref.id) || [];
            const contractData = this.transformContractData(data?.contractData);

            return { ...data, beneficiaries, contractData };
        } else {
            throw new Error('Document does not exist');
        }
    }

    getAllByIds$(ids: string[]): Observable<DocumentUpload[]> {
        const peopleAndObjectsQuery = query(
            this.documentUploadsRef,
            where('__name__', 'in', ids)
        );
    
        return collectionData(peopleAndObjectsQuery, { idField: 'id' }).pipe(
            map((snapshot) => snapshot.map((doc: DocumentData) => {
                const data = doc as DocumentUpload;
                const beneficiaries = data.beneficiaries?.map((ref) => ref.id) || [];
                const contractData = this.transformContractData(data?.contractData); 
    
                return {
                    ...data,
                    beneficiaries,
                    contractData
                };
            }))
        );
    }

    // Method to update the policy extraction's status
    updateDocUploadStatus(id: string, newStatus: Status): Promise<void> {
        const docRef = doc(this._firestore, 'policy-extractions', id);
        return getDoc(docRef).then((snapshot) => {
            if (!snapshot.exists()) {
                throw new Error('Document does not exist!');
            }

            const data = { ...snapshot.data() } as DocumentUpload;
            const { beneficiaries, contractData, ...prevData } = data; // omitting beneficiaries & contractData
            const newData = { ...prevData, status: newStatus, updatedAt: new Date() };

            return updateDoc(docRef, newData).then(() => {
                return this.eventService
                    .createUpdateEvent(
                        prevData,
                        newData,
                        id,
                        prevData.user.uid,
                        'policy-extractions'
                    )
                    .toPromise();
            });
        });
    }

    // Method to update the extraction
    updateExtraction(
        extractionId: string,
        updatedProperty: Partial<DocumentUpload>
    ): Promise<void> {
        const docRef = doc(this._firestore, 'policy-extractions', extractionId);
        return getDoc(docRef).then((snapshot) => {
            if (!snapshot.exists()) {
                throw new Error('Document does not exist!');
            }
            const data = { ...snapshot.data() } as DocumentUpload;
            const beneficiaries = data.beneficiaries?.map((ref) => ref.id) || [];
            const contractData = this.transformContractData(data?.contractData); 

            // `prevData` and `newData` use for event changes comparison
            const prevData = { ...data, beneficiaries, contractData };
            const newData = { ...data, ...updatedProperty, beneficiaries, contractData };
            // `updatedData` data to save into the database
            const updatedData = { ...data, ...updatedProperty, updatedAt: new Date() };

            return updateDoc(docRef, updatedData).then(() => {
                return this.eventService
                    .createUpdateEvent(
                        prevData,
                        newData,
                        extractionId,
                        data.user.uid,
                        'policy-extractions'
                    )
                    .toPromise();
            });
        });
    }

    // Adds a new comment to a policy extraction
    addComment(id: string, comment: Partial<Comment>): Promise<void> {
        const docRef = doc(this._firestore, 'policy-extractions', id);
        // Explicitly state that you're returning a Promise<void>
        return runTransaction(this._firestore, async (transaction) => {
            const policyExtraction = await transaction.get(docRef);
            if (!policyExtraction.exists()) throw new Error('Document does not exist!');
            const extractionData = policyExtraction.data() as DocumentUpload;
            const newComment = {
                ...comment,
                createdAt: new Date(),
                updatedAt: new Date(),
            };
            const updatedComments = [...extractionData.comments, newComment];
            transaction.update(docRef, { comments: updatedComments });

            // After transaction is successful, create a comment event
            const commentLog: CommentLog = {
                type: 'created',
                id: newComment.id,
                description: newComment.description,
            };

            await this.eventService
                .createCommentEvent(
                    id,
                    extractionData.user.uid,
                    'policy-extractions',
                    commentLog
                )
                .toPromise();

            return;
        });
    }

    resolveDocumentReferences(refs: DocumentReference[]): Observable<any[]> {
        if (!refs.length) {
            return of([]);
        }
        const docs$ = refs.map((ref) => from(getDoc(ref)));
        return forkJoin(docs$).pipe(
            map((docs) => docs.map((doc) => ({ id: doc.id, ...doc.data() })))
        );
    }

    getById(id: string): Observable<DocumentUpload | undefined> {
        const docRef = doc(this._firestore, 'policy-extractions', id);
        return from(getDoc(docRef)).pipe(
            switchMap((docSnapshot) => {
                if (!docSnapshot.exists()) {
                    console.log('No such document!');
                    return of(undefined);
                } else {
                    const data = docSnapshot.data() as DocumentUpload;
                    const refsToResolve = [];

                    // Add beneficiaries to the references to resolve if they exist
                    if (data.beneficiaries && data.beneficiaries.length > 0) {
                        refsToResolve.push(...data.beneficiaries);
                    }

                    // Add insurer and holder to the references to resolve if they exist
                    if (data?.contractData?.insurer) {
                        refsToResolve.push(data.contractData.insurer);
                    }
                    if (data?.contractData?.holder) {
                        refsToResolve.push(data.contractData.holder);
                    }

                    // If there are no references to resolve, return the document data
                    if (refsToResolve.length === 0) {
                        return of({ 
                            ...data, 
                            beneficiaries: [],
                            contractData: null,
                            id: docSnapshot.id 
                        });
                    }

                    // Resolve all references and map them back to their fields
                    return this.resolveDocumentReferences(refsToResolve).pipe(
                        map((resolvedRefs) => {
                            let contractData = data?.contractData || null;
                            const beneficiaries = resolvedRefs.slice(0, data.beneficiaries?.length || 0);
                            if (contractData) {
                                const insurer = resolvedRefs[data.beneficiaries?.length || 0];
                                const holder = resolvedRefs[data.beneficiaries?.length ? data.beneficiaries.length + 1 : 1];
                                contractData =  {
                                    ...contractData,
                                    insurer,
                                    holder,
                                };
                            }

                            return {
                                ...data,
                                beneficiaries,
                                contractData,
                                id: docSnapshot.id,
                            };
                        })
                    );
                }
            })
        );
    }

    // Method to update beneficiaries by their IDs
    updateBeneficiaries(documentId: string, beneficiaryIds: string[]): Promise<void> {
        const beneficiaryRefs = beneficiaryIds.map((id) =>
            doc(this._firestore, 'people-and-objects', id)
        );
        const docRef = doc(this._firestore, 'policy-extractions', documentId);
        return updateDoc(docRef, { beneficiaries: beneficiaryRefs });
    }

    /**
     * Update a document upload's contract data.
     * @param documentId The ID of the document to update.
     * @param contractData The new contract data to set on the document, or null to remove existing contract data.
     */
      updateContractData(
        documentId: string,
        contractData: ContractData | null
    ): Promise<void> {
        const documentRef = doc(
            this._firestore,
            this._documentUploadCollectionName,
            documentId
        );

        return runTransaction(this._firestore, async (transaction) => {
            const documentSnapshot = await transaction.get(documentRef);

            if (!documentSnapshot.exists()) {
                throw new Error('Document does not exist!');
            }

            // If contractData is not null, map the insurer and holder ids to DocumentReferences
            let updatedContractData;
            if (contractData) {
                const insurer = contractData.insurer as InsurerCore;
                const holder = contractData.holder as PeopleAndObjectsEntity;
                // Map the insurer and holder ids to DocumentReferences
                const insurerRef = doc(this._firestore, 'insurers', insurer.id);
                const holderRef = doc(this._firestore, 'people-and-objects', holder.id);

                updatedContractData = {
                    ...contractData,
                    insurer: insurerRef,
                    holder: holderRef,
                };
            } else {
                // If contractData is null, prepare to remove the contractData from the document
                updatedContractData = null;
            }

            // Update the document with the new or null contract data
            transaction.update(documentRef, { contractData: updatedContractData });

            // Insert, create update event log here
        });
    }

    transformContractData(contractData: ContractData | null | undefined): ContractData | null {
        if (!contractData) {
            return null;
        }

        return {
            ...contractData,
            insurer: contractData.insurer instanceof DocumentReference ? contractData.insurer.id : contractData.insurer,
            holder: contractData.holder instanceof DocumentReference ? contractData.holder.id : contractData.holder,
        };
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }
}
