import { EmailNotification, LangCode } from 'app/models/db/event.model';
import { environment } from 'environments/environment';

type NotificationType = 'new-insurance' | 'new-insurance-beneficiary';

const getTranslation = (
    key: string,
    property: string,
    langCode: LangCode,
    replacements: { [key: string]: string } = {}
): string => {
    const translations = {
        'new-insurance': {
            title: {
                EN: 'New insurance is published',
                DE: 'Neue Versicherung wurde veröffentlicht',
                FR: 'Nouvelle assurance publiée',
                IT: 'Nuova assicurazione pubblicata',
            },
            description: {
                EN: `The insurance "{name}" has been inserted into your profile. You now have access to all the details.`,
                DE: `Die Versicherung "{name}" wurde in Ihr Profil eingefügt. Sie haben jetzt Zugriff auf alle Details.`,
                FR: `L'assurance "{name}" a été insérée dans votre profil. Vous avez maintenant accès à tous les détails.`,
                IT: `L'assicurazione "{name}" è stata inserita nel tuo profilo. Ora hai accesso a tutti i dettagli.`,
            },
            btnLabel: {
                EN: 'View Insurance',
                DE: 'Versicherung anzeigen',
                FR: 'Voir l’assurance',
                IT: 'Visualizza assicurazione',
            },
        },
        'new-insurance-beneficiary': {
            title: {
                EN: 'New beneficiary created',
                DE: 'Neuer Begünstigter erstellt',
                FR: 'Nouveau bénéficiaire créé',
                IT: 'Nuovo beneficiario creato',
            },
            description: {
                EN: `The beneficiary "{name}" has been created and is available in your profile.`,
                DE: `Der Begünstigte "{name}" wurde erstellt und ist in Ihrem Profil verfügbar.`,
                FR: `Le bénéficiaire "{name}" a été créé et est disponible dans votre profil.`,
                IT: `Il beneficiario "{name}" è stato creato ed è disponibile nel tuo profilo.`,
            },
            btnLabel: {
                EN: 'View Contract Request',
                DE: 'Vertragsanfrage anzeigen',
                FR: 'Voir la demande de contrat',
                IT: 'Visualizza la richiesta di contratto',
            },
        },
    };

    let message = translations[key][property][langCode];
    Object.keys(replacements).forEach((replaceKey) => {
        message = message.replace(
            new RegExp(`\{${replaceKey}\}`, 'g'),
            replacements[replaceKey]
        );
    });

    return message;
};

export function generateEmailNotificationData(
    type: NotificationType,
    email: string,
    name: string,
    id: string,
    langCode: LangCode
): EmailNotification | null {
    switch (type) {
        case 'new-insurance':
            return {
                email,
                title: getTranslation(type, 'title', langCode),
                description: getTranslation(type, 'description', langCode, { name }),
                urlLink: `${environment.baseUrl}/policies?insurance=${id}`,
                btnLabel: getTranslation(type, 'btnLabel', langCode),
            };
        case 'new-insurance-beneficiary':
            return {
                email,
                title: getTranslation(type, 'title', langCode),
                description: getTranslation(type, 'description', langCode, { name }),
                urlLink: `${environment.baseUrl}/policies?extraction=${id}`,
                btnLabel: getTranslation(type, 'btnLabel', langCode),
            };
        default:
            return null;
    }
}
