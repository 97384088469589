import {
    Component,
    ViewChild,
    EventEmitter,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Input,
    OnInit,
    Output,
    OnChanges,
    OnDestroy,
    SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgSelectModule, NgSelectComponent } from '@ng-select/ng-select';
import { ReactiveFormsModule, FormsModule, FormControl } from '@angular/forms';
import { User } from '@angular/fire/auth';

import { AdminUserService } from '../../services/admin-user.service';

@Component({
    selector: 'select-admin-user',
    templateUrl: './select-admin-user.component.html',
    styleUrls: ['./select-admin-user.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [CommonModule, NgSelectModule, ReactiveFormsModule, FormsModule],
})
export class SelectAdminUserComponent implements OnInit, OnChanges, OnDestroy {
    @ViewChild('ngSelectAdminUser') ngSelectAdminUser: NgSelectComponent;
    @Input() selectedAdminUser: User;
    @Output() onSelectAdminUser = new EventEmitter<User>();
    adminUserControl = new FormControl();
    adminUsers: User[] = [];
    isLoading = false;

    constructor(
        private adminUserService: AdminUserService,
        private changeDetectorRef: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.fetchAdminUsers();
        this.adminUserControl.setValue(this.selectedAdminUser);
    }

    fetchAdminUsers(): void {
        this.isLoading = true;
        this.adminUserService.getAdminUsers().subscribe({
            next: ({ data }) => {
                this.adminUsers = data;
                this.isLoading = false;
                this.changeDetectorRef.markForCheck();
            },
            error: (error) => {
                console.error('Error fetching admin users:', error);
                this.isLoading = false;
            },
        });
    }

    onSelected(user: User) {
        this.onSelectAdminUser.emit(user);
    }

    compareFn(user1: User, user2: User) {
        return user1 && user2 ? user1.uid === user2.uid : user1 === user2;
    }

    ngOnChanges(changes: SimpleChanges) {
        if ('selectedAdminUser' in changes) {
            this.adminUserControl.setValue(this.selectedAdminUser);
        }
    }

    ngOnDestroy() {}
}
